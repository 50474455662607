import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IHistory, insertImportHistory } from '@/services/rest/importHistory';
import {
  deleteMultiplePresetSpecification,
  deleteSpecificationById,
  FilterSpec,
  insertSpecificationPreset,
  Specification,
  upsertSpecification,
  useSpecification
} from '@/services/rest/specification';
import { useAuthStore } from '@/store/useAuthStore';
import { useHistoryStore } from '@/store/useHistoryStore';
import { debounce, uniqBy } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { useDebounce } from 'primereact/hooks';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

interface IOptions {
  label: string;
  items: Array<IItemsOption>;
}

interface IItemsOption {
  label: string;
  value: string | boolean;
  name: string;
}

interface ISpecification {
  id?: string;
  name: string;
  description?: string;
  label: string;
  type: string;
  status: string;
  value: Array<string>;
  instruction?: string;
}

const useCustom = () => {
  const initialFilter: FilterSpec = {
    id_from: null,
    id_to: null,
    name: '',
    label: '',
    description: '',
    type: '',
    status: ''
  };

  const headerSpec = [
    'Nama Spesifikasi',
    'Deskripsi',
    'Label',
    'Type',
    'Status',
    'Value Spesifikasi',
    'Petunjuk Pengisian'
  ];

  const headerUpSpec = [
    'ID Spesifikasi',
    'Nama Spesifikasi',
    'Deskripsi',
    'Label',
    'Type',
    'Status',
    'Value Spesifikasi',
    'Petunjuk Pengisian'
  ];
  const [dataSpecificationFilterOption, setDataSpecificationFilterOption] = useState<Specification[]>([]);
  const [uploadType, setUploadType] = useState<'create' | 'edit'>('create');
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [importedSpecification, setImportedSpecification] = useState<ISpecification[]>([]);
  const [fileName, setFileName] = useState<string|null>(null);
  const fileUploadRef = useRef<FileUpload>(null);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const user = useAuthStore((state)=>state.user);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.specification != '' ? JSON.parse(String(lastFilterPage.specification)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.specification != '' ? JSON.parse(String(lastFilterPage.specification)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const currentPage = parseInt(visitedPage.specification.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [paginator, setPaginator] = useState({
    currentPage,
    range: { start, end }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);

  //get all specification
  const {
    data: dataSpecification,
    refetch: refetchSpecification,
    isFetching: isFetchingSpecification
  } = useSpecification(filters, paginator.range, debounceSearch);

  const totalRecords = useMemo(() => {
    return dataSpecification?.count ? dataSpecification?.count : 0;
  }, [dataSpecification]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const specifications = useMemo(() => {
    if (!Array.isArray(dataSpecification?.data)) return [];
    return dataSpecification?.data.map((item) => ({
      ...item,
      is_active: item.is_active ? 'Active' : 'Inactive'
    }));
  }, [dataSpecification]);

  const findSpecDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }
    const query = supabase
      .from(TABLE.SPESIFICATION)
      .select('name,label,id,type,is_active')
      .is('deleted_at', null)
      .or(`name.ilike.%${findName}%, or(label.ilike.%${findName},type.ilike.%${findName}%)`)
      .order('id').range(0, 10);

    const { data } = await query;

    if (data) {
      if (dataSpecificationFilterOption) {
        setDataSpecificationFilterOption((prev)=>{
          const newData = uniqBy([...prev, ...data], 'id');
          return newData;
        });
      } else {
        setDataSpecificationFilterOption(data);
      }
    }
  }, 300);

  const findSpecificaiton = async (findName: string | null) => {
    findSpecDebounce(findName);
  };
  const getDefaultOption = async ()=> {
    const { data } = await supabase.from(TABLE.SPESIFICATION)
      .select('name,label,id,type,is_active')
      .is('deleted_at', null)
      .order('id', { ascending: false }).range(0, 9);
    if (data) {
      setDataSpecificationFilterOption(data);
    }
  };

  useEffect(()=> {
    getDefaultOption();
  }, []);

  const options = useMemo((): IOptions[] => {
    if (!Array.isArray(dataSpecificationFilterOption)) return [];
    const filterType = [...new Set(dataSpecificationFilterOption.map((s) => s.type))];
    const filterLabel = [...new Set(dataSpecificationFilterOption.map((s) => s.label))];
    const filterName = dataSpecificationFilterOption.map((s) => {
      return {
        label: String(s.name),
        name: 'name',
        value: String(s.id)
      };
    });
    return [
      {
        label: 'Name',
        items: filterName || []
      },
      {
        label: 'Label',
        items:
          filterLabel.map((label) => ({
            label: label,
            name: 'label',
            value: label
          })) || []
      },
      {
        label: 'Type',
        items:
          filterType.map((type) => ({
            label: type,
            name: 'type',
            value: type
          })) || []
      },
      {
        label: 'Status',
        items: [
          { label: 'Active', name: 'status', value: true },
          { label: 'Inactive', name: 'status', value: false }
        ]
      }
    ];
  }, [dataSpecificationFilterOption, dataSpecification?.data, findSpecDebounce, specifications]);

  const showFailedToast = (msg: string) => {
    toast.current?.show({
      severity: 'error',
      summary: 'Warning',
      detail: msg
    });
  };

  const showSuccessToast = (msg:string, isBulk?:boolean) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 2000
    });
    if (isBulk) {
      const timeout = setTimeout(()=>{
        navigate('/import-history/list');
      }, 2000);
      return ()=>clearTimeout(timeout);
    }
  };

  const deleteDataSpecification = useCallback(async (id: string) => {
    const { error: errorDeleteSpecification } = await deleteSpecificationById(id);

    if (errorDeleteSpecification) {
      showFailedToast('Failed to delete specification');
      return;
    }

    showSuccessToast('Specification deleted succesfully');
    refetchSpecification();
  }, []);

  const onDelete = useCallback((id: string) => {
    confirmDialog({
      message: 'Are you sure you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept() {
        deleteDataSpecification(id);
      }
    });
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      specification: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleClickDropdownAddSpecification = useCallback(() => {
    setIsOpenDropdown(!isOpenDropdown);
  }, [isOpenDropdown]);

  const handleToggleModal = useCallback(
    (type: 'create' | 'edit') => () => {
      setUploadType(type);
      setIsOpenDropdown(false);
      setIsShowModal(!isShowModal);
    },
    [isShowModal]
  );

  const handleClickDownload = useCallback(() => {
    const link = document.createElement('a');
    link.href = `/specification/${
      uploadType === 'create' ? 'templateSpecification.xlsx' : 'templateUbahSpecification.xlsx'
    }`;
    link.target = '_blank';
    link.download = '';
    link.click();
  }, [uploadType]);

  const handleChangeFileUpload = useCallback(
    async (event: FileUploadFilesEvent) => {
      setIsLoadingUpload(true);
      const text = await event.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary', cellDates: true });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<Object[]>(ws, { raw: false, dateNF: 'dd-mm-yyyy' });
      const option = { header: 1 };
      const sheet2 = XLSX.utils.sheet_to_json(ws, option);
      const header = sheet2.shift();
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(headerSpec);
      const isHeaderUpValid = JSON.stringify(header) === JSON.stringify(headerUpSpec);
      if (!isHeaderValid && uploadType === 'create') {
        showFailedToast('File is not Same With Template, Please Check your file correctly');
        fileUploadRef.current?.clear();
        setIsLoadingUpload(false);
        return;
      }
      if (!isHeaderUpValid && uploadType === 'edit') {
        showFailedToast('File is not Same With Template, Please Check your file corectly');
        fileUploadRef.current?.clear();
        setIsLoadingUpload(false);
        return;
      }
      setFileName(event.files[0].name);
      const specificationResult = json.splice(1).map((item) => {
        return uploadType === 'create' ?
          {
            label: item['Label'],
            name: item['Nama Spesifikasi'],
            status: item['Status'],
            type: item['Type'],
            value: item['Value Spesifikasi'],
            description: item['Deskripsi'],
            instruction: item['Petunjuk Pengisian']
          } :
          {
            id: item['ID Spesifikasi'],
            label: item['Label'],
            name: item['Nama Spesifikasi'],
            status: item['Status'].toUpperCase(),
            type: item['Type'],
            value: item['Value Spesifikasi'],
            description: item['Deskripsi'],
            instruction: item['Petunjuk Pengisian']
          };
      });
      const specName = specificationResult.map((spec)=> spec.name);
      const duplicatedName = specName.filter((item, index)=>specName.indexOf(item) !== index);
      if (duplicatedName.length>0) {
        showFailedToast(`Error name must be unique at ${duplicatedName.join(',')}`);
        setIsLoadingUpload(false);
        return;
      }
      if (specificationResult?.length > 300) {
        showFailedToast('Can not Create more than 300 Specifications');
        fileUploadRef.current?.clear();
        setIsLoadingUpload(false);
        return;
      }
      if (specificationResult.length === 0) {
        showFailedToast('File Can not be Empty');
        fileUploadRef.current?.clear();
        setIsLoadingUpload(false);
        return;
      }
      setIsLoadingUpload(false);
      setImportedSpecification(specificationResult);
    },
    [uploadType]
  );

  const handleClickUpload = useCallback(async () => {
    setIsLoadingUpload(true);
    const payload = importedSpecification.map((item)=>{
      return uploadType === 'create' ? {
        description: item.description,
        is_active: item.status === 'TRUE',
        label: item.label,
        name: item.name,
        type: item.type,
        instruction: item.instruction
      }:{
        id: item.id,
        description: item.description,
        is_active: item.status === 'TRUE',
        label: item.label,
        name: item.name,
        type: item.type,
        instruction: item.instruction
      };
    });

    if (uploadType === 'edit') {
      const { error: errorDeleteMultiple } = await deleteMultiplePresetSpecification(importedSpecification.map((item)=>String(item.id)));
      if (errorDeleteMultiple) {
        setIsLoadingUpload(false);
        return;
      }
    }

    const errorImport:Array<string> = [];

    for (const [index, item] of payload.entries()) {
      const { data, error } = await upsertSpecification(item);
      if (error) {
        errorImport.push(`Baris ke-${index+3}: `+ error.message);
        continue;
      }
      const payloadSpecPreset = importedSpecification[index].value.toString().includes(',')? importedSpecification[index].value.toString().split(',').map((val)=>({
        specification_id: data.id,
        name: val
      })) : [{ specification_id: data.id, name: importedSpecification[index].value.toString() }];
      const { error: errorSpecPreset } = await insertSpecificationPreset(payloadSpecPreset);
      if (errorSpecPreset) {
        errorImport.push(`Baris ke-${index+3}: `+ errorSpecPreset.message);
        continue;
      }
    }

    const payloadImportHistorySuccess = payload.length - errorImport.length > 0 && {
      account_id: user?.id || '',
      file_path: fileName || '',
      row_number: payload.length - errorImport.length,
      status: true,
      type: uploadType === 'create' ? 'bulk_upload_specification' : 'bulk_update_specification'
    };

    const payloadImportHistoryError = errorImport.length>0&&{
      account_id: user?.id || '',
      file_path: fileName || '',
      errors: errorImport,
      row_number: errorImport.length,
      status: false,
      type: uploadType === 'create' ? 'bulk_upload_specification' : 'bulk_update_specification'
    };

    const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');
    await insertImportHistory(payloadImportHistory as IHistory[]);
    setIsShowModal(false);
    setIsLoadingUpload(false);
    refetchSpecification();
    showSuccessToast('Success', true);
  }, [importedSpecification, uploadType, fileName, user]);


  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
    setPaginator((prev) => {
      return {
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      };
    });
  }, [paginator]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => {
        return {
          ...prev,
          currentPage: paginator.currentPage + 1,
          range: {
            start: paginator.range.start + perPage,
            end: paginator.range.end + perPage
          }
        };
      });
  }, [paginator, totalPages]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);


  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter: FilterSpec) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Name', 'name', filter.name));
    }

    if (filter.label !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Label', 'label', filter.label));
    }

    if (filter.description !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Description', 'description', filter.description));
    }

    if (filter.type !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Type', 'type', filter.type));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      specification: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      specification: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      specifications,
      options,
      isFetchingSpecification,
      toast,
      filterHistory,
      isShowModal,
      isOpenDropdown,
      uploadType,
      importedSpecification,
      fileUploadRef,
      isLoadingUpload,
      paginator,
      totalPages,
      perPage,
      totalRecords,
      itemFilters,
      search
    },
    method: {
      handleFilter,
      handleClickSubmitFilter,
      navigate,
      handleClearFilter,
      handleDeleteFilterHistory,
      onDelete,
      handleClickDropdownAddSpecification,
      handleToggleModal,
      handleClickDownload,
      handleChangeFileUpload,
      handleClickUpload,
      handleClickPrev,
      handleClickNext,
      handleChangeDropdownPage,
      findSpecificaiton,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleSearch
    }
  };
};

export default useCustom;
