import { STATUS_TRANSACTION, TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination } from '@/types/pagination';
import { Database as DatabaseCatalogue } from '@/types/services/catalogue';
import { Database as DatabaseTrade } from '@/types/services/trade';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { getPromosByPromoIds } from '../promo';

type AccountTransactions = DatabaseTrade['public']['Tables']['account_transactions']['Row'];
type OrderTransactions = DatabaseTrade['public']['Tables']['order_transactions']['Row'];
export type Orders = DatabaseTrade['public']['Tables']['orders']['Row'];
export type OrderList = DatabaseTrade['public']['Tables']['order_list']['Row'];
type Products = DatabaseCatalogue['public']['Tables']['products']['Row'];
type Merchants = DatabaseCatalogue['public']['Tables']['merchants']['Row'];
type OrderUpdate = DatabaseTrade['public']['Tables']['orders']['Update'];

export type RecipientMetadata = {
  name: string;
  email: string;
  phone: string;
  address: string;
  latitude: string;
  longitude: string;
  postal_code: string;
}

export type OrdersWithOrderList = Orders & { order_list: OrderList[] } & { recipient_metadata: RecipientMetadata };

export type OrderTransactionsList = OrderTransactions & { orders: OrdersWithOrderList };

export type OrderTransactionsListBuyer = OrderTransactions & { orders: OrdersWithOrderList } & {
  buyer_detail: BuyerDetail;
} & {
  shipping_address_detail:
    | {
        id: number;
        receiver_name: string;
        phone_number: string;
        label: string;
        address: string;
        postal_code: string;
        provinces: {
          id: number;
          name: string;
        };
        cities: {
          id: number;
          name: string;
        };
        districts: {
          id: number;
          name: string;
        };
      }
    | undefined;
};

type TrackingStatus = {
  created_at: string;
  order_id: string;
  status: string;
  updated_by: string;
};


interface Transaction extends AccountTransactions {
  order_transactions: OrderTransactionsList[];
}

interface BuyerDetail {
  id: string;
  name: string;
}

export interface IAccountDetails {
  id: string | null | undefined;
  type: string | null | undefined;
  first_name: string | null | undefined;
  last_name: string | null | undefined;
  merchant_id: string | null | undefined;
}

export interface IFilterTransaction {
  id_from: number | null;
  id_to: number | null;
  purchased_product: string;
  buyer_name: string;
  recipient_information: string;
  status: string;
}

export interface RowTransaction {
  order_id: number;
  buyer_detail: BuyerDetail;
  order_status: string | null;
  payment_metadata: JSON | null;
  payment_bill_metadata: JSON | null;
  shipping_address_detail:
    | {
        id: number;
        receiver_name: string;
        phone_number: string;
        label: string;
        address: string;
        postal_code: string;
        provinces: {
          id: number;
          name: string;
        };
        cities: {
          id: number;
          name: string;
        };
        districts: {
          id: number;
          name: string;
        };
      }
    | undefined;
  order_transactions: OrderTransactionsListBuyer[],
  products: {
    id: string;
    merchants: Merchants;
    merchant_id: string;
    price: number;
    product: Products;
    special_price: number;
    value: Record<string, string | number>;
    amount: number | null;
    created_at: string | null;
    notes: string | null;
    order_id: number | null;
    orders: OrdersWithOrderList;
    product_variant_id: string | null;
    quantity: number | null;
    updated_at: string | null;
    account_transaction_id: number;
  }[];
}

const getTotalTransactions = async () => {
  const query = supabase
    .from(TABLE.ACCOUNT_TRANSACTIONS)
    .select('*', { count: 'exact', head: true })
    .limit(50);
  return await query;
};

export const getTransactionList = async (range: IPagination = { start: 0, end: 10 }, filter?: IFilterTransaction, search?: string) => {
  let data: Transaction[] | null = [];
  let orderTransactions: OrderTransactionsList[] | null = [];
  let ordersData: OrdersWithOrderList[] | null = [];
  let countTotal: number = 0;

  // If filter available, reverse the fetch sequence
  if (filter) {
    // Fetch transactions
    const queryTransaction = supabase
      .from(TABLE.ACCOUNT_TRANSACTIONS)
      .select('id,buyer_account_id,status,payment_metadata,payment_bill_metadata', { count: 'exact' })
      .range(range.start, range.end)
      .order('id', { ascending: false });

    // ID
    if (filter.id_from != null) {
      queryTransaction.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      queryTransaction.lte('id', filter.id_to);
    }

    // Buyer Name
    if (filter.buyer_name != '') {
      const userIds = await getUserIdsByName(filter.buyer_name);
      if (userIds.length > 0) {
        queryTransaction.in('buyer_account_id', userIds);
      }
    }

    // Recipient Information
    if (filter.recipient_information != '') {
      const accountTransactions = await getAccountTransactionByAddress(filter.recipient_information);
      if (accountTransactions && accountTransactions.length > 0) {
        queryTransaction.in('id', accountTransactions);
      }
    }

    // Product Name
    if (filter.purchased_product != '') {
      const accountTransactions = await getAccountTransactionByProductName(filter.purchased_product);
      if (accountTransactions && accountTransactions.length > 0) {
        queryTransaction.in('id', accountTransactions);
      }
    }

    // Status
    if (filter.status != '') {
      const accountTransactions = await getAccountTransactionByOrderStatus(filter.status);
      if (accountTransactions && accountTransactions.length > 0) {
        queryTransaction.in('id', accountTransactions);
      }
    }

    // Search
    if (search && search !== '') {
      const accountTransactions =
      (await getAccountTransactionByProductName(search)) ??
        (await getAccountTransactionByAddress(search));

      if (accountTransactions.length > 0) {
        queryTransaction.in('id', accountTransactions);
      } else {
        const userIds = await getUserIdsByName(search);
        if (userIds.length > 0) {
          queryTransaction.in('buyer_account_id', userIds);
        } else {
          const searchId = search?.includes('-') ? search?.split('-')[0] : search;
          queryTransaction.eq('id', searchId);
        }
      }
    }

    const { data: transactionsData, count } = await (queryTransaction) as PostgrestSingleResponse<Transaction[]>;

    data = transactionsData;
    countTotal = count || 0;
  } else {
    // Fetch transactions
    const query = supabase
      .from(TABLE.ACCOUNT_TRANSACTIONS)
      .select('id,buyer_account_id,status,payment_metadata,payment_bill_metadata', { count: 'exact' })
      .range(range.start, range.end)
      .order('id', { ascending: false });

    // filter search order list id
    const searchidOrderList = search?.split('-')[0];

    if (searchidOrderList && !isNaN(+searchidOrderList)) {
      query.match({ id: searchidOrderList });
    }

    const { data: transactionsData, count } = (await query) as PostgrestSingleResponse<Transaction[]>;
    data = transactionsData;
    countTotal = count || 0;
  }

  // Fetch order_transactions
  const { data: orderTransactionsData } = (await supabase
    .from(TABLE.ORDER_TRANSACTIONS)
    .select('id,order_id,amount,account_transaction_id')
    .in('account_transaction_id', data?.map((it) => it.id) || [0])
    .order('id', { ascending: false })) as PostgrestSingleResponse<OrderTransactionsList[]>;
  orderTransactions = orderTransactionsData;
  orderTransactions = uniqBy(orderTransactions, 'id');

  // Fetch orders
  const query = supabase
    .from(TABLE.ORDERS)
    .select('*,order_list(*)')
    .in('id', orderTransactions?.map((it) => it.order_id) || [0])
    .order('id', { ascending: false });

  const { data: ordersDataRes } = (await query) as PostgrestSingleResponse<OrdersWithOrderList[]>;
  ordersData = ordersDataRes;
  ordersData = uniqBy(ordersData, 'id');

  // Fetch promos
  const orderPromosIds: string[] = [];
  ordersDataRes?.forEach((item) => {
    if (item.promo_ids && Array.isArray(item.promo_ids)) {
      item.promo_ids.forEach((promoId) => {
        if (!orderPromosIds.includes(promoId as string)) {
          orderPromosIds.push(promoId as string);
        }
      });
    }
  });

  const { data: promoDataList } = await getPromosByPromoIds(orderPromosIds);

  if ((data instanceof Array && data?.length === 0) || !data) return { results: [], count: 0 };
  const result = [] as RowTransaction[];
  const userIds = new Set<string>();
  const merchantIds = new Set<string>();
  const shippingAddressIds = new Set<number>();

  // Loop transactions reversed
  for (let index = 0; index < data.length; index++) {
    // Insert order_transactions to each transactions
    if (orderTransactions && orderTransactions?.length) {
      data[index].order_transactions = [];
      for (let idx = 0; idx < orderTransactions.length; idx++) {
        if (orderTransactions[idx].account_transaction_id === data[index].id) {
          data[index].order_transactions.push(orderTransactions[idx]);
        }
      }
    }

    const accountTransactions = data[index];
    const buyerId = accountTransactions.buyer_account_id;
    if (buyerId) {
      userIds.add(buyerId);
      (accountTransactions.order_transactions && accountTransactions.order_transactions.length > 0) && accountTransactions.order_transactions.forEach((ot, i) => {
        if (ordersData && ordersData.length) {
          // Insert orders to each order_transactions
          for (let ix = 0; ix < ordersData.length; ix++) {
            if (ordersData[ix].id === ot.order_id && data) {
              data[index].order_transactions[i].orders = ordersData[ix];
              if (ordersData[ix].shipping_address_id) shippingAddressIds.add(ordersData[ix].shipping_address_id!);
              if (ordersData[ix].merchant_id) merchantIds.add(ordersData[ix].merchant_id!);
            }
          }
        }
      });
    }
  }

  // get buyer_detail
  const { data: dataUser } = await supabase
    .from(TABLE.ACCOUNTS)
    .select('id,first_name,last_name,email,phone_number')
    .in('id', [...userIds])
    .limit(userIds.size);

  // get merchant_detail
  const { data: dataMerchant } = await supabase
    .from(TABLE.MERCHANTS)
    .select('id,name')
    .in('id', [...merchantIds])
    .limit(merchantIds.size);

  for (let index = 0; index < data.length; index++) {
    const accountTransactions = data[index];
    const buyerId = accountTransactions.buyer_account_id;
    if (buyerId) {
      const buyerDetail = dataUser
        ?.map?.((user) => {
          const name = `${user.first_name || ''} ${user.last_name || ''}` || user.email || user.phone_number || '';
          return {
            ...user,
            name
          };
        })
        ?.find((user) => user.id === buyerId);

      const shippingAddressDetail = {
        id: 0,
        receiver_name: accountTransactions.order_transactions?.[0]?.orders?.recipient_metadata?.name,
        phone_number: accountTransactions.order_transactions?.[0]?.orders?.recipient_metadata?.phone,
        label: '',
        address: accountTransactions.order_transactions?.[0]?.orders?.recipient_metadata?.address,
        postal_code: accountTransactions.order_transactions?.[0]?.orders?.recipient_metadata?.postal_code
      };

      const promos = [];
      accountTransactions.order_transactions.forEach((ot) => {
        (ot?.orders?.promo_ids as string[])?.forEach((promoId) => {
          const promoData = promoDataList?.find((item) => item.id === promoId.toLowerCase());
          if (promoData) promos.push(promoData as never);
        });
      });

      const payload = {
        transaction_id: accountTransactions.id,
        buyer_detail: buyerDetail as BuyerDetail,
        order_status: STATUS_TRANSACTION?.[accountTransactions.order_transactions?.[0]?.orders?.status?.toUpperCase() ?? 'pending'],
        shipping_address_detail: shippingAddressDetail,
        payment_metadata: accountTransactions.payment_metadata,
        payment_bill_metadata: accountTransactions.payment_bill_metadata,
        order_transactions: accountTransactions.order_transactions && accountTransactions.order_transactions.length > 0 ? accountTransactions.order_transactions.map((ot) => ({
          ...ot,
          buyer_detail: buyerDetail as BuyerDetail,
          shipping_address_detail: shippingAddressDetail,
          orders: {
            ...ot.orders,
            order_status: STATUS_TRANSACTION?.[ot?.orders?.status?.toUpperCase() ?? 'pending'],
            merchant_name: dataMerchant?.find((it) => ot.orders.merchant_id === it.id)?.name
          }
        })) : [],
        // TODO - map products based on PO/SO
        products: accountTransactions.order_transactions && accountTransactions.order_transactions.length > 0 ? accountTransactions.order_transactions.flatMap((ot) =>
          ot.orders && ot.orders.order_list && ot.orders.order_list.map((ol) => ({
            ...ol,
            ...(ol?.['product_metadata'] as object),
            delivery_metadata: ot?.orders?.delivery_metadata,
            status: STATUS_TRANSACTION?.[ot?.orders?.status?.toUpperCase() ?? 'pending'],
            merchant_id: ot.orders.merchant_id
          }))
        ) : [],
        promos
      };
      result.push(payload as unknown as RowTransaction);
      continue;
    }
  }

  return { results: result, count: countTotal };
};

const getUserIdsByName = async (name: string): Promise<string[]> => {
  const { data: users } = await supabase
    .from(TABLE.ACCOUNTS)
    .select('id')
    .or(`first_name.ilike.${name}%, last_name.ilike.${name}%`);

  if (users && users.length > 0) {
    return users.map((it) => it.id);
  }

  return [];
};

const getAccountTransactionByAddress = async (name: string): Promise<number[]> => {
  const { data: addresses } = await supabase
    .from(TABLE.SHIPPING_ADDRESSES)
    .select('id')
    .ilike('address', `%${name}%`);

  if (addresses && addresses.length > 0) {
    const { data: ordersDataRes } = await supabase
      .from(TABLE.ORDERS)
      .select('id')
      .in('shipping_address_id', addresses.map((it) => it.id));

    if (ordersDataRes && ordersDataRes.length > 0) {
      const { data: ordersTransactionsDataRes } = await supabase
        .from(TABLE.ORDER_TRANSACTIONS)
        .select('account_transaction_id')
        .in('order_id', ordersDataRes.map((it) => it.id));

      if (ordersTransactionsDataRes && ordersTransactionsDataRes.length > 0) {
        return ordersTransactionsDataRes.map((it) => it.account_transaction_id);
      }
    }
  }

  return [];
};

const getAccountTransactionByOrderStatus = async (status: string): Promise<number[]> => {
  const { data: ordersDataRes } = await supabase
    .from(TABLE.ORDERS)
    .select('id, status')
    .eq('status', status);

  if (ordersDataRes && ordersDataRes.length > 0) {
    const { data: ordersTransactionsDataRes } = await supabase
      .from(TABLE.ORDER_TRANSACTIONS)
      .select('account_transaction_id')
      .in('order_id', ordersDataRes.map((it) => it.id));

    if (ordersTransactionsDataRes && ordersTransactionsDataRes.length > 0) {
      return ordersTransactionsDataRes.map((it) => it.account_transaction_id);
    }
  }

  return [];
};

const getAccountTransactionByProductName = async (name: string): Promise<number[]> => {
  const { data: products } = await supabase
    .from(TABLE.PRODUCT)
    .select('id')
    .ilike('name', `%${name}%`);

  if (products && products.length > 0) {
    const { data: ordersDataRes } = await supabase
      .from(TABLE.ORDER_LIST)
      .select('order_id')
      .in('product_metadata->product->id', products.map((it) => it.id));

    if (ordersDataRes && ordersDataRes.length > 0) {
      const { data: ordersTransactionsDataRes } = await supabase
        .from(TABLE.ORDER_TRANSACTIONS)
        .select('account_transaction_id')
        .in('order_id', ordersDataRes.map((it) => it.order_id));

      if (ordersTransactionsDataRes && ordersTransactionsDataRes.length > 0) {
        return ordersTransactionsDataRes.map((it) => it.account_transaction_id);
      }
    }
  }

  return [];
};

export const updateOrderStatus = async (orderId: number, payload: OrderUpdate) => {
  return await supabase.from(TABLE.ORDERS).update(payload).eq('id', orderId);
};

// detail order
const getOrderDetail = async (id: string | undefined) => {
  const { data: dataOrder } = (await supabase
    .from(TABLE.ORDERS)
    .select('*,order_list(*)')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<OrdersWithOrderList>;

  const { data: dataUser, error: errorUser } = await supabase
    .from(TABLE.ACCOUNTS)
    .select('id,first_name,last_name,email,phone_number')
    .eq('id', dataOrder?.buyer_account_id)
    .single();

  if (errorUser) return;

  const { data: dataTransaction, error: errorTransaction } = (await supabase
    .from(TABLE.ACCOUNT_TRANSACTIONS)
    .select('id,checkout_id,payment_metadata,status')
    .eq('checkout_id', dataOrder?.checkout_id)
    .single()) as PostgrestSingleResponse<AccountTransactions>;

  if (errorTransaction) return;

  const { data: dataTrackingStatus } = await getTrackingStatus(dataOrder?.id);

  const paymentMetadata = dataTransaction.payment_metadata;
  const dataShippingAddress = {
    id: 0,
    receiver_name: dataOrder?.recipient_metadata?.name,
    phone_number: dataOrder?.recipient_metadata?.phone,
    label: '',
    address: dataOrder?.recipient_metadata?.address,
    postal_code: dataOrder?.recipient_metadata?.postal_code,
    districts: null,
    cities: null,
    provinces: null
  };

  return {
    ...dataOrder,
    buyer_detail: dataUser,
    shipping_address_detail: dataShippingAddress,
    traking_status: dataTrackingStatus?.map?.((tracking) => ({ ...tracking, status: STATUS_TRANSACTION[tracking.status.toUpperCase()] })),
    payment_metadata: paymentMetadata
  };
};

const getAccount = async (id?: string[]) => {
  if (id) {
    return (await supabase
      .from(TABLE.ACCOUNTS)
      .select('id,type,first_name,last_name,merchant_id')
      .in('id', id)) as PostgrestSingleResponse<IAccountDetails[]>;
  }
};

// get RPC status order
const getTrackingStatus = async (orderId: number | undefined) => {
  return (await supabase.rpc('trade.order_tracking', { oid: orderId })) as PostgrestSingleResponse<TrackingStatus[]>;
};

export const useGetAccount = (id?: string[]) =>
  useQuery({
    queryKey: ['account0details', id],
    queryFn: () => getAccount(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useOrderDetail = (id: string | undefined) =>
  useQuery({
    queryKey: ['orderDetail'],
    queryFn: async () => await getOrderDetail(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useTotalTransactions = () =>
  useQuery({
    queryKey: ['total-transation'],
    queryFn: getTotalTransactions,
    refetchOnWindowFocus: false
  });


export const useTransactionList = (range: IPagination, filter?: IFilterTransaction, search?: string) =>
  useQuery({
    queryKey: ['transactionList', filter, range, search],
    queryFn: () => getTransactionList(range, filter, search),
    refetchOnWindowFocus: false
  });
