import React, { FC } from 'react';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import useCustom from './hooks';
import { OrderTransactionsListBuyer, RowTransaction } from '@/services/rest/transaction';
import { Image } from 'primereact/image';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { formatRupiah } from '@/utils/formatter';
import 'primeicons/primeicons.css';
import './index.css';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { Json } from '@/types/services/trade';
import Pagination from '@/components/base/Pagination';
import { Divider } from 'primereact/divider';
import FilterHistory from '@/components/base/FilterHistory';
import { InputNumber } from 'primereact/inputnumber';
import dayjs from 'dayjs';

const Item = (props) => {
  const { products, notes, quantity, amount, productCount, promo } = props;

  return (
    <div className="flex flex-col">
      {promo && (
        <div
          className="w-60 h-6 px-2 rounded-xl bg-[#3b86f6] font-bold text-white truncate cursor-default"
          title={`${promo.code} - ${promo.name}`}
        >
          {promo.code} - {promo.name}
        </div>
      )}
      <div className="flex items-start justify-start gap-2 w-full h-full">
        <Image
          id="order-product-image"
          zoomSrc={products?.images?.[0]}
          src={products?.images?.[0]}
          alt={products?.name || 'product image'}
          className="w-16 h-16 object-cover"
          preview
        />
        <div className="flex flex-col gap-2">
          <p className="font-bold text-base">{products?.name}</p>
          <p className="text-sm">{products?.sku || ''}</p>
          <p className="text-sm">{notes || ' '}</p>
        </div>
        <p className="text-sm">{`${quantity} pcs x ${formatRupiah(amount ?? 0)}`}</p>
      </div>
      {
        productCount > 1 && (
          <div className="font-bold text-base mt-8">
            + {productCount - 1} Produk Lainnya
          </div>
        )
      }
    </div>
  );
};

const productBodyTemplate =
  (isSingle = true) =>
  // eslint-disable-next-line react/display-name
    (rowData: RowTransaction) => {
      const promo = rowData?.['promos'].find((item) => item.type === 'product');
      if (isSingle) {
        const firstProduct = rowData.products[0];
        return (
          <Item
            productCount={rowData.products.length}
            products={firstProduct?.['product']}
            notes={firstProduct?.['notes']}
            quantity={firstProduct?.['quantity']}
            amount={firstProduct?.['amount']}
            promo={promo}
          />
        );
      }

      return (
        <Item
          productCount={rowData.products.length}
          products={rowData?.['product']}
          notes={rowData?.['notes']}
          quantity={rowData?.['quantity']}
          amount={rowData?.['amount']}
          promo={rowData?.['promos']}
        />
      );
    };


const SubItem = (props) => {
  const { products, notes, quantity, amount } = props;
  const product = products.product || {};
  const variants = products?.attribute_set_variants?.map((item) => item?.value);

  return (
    <div className="flex items-start justify-start gap-2 w-full h-full">
      <Image
        id="order-product-image"
        zoomSrc={product?.images?.[0]}
        src={product?.images?.[0]}
        alt={product?.name || 'product image'}
        className="w-16 h-16 object-cover"
        preview
      />
      <div className="flex flex-col gap-2">
        <p className="font-bold text-base">{product?.name}</p>
        <p className="text-sm">{product?.sku || ''}</p>
        <p className="text-sm">{notes || ' '}</p>
        {variants && variants?.length > 0 && (
          <p className="text-sm">Variant: ({variants.join(', ')})</p>
        )}
      </div>
      <p className="text-sm">{`${quantity} pcs x ${formatRupiah(amount ?? 0)}`}</p>
    </div>
  );
};

const subProductBodyTemplate =
  // eslint-disable-next-line react/display-name
  (rowData: OrderTransactionsListBuyer) => {
    return (
      <div className="flex flex-col gap-3">
        {rowData.orders.order_list.slice(0, 2).map((it, ix) => (
          <SubItem
            key={ix}
            products={it?.product_metadata}
            notes={it?.notes}
            quantity={it?.quantity}
            amount={it?.amount}
          />
        ))}
        {rowData.orders.order_list.length > 2 && (
          <div className="font-bold text-base mt-8">+ {rowData.orders.order_list.length - 2} Produk Lainnya</div>
        )}
      </div>
    );
  };

const orderBodyTemplate = (rowData: OrderTransactionsListBuyer) => {
  if (!rowData.order_id) return '-';

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-base">{rowData.order_id}</p>
    </div>
  );
};

const orderDateBodyTemplate = (rowData: OrderTransactionsListBuyer) => {
  if (!rowData.orders.created_at) return '-';

  return (
    <div className="flex flex-col gap-2">
      <p className="text-base">{ dayjs(rowData.orders.created_at).format('DD MMMM YYYY HH:mm:ss') }</p>
    </div>
  );
};

const merchantBodyTemplate = (rowData: OrderTransactionsListBuyer) => {
  if (!rowData.orders) return '-';

  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-base">Merchant:</p>
      <p className="text-sm">{rowData.orders.merchant_name}</p>
    </div>
  );
};


const addressBodyTemplate = (rowData: OrderTransactionsListBuyer) => {
  if (!rowData.shipping_address_detail) return '-';

  const shippingAddressDetail = rowData.shipping_address_detail;
  const promo = rowData?.['promos'].find((item) => item.type === 'free_delivery');

  return (
    <div className="flex flex-col items-start gap-2">
      {promo && (
        <div
          className="w-60 h-6 px-2 rounded-xl bg-[#49a14a] font-bold text-white truncate cursor-default"
          title={promo.name}
        >
          {promo.name}
        </div>
      )}
      <p>
        {shippingAddressDetail?.receiver_name} ({shippingAddressDetail?.phone_number})
      </p>
      <p>{shippingAddressDetail?.address}</p>
      <p>{shippingAddressDetail?.districts?.name}</p>
      <p>{shippingAddressDetail?.cities?.name}</p>
      <p>{shippingAddressDetail?.provinces?.name}</p>
      <p>{shippingAddressDetail?.postal_code}</p>
    </div>
  );
};

const shippingBodyTemplate = (product: OrderTransactionsListBuyer) => {
  const shippingName = product.orders.delivery_metadata?.['provider_name'] === 'on_site_pick_up' ?
    product.orders.delivery_metadata?.['name'] :
    product.orders.delivery_metadata?.['provider_name']?.toUpperCase?.() + ' ' + product.orders.delivery_metadata?.['name'];
  return (
    <div className="flex flex-col items-start gap-2">
      <p>{shippingName}</p>
      <div className="mt-2 flex flex-col items-start">
        <p>{product.orders.delivery_metadata?.['type']}</p>
        <p>
          Berat: {getWeight(product?.orders.delivery_metadata)}{' '}
          kg
        </p>
      </div>
    </div>
  );
};

const getWeight = (deliveryMetadata: Json) => {
  if (deliveryMetadata?.['pickup_request']?.weight) {
    return deliveryMetadata?.['pickup_request']?.weight;
  };

  if (deliveryMetadata?.['pickup_request'] && deliveryMetadata?.['pickup_request']['PackageList'] && deliveryMetadata?.['pickup_request']['PackageList'].length > 0) {
    return deliveryMetadata?.['pickup_request']['PackageList'].map((pl) => pl.total_weight).reduce((prev, curr) => prev + curr);
  }

  if (deliveryMetadata?.['pickup_request'] && deliveryMetadata?.['pickup_request']['OLSHOP_WEIGHT']) {
    return deliveryMetadata?.['pickup_request']['OLSHOP_WEIGHT'];
  }

  return 0;
};

const orderStatusBodyTemplate = (rowData: OrderTransactionsListBuyer) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-base">{rowData.orders.order_status}</p>
      {
        rowData.orders.awb_no && (
          <div className="flex flex-row">
            <p className="font-bold text-base">No Resi:</p>
            <p className="font-medium text-base">{rowData.orders.awb_no}</p>
          </div>
        )
      }
    </div>
  );
};

const transactionStatusBodyTemplate = (rowData: RowTransaction) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-base">{rowData.order_status}</p>
      {rowData.payment_metadata && (
        <div className="flex flex-col">
          <p className="font-medium text-base">{rowData.payment_metadata?.['payment_type_name'] || rowData.payment_metadata?.['payment_type']}</p>
          {rowData.payment_bill_metadata?.['payment_account_number'] && (
            <p className="font-medium text-base">VA#: {rowData.payment_bill_metadata?.['payment_account_number']}</p>
          )}
        </div>
      )}
    </div>
  );
};

const getSubBodyTemplate =
    (field: string) => {
      if (field === 'order_id') return orderBodyTemplate;
      if (field === 'order_date') return orderDateBodyTemplate;
      if (field === 'merchants') return merchantBodyTemplate;
      if (field === 'products') return subProductBodyTemplate;
      if (field === 'shipping_address_detail') return addressBodyTemplate;
      if (field === 'delivery') return shippingBodyTemplate;
      if (field === 'order_status') return orderStatusBodyTemplate;
      return null;
    };

const getBodyTemplate =
  (isSingle = true) =>
    (field: string) => {
      if (field === 'order_id') return orderBodyTemplate;
      if (field === 'products') return productBodyTemplate(isSingle);
      if (field === 'shipping_address_detail') return addressBodyTemplate;
      if (field === 'delivery') return shippingBodyTemplate;
      if (field === 'order_status') return transactionStatusBodyTemplate;
      return null;
    };

const allowExpansion = (rowData: RowTransaction) => {
  return rowData?.order_transactions?.length > 0;
};

const OrderManagement: FC = () => {
  const { data, methods } = useCustom();

  const rowExpansionTemplate =
    (header: Record<string, string>[]) =>
    // eslint-disable-next-line react/display-name
      (rowData: RowTransaction) => {
        return (
          <div className="p-2">
            <DataTable
              id="order-data-table"
              value={rowData.order_transactions}
              scrollable
              scrollHeight="35rem"
              showGridlines
              stripedRows
            >
              {header.map((item, index) => {
                if (item.field == 'account_transaction_id') {
                  return (
                    <Column
                      key={index}
                      field={item.field}
                      header={item.header}
                      style={{ minWidth: '6rem' }}
                      body={(rowData, col) => {
                        return (
                          <div className="flex flex-col gap-2">
                            <p className="font-bold text-base">{rowData.orders.order_no}</p>
                          </div>
                        );
                      }}
                    />
                  );
                }
                return (
                  <Column
                    key={index}
                    field={item.field}
                    header={item.header}
                    style={{ minWidth: '6rem' }}
                    body={getSubBodyTemplate(item.field)}
                  />
                );
              })}
              <Column
                header="Action"
                className="!p-0"
                style={{ width: '5rem', position: 'relative' }}
                body={(rowData, col) => {
                  const poId = `${rowData.account_transaction_id}-${col.rowIndex + 1}`;
                  const statusCancel = [
                    'waiting_payment',
                    'payment_expired',
                    'payment_paid',
                    'waiting_approval',
                    'in_progress',
                    'ready'
                  ];
                  const isHideCancel = !statusCancel.includes(rowData.orders?.status);
                  return (
                    <>
                      <Tooltip target=".speeddial-bottom .p-speeddial-action" position="bottom" />
                      <SpeedDial
                        model={data.buttonActions(isHideCancel)}
                        direction="left"
                        showIcon="pi pi-bars"
                        hideIcon="pi pi-times"
                        className="speeddial-bottom right-0 mr-2 top-1/2 -translate-y-1/2"
                        buttonClassName="p-button-outlined"
                        onShow={methods.handleSelectedActions(rowData, poId)}
                      />
                    </>
                  );
                }}
              />
            </DataTable>
          </div>
        );
      };

  const modalFooter = (
    <div>
      <Button label="Kembali" onClick={() => methods.setVisible(false)} className="p-button-text" />
      <Button label="Lanjut" onClick={() => methods.onSubmit()} />
    </div>
  );

  const modalHeader = ({ title = '', description = '' } = { title: '', description: '' }) => (
    <div className="flex flex-col">
      <div className="font-bold py-2 px-1">{title}</div>
      <div className="text-xs px-1">{description}</div>
    </div>
  );

  const optionStatusOrder = [
    { label: 'WAITING_PAYMENT', value: 'waiting_payment' },
    { label: 'PAYMENT_EXPIRED', value: 'payment_expired' },
    { label: 'PAYMENT_PAID', value: 'payment_paid' },
    { label: 'WAITING_APPROVAL', value: 'waiting_approval' },
    { label: 'IN_PROGRESS', value: 'in_progress' },
    { label: 'READY', value: 'ready' },
    { label: 'ON_THE_WAY', value: 'on_the_way' },
    { label: 'DELIVERED', value: 'delivered' },
    { label: 'RETURN', value: 'return' },
    { label: 'COMPLETED', value: 'completed' },
    { label: 'CANCEL', value: 'cancel' },
    { label: 'CANCEL_BY_BUYER', value: 'cancel_by_buyer' }
  ];

  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => methods.setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={methods.handleClickSubmitFilter} size="large" />
    </div>
  );

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'id_from')}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'id_to')}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Purchased Product</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'purchased_product')}
            value={data.itemFilters.purchased_product}
            name="purchased_product"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Recipient Information</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'recipient_information')}
            value={data.itemFilters.recipient_information}
            name="recipient_information"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Buyer Name</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'buyer_name')}
            value={data.itemFilters.buyer_name}
            name="buyer_name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={optionStatusOrder}
            onChange={(e) => methods.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Toast ref={data.toast} />
      <BaseContainer>
        <LoadingOverlay loadingText={data.isExportLoading ? 'Exporting Data...' : undefined} show={data.isExportLoading} />
        <MainTitle title="Order Management" nav="" />
        <div className="flex w-full justify-end items-center gap-4">
          <Button
            icon="pi pi-sliders-h"
            onClick={() => methods.setIsOpenDialog(true)}
            label="Filter"
            severity="secondary"
            text
            raised
          />
          <Button
            label="Export"
            icon="pi pi-download"
            severity="success"
            disabled={data.isExportLoading || data.isLoading}
            onClick={() => methods.handleExportData()}
          />
        </div>
        {data.filterHistory && data.filterHistory.length > 0 ?
          <FilterHistory
            filterHistory={data.filterHistory}
            handleClearFilter={methods.handleClearFilter}
            handleDeleteFilterHistory={methods.handleDeleteFilterHistory}
            show={data.filterHistory?.length > 0}
          /> :
          <Divider className="p-divider-solid " />
        }
        <div className="card border">
          <div className="flex justify-between items-center px-2 my-4">
            <div id="search" className="p-input-icon-right w-[20%]">
              <InputText
                id="order-search-input"
                className="w-full"
                placeholder="Cari Disini"
                type="search"
                value={data.search}
                onChange={methods.handleSearch}
                keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
              />
              <i className="pi pi-search" />
            </div>
            <div className="flex items-center justify-end gap-6 w-full">
              <h4 className="font-bold justify-self-end"> {data.totalRecords} data ditemukan</h4>
              <Pagination
                perPage={data.perPage}
                totalPages={data.totalPages}
                currentPage={data.paginator.currentPage}
                disabledDropdown={data.totalRecords === 0}
                onClickPrev={methods.handleClickPrev}
                onClickNext={methods.handleClickNext}
                onChangeDropdownPage={methods.handleChangeDropdownPage}
                onChangeJumptoPage={methods.handleChangeJumpTopage}
                onJumptoPage={methods.handleJumpToPage}
                isOnOrder
              />
            </div>
          </div>

          <DataTable
            id="order-data-table"
            value={data.transactions}
            showGridlines
            rows={data.perPage}
            totalRecords={data.totalRecords}
            scrollable
            scrollHeight="36rem"
            expandedRows={data.expanded}
            onRowToggle={(e) => methods.setExpanded(e.data)}
            rowExpansionTemplate={rowExpansionTemplate(data.poTableHeader)}
            dataKey="transaction_id"
            tableStyle={{ minWidth: '12rem', minHeight: '36rem' }}
            loading={data.isLoading}
          >
            <Column alignHeader="center" align="center" expander={allowExpansion} style={{ width: '0.5rem' }} />
            {data.header.map((item, index) => (
              <Column
                key={index}
                field={item.field}
                header={item.header}
                sortable
                alignHeader="center"
                body={getBodyTemplate(true)(item.field)}
              />
            ))}
          </DataTable>
        </div>

        <div className="card flex justify-content-center">
          <Dialog
            id="order-dialog"
            header={modalHeader(methods.modalPresets(data.preferedAction || ''))}
            visible={data.visible}
            style={{ width: '35vw' }}
            onHide={() => methods.resetModal()}
            footer={modalFooter}
          >
            {data.preferedAction === 'cancel_order' && <CancelOrderModalContent />}
            {data.preferedAction === 'complete_order' && <CompleteOrderModalContent />}
            {data.preferedAction === 'return_order' && <ReturnOrderModalContent selectedRow={data.selectedRow} />}
          </Dialog>
        </div>
      </BaseContainer>
      <Dialog onHide={() => methods.setIsOpenDialog(false)} visible={data.isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
    </>
  );
};

const CancelOrderModalContent = () => {
  const { data, methods } = useCustom();
  const dropdownOptions = [
    { label: 'Pembeli', value: 'buyer' },
    { label: 'Penjual', value: 'seller' }
  ];

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="font-bold py-2 px-1 flex-auto">Diajukan oleh</div>
        <Dropdown value={data.requester.value} options={dropdownOptions} onChange={(event) => methods.setRequester({ label: event.value, value: event.value })} />
      </div>
      <div className="font-bold py-2 px-1">Alasan pembatalan (wajib isi)</div>
      <InputTextarea id="product-note-revision-input" value={data.changeNote} className="w-full h-36" onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => methods.setChangeNote(e.target.value)} />
    </div>
  );
};

const CompleteOrderModalContent = () => {
  const { data, methods } = useCustom();
  return (
    <div className="flex flex-col">
      <div className="font-bold py-2 px-1">Catatan penyelesaian (wajib isi)</div>
      <InputTextarea id="product-note-revision-input" value={data.changeNote} className="w-full h-36" onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => methods.setChangeNote(e.target.value)} />
    </div>
  );
};

const ReturnOrderModalContent = ({ selectedRow }: { selectedRow: OrderTransactionsListBuyer | null }) => {
  const { data, methods } = useCustom();
  const dropdownOptions = [
    { label: 'Semuanya', value: false },
    { label: 'Sebagian', value: true }
  ];
  const productOptions = selectedRow?.orders.order_list.map((item) => ({ label: item.product_metadata?.['product']['name'], value: item.product_variant_id }));

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="font-bold py-2 px-1 flex-auto">Pengembalian</div>
        <Dropdown
          value={data.isReturnPart}
          options={dropdownOptions}
          onChange={(event) => methods.setIsReturnPart(event.target.value)}
        />
      </div>
      {
        data.isReturnPart && (
          <div className="flex flex-col">
            <div className="font-bold py-2 px-1">Produk yang Dikembalikan</div>
            {data.returnedProducts && data.returnedProducts.map((item, idx) => (
              <div key={idx} className="flex flex-row gap-1">
                <Dropdown
                  className="flex-auto w-52"
                  value={item.id}
                  options={productOptions}
                  onChange={(event) => {
                    const newSelection: { id: string; quantity: number; maxQuantity: number }[] = data.returnedProducts.map((it, ix) => {
                      if (ix === idx) {
                        it.id = event.target.value;
                        it.maxQuantity = selectedRow?.orders.order_list.find((p) => p.product_variant_id === event.target.value)?.quantity || 0;
                      }

                      return it;
                    });
                    methods.setReturnedProducts(newSelection);
                  }}
                />
                <InputText
                  id="return-order-input"
                  className="flex-none w-20"
                  type="number"
                  max={item.maxQuantity}
                  value={item.quantity.toString()}
                  onChange={(event) => {
                    if (parseInt(event.target.value) < 0) {
                      return;
                    }
                    const newSelection: { id: string; quantity: number; maxQuantity: number }[] = data.returnedProducts.map((it, ix) => {
                      if (ix === idx) {
                        it.quantity = parseInt(event.target.value) > it.maxQuantity ? it.maxQuantity : parseInt(event.target.value);
                      }

                      return it;
                    });
                    methods.setReturnedProducts(newSelection);
                  }}
                />
                <div className="font-bold py-2 px-1 flex-auto">dari {item.maxQuantity} pcs</div>
              </div>
            ))}
            {selectedRow && selectedRow?.orders.order_list.length > data.returnedProducts.length && (
              <Button
                label="+ Produk yang dikembalikan"
                onClick={() =>
                  methods.setReturnedProducts([
                    ...data.returnedProducts,
                    {
                      id: selectedRow.orders.order_list[data.returnedProducts.length].product_variant_id || '',
                      quantity: 0,
                      maxQuantity: selectedRow.orders.order_list[data.returnedProducts.length].quantity || 0
                    }
                  ])
                }
              />
            )}
          </div>
        )
      }
      <div className="font-bold py-2 px-1">Alasan pengembalian (wajib isi)</div>
      <InputTextarea
        id="product-note-revision-input"
        value={data.changeNote}
        className="w-full h-36"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => methods.setChangeNote(e.target.value)}
      />
    </div>
  );
};

export default OrderManagement;
