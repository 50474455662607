import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'primereact/hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { STATUS_TRANSACTION } from '@/constants';
import { OrderList, Orders } from '@/services/rest/transaction';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { TabViewTabChangeEvent } from 'primereact/tabview';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useSearchParams } from 'react-router-dom';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Jakarta');
interface IProductRevisions {
    id: number,
    product_id: number,
    product_name: string,
    old_fields: string,
    field_changes: string,
    new_fields: string,
    merchant_name: string,
    created_at: string,
    updated_at: string
}

interface IOrderTracking {
  order_id : number,
  status : string,
  date : string,
  sku: string,
  merchant_name: string,
  quantity: number,
  amount: number
  updated_by_role : string
}

interface IFilterAuditTrail {
  product_id_from: number | null;
  product_id_to: number | null;
  product_name: string;
  created_by: string;
  created_at: Date[];
}

const useAuditTrail = () => {
  const initialFilter: IFilterAuditTrail = {
    product_id_from: null,
    product_id_to: null,
    product_name: '',
    created_by: '',
    created_at: []
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataProductRevisions, setDataProductRevisions] = useState<IProductRevisions[]>();
  const [dataOrderTracking, setDataOrderTracking] = useState<IOrderTracking[]>();
  const [filtersOrder, filterOrderDebounce, setFilterOrder] = useDebounce('', 400);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.auditTrail.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [tabNumber, setTabNumber] = useState<number>(0);
  const [seacrhParams, setSeacrhParams] = useSearchParams();
  const [itemFilters, setItemFilters] = useState(lastFilterPage.auditTrail != '' ? JSON.parse(String(lastFilterPage.auditTrail)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.auditTrail != '' ? JSON.parse(String(lastFilterPage.auditTrail)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    switch (tabNumber) {
    case 1:
      getOrderTracking();
      break;
    default:
      getProductRevisions(paginator.range.start, paginator.range.end, filters, debounceSearch);
      break;
    }
  }, [paginator, tabNumber, debounceSearch, filters]);

  const getProductRevisions = async (from: number = 0, limit: number = 10, filter: IFilterAuditTrail, search: string) => {
    setIsLoading(true);

    let dataProduct: { id: number, name: string }[] = [];
    let dataMerchant: { id: number, name: string }[] = [];
    let dataProductLog: IProductRevisions[] = [];

    const query = supabase
      .from(TABLE.PRODUCT_REVISIONS)
      .select('*', { count: 'exact' })
      .order('updated_at', { ascending: false })
      .not('old_fields', 'eq', '{}')
      .not('field_changes', 'eq', '{}')
      .range(from, limit);

    // ID
    if (filter.product_id_from != null) {
      query.gte('product_id', filter.product_id_from);
    }

    if (filter.product_id_to != null) {
      query.lte('product_id', filter.product_id_to);
    }

    // Created At
    if (filter.created_at && filter.created_at?.length != 0) {
      const fromDate = dayjs(String(filter.created_at[0])).format('YYYY-MM-DD');
      query.gte('created_at:date', fromDate);

      if (filter.created_at.length > 1 && filter.created_at[1] != null) {
        const toDate = dayjs(String(filter.created_at[1])).format('YYYY-MM-DD');
        query.lte('created_at:date', toDate);
      }
    }

    // Product Name
    if (filter.product_name != '') {
      const productIds = await getProductIdsByName(filter.product_name);
      if (productIds.length > 0) {
        query.in('product_id', productIds);
      }
    }

    // Created By
    if (filter.created_by != '') {
      const merchantIds = await getMerchantIdsByName(filter.created_by);
      if (merchantIds.length > 0) {
        query.in('merchant_id', merchantIds);
      }
    }

    // Search
    if (search != '') {
      const productIds = await getProductIdsByName(search);
      if (productIds.length > 0) {
        query.in('product_id', productIds);
      } else {
        const merchantIds = await getMerchantIdsByName(search);
        if (merchantIds.length > 0) {
          query.in('merchant_id', merchantIds);
        }
      }
    }

    const { data, count, error } = await query;

    if (data?.length) {
      const { data: dp } = await supabase.from(TABLE.PRODUCT).select('id, name').in('id', data.filter((f) => f.product_id).map((log) => log.product_id));
      dataProduct = dp || [];
      const { data: dm } = await supabase.from(TABLE.MERCHANTS).select('id, name').in('id', data.filter((f) => f.merchant_id).map((log) => log.merchant_id));
      dataMerchant = dm || [];
    }

    if (error) {
      setIsLoading(false);
      return;
    }

    if (data) {
      dataProductLog = data.map((item) => {
        // Product Name
        const filterProduct = dataProduct?.filter((d) => d.id === item.product_id);
        if (filterProduct !== undefined && filterProduct.length > 0) {
          const nameProduct = filterProduct[0].name;
          item.product_name = nameProduct;
        }
        // Updated By
        const filterMechant = dataMerchant?.filter((d) => d.id === item.merchant_id);
        if (filterMechant !== undefined && filterMechant.length > 0) {
          const nameMerchant = filterMechant[0].name;
          item.merchant_name = nameMerchant;
        }
        // Data Before Changes
        const jsonDataBefore = JSON.stringify(item.old_fields || item.product_old_fields);
        const dataBefore = JSON.parse(jsonDataBefore);
        if (dataBefore && 'id' in dataBefore) {
          delete dataBefore.id;
        }
        if (dataBefore && 'updated_at' in dataBefore) {
          delete dataBefore.updated_at;
        }
        if (dataBefore && 'created_at' in dataBefore) {
          delete dataBefore.created_at;
        }
        item.old_fields = Object.keys(dataBefore).map((key) => {
          if (dataBefore[key] instanceof Object) {
            return `[${key} => {${Object.keys(dataBefore[key]).map((it) => `${it} => ${dataBefore[key][it]}`)}}]`;
          }
          return `[${key} => ${dataBefore[key]}]`;
        }).join(' \n\r ');

        // Changed Data
        const jsonDataAfter = JSON.stringify(item.field_changes || item.product_field_changes);
        const dataAfter = JSON.parse(jsonDataAfter);
        if (dataAfter && 'id' in dataAfter) {
          delete dataAfter['id'];
        }
        if (dataAfter && 'updated_at' in dataAfter) {
          delete dataAfter['updated_at'];
        }
        if (dataAfter && 'created_at' in dataAfter) {
          delete dataAfter['created_at'];
        }
        item.field_changes = dataAfter && Object.keys(dataAfter).map((key) => {
          if (dataAfter[key] instanceof Object) {
            return `[${key} => {${Object.keys(dataAfter[key]).map((it) => `${it} => ${dataAfter[key][it]}`)}}]`;
          }
          return `[${key} => ${dataAfter[key]}]`;
        }).join(' \n\r ');

        // Data After Changes
        const dataAfterChange = { ...dataBefore, ...dataAfter };
        item.new_fields = dataAfterChange && Object.keys(dataAfterChange).map((key)=> {
          if (dataAfterChange[key] instanceof Object) {
            return `[${key} => {${Object.keys(dataAfterChange[key]).map((it)=> `${it} => ${dataAfterChange[key][it]}`)}}]`;
          }
          return `[${key} => ${dataAfterChange[key]}]`;
        }).join(' \n\r ');

        item.updated_at = dayjs(item.updated_at).format('DD MMMM YYYY HH:mm:ss');
        return item as IProductRevisions;
      });
    }
    setTotalRecords(count || data.length);
    setDataProductRevisions(dataProductLog);
    setIsLoading(false);
  };

  const getOrderTracking = async () => {
    setIsLoading(true);
    const { data: orderTracking } = await supabase.rpc('trade.order_tracking', { oid: 0 });

    let dataOrderList: OrderList[] = [];
    let dataOrders: Orders[] = [];

    if (orderTracking?.length) {
      const { data: dol } = await supabase.from(TABLE.ORDER_LIST).select('*').in('order_id', orderTracking.map((log) => log.order_id)) as PostgrestSingleResponse<OrderList[]>;
      dataOrderList = dol || [];

      const { data: dor } = await supabase.from(TABLE.ORDERS).select('*').in('id', orderTracking.map((log) => log.order_id)) as PostgrestSingleResponse<Orders[]>;
      dataOrders = dor || [];
    }

    if (orderTracking) {
      const { data: merchantNames } = await supabase.from(TABLE.MERCHANTS).select('id, name').in('id', dataOrders.map((or) => or.updated_by).filter((it) => it));
      const { data: accountNames } = await supabase.from(TABLE.ACCOUNTS).select('id, first_name, last_name').in('id', dataOrders.map((or) => or.updated_by).filter((it) => it));

      const badSkuMetaList = dataOrderList.filter((ol) => !ol.product_metadata?.['product']?.['sku']);
      const badMerchantMetaList = dataOrderList.filter((ol) => !ol.product_metadata?.['product']?.['merchant']?.['name']);

      const metaSkus: { id: string, product_id: number, vendor_sku: string, sku_id: string }[] = [];
      if (badSkuMetaList.length > 0) {
        const { data: mSkus } = await supabase.from(TABLE.PRODUCT_VARIANTS).select('id, product_id, vendor_sku, sku_id').in('id', badSkuMetaList.map((bm) => bm.product_variant_id).filter((it) => it));
        metaSkus.push(...mSkus || []);
      }

      const metaMerchants: { id: string, name: string }[] = [];
      if (badMerchantMetaList.length > 0) {
        const { data: metaM } = await supabase.from(TABLE.MERCHANTS).select('id, name').in('id', badMerchantMetaList.map((bm) => bm.product_metadata?.['product']?.['merchant_id'] ? (bm.product_metadata?.['product']?.['merchant_id'] as string).toLowerCase() : undefined).filter((it) => it));
        metaMerchants.push(...metaM || []);
      }

      const ot = orderTracking.map((item) => {
        if (!dataOrderList?.find((ol) => ol.order_id === item.order_id)) {
          return undefined;
        }

        if (dataOrderList?.find((ol) => ol.order_id === item.order_id && (!ol.product_metadata?.['product']?.['sku'] && !metaSkus.find((mk) => mk.id === ol.product_variant_id)))) {
          return undefined;
        }

        const skus: string[] = [];
        const merchants: string[] = [];
        const order = dataOrders.find((od) => od.id === item.order_id);
        const hasMerchantName = merchantNames?.find((an) => an.id === order?.updated_by);
        const hasAccountName = accountNames?.find((an) => an.id === order?.updated_by);

        dataOrderList?.filter((i) => i.order_id === item.order_id).forEach((d) => {
          if (d.product_metadata && d.product_metadata?.['product']) {
            if (d.product_metadata?.['product']?.['sku']) {
              skus.push(d.product_metadata?.['product']?.['sku']);
            } else {
              const sku = metaSkus.find((mk) => mk.id === d.product_variant_id);
              if (sku) {
                skus.push(sku?.vendor_sku || sku?.sku_id);
              }
            }

            if (d.product_metadata?.['product']?.['merchant']?.['name']) {
              merchants.push(d.product_metadata?.['product']?.['merchant']?.['name']);
            } else {
              const merchant = metaMerchants.find((mk) => mk.id === (d.product_metadata?.['product']?.['merchant_id'] as string).toLowerCase());
              if (merchant) {
                merchants.push(merchant?.name);
              }
            }
          }
        });
        item.order_no = order?.order_no || order?.id;
        item.qty = order ? order.total_quantity : 0;
        item.amount = order ? order.total_amount : 0;
        item.sku = skus.filter((f) => f && f != '').join(', ');
        item.merchant_name = merchants.filter((f) => f && f != '').join(', ');
        item.updated_at = dayjs(item.created_at).format('DD MMMM YYYY HH:mm:ss');
        item.updated_by_role = hasAccountName ? `${hasAccountName.first_name} ${hasAccountName.last_name}` : hasMerchantName?.name;
        item.status = STATUS_TRANSACTION[item.status.toUpperCase()] || item.status;

        return item;
      });
      setDataOrderTracking(ot.filter((it) => it) as IOrderTracking[]);
      setIsLoading(false);
    }
  };

  const getProductIdsByName = async (name: string): Promise<number[]> => {
    const { data: products } = await supabase.from(TABLE.PRODUCT).select('id').ilike('name', `%${name}%`);
    if (products) {
      return products.map((p: { id: number }) => p.id);
    }

    return [];
  };

  const getMerchantIdsByName = async (name: string): Promise<string[]> => {
    const { data: merchants } = await supabase.from(TABLE.MERCHANTS).select('id').ilike('name', `%${name}%`);
    if (merchants) {
      return merchants.map((m: { id: string }) => m.id);
    }

    return [];
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickPage = useCallback(
    (page: number) => () => {
      setPaginator((prev) => ({
        ...prev,
        currentPage: page,
        range: {
          start: page === 1 ? page - 1 : perPage * page - perPage,
          end: page * perPage - 1
        }
      }));
    },
    [perPage]
  );

  const onTabChange = (event: TabViewTabChangeEvent) => {
    setSeacrhParams({ index: `${event.index}` });
    setTabNumber(event.index);
    setPerPage(10);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    }));
  };

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      auditTrail: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (key: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
    setIsOpenDialog(false);
  }, [itemFilters]);

  const storeFilterHistory = useCallback((filter: IFilterAuditTrail) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.product_id_from != null) {
      let filterID = filter.product_id_from.toString();
      if (filter.product_id_to != null) {
        filterID = `${filter.product_id_from}-${filter.product_id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Product ID', 'product_id_from,product_id_to', filterID));
    }

    if (filter.product_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Product Name', 'product_name', filter.product_name));
    }

    if (filter.created_by !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Changed By', 'created_by', filter.created_by));
    }

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Changed At', 'created_at', filterDate));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      auditTrail: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      auditTrail: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      dataProductRevisions,
      filters,
      filtersOrder,
      isLoading,
      dataOrderTracking,
      filterOrderDebounce,
      totalRecords,
      totalPages,
      paginator,
      perPage,
      seacrhParams,
      filterHistory,
      search,
      itemFilters,
      isOpenDialog
    },
    methods: {
      setDataProductRevisions,
      setFilters,
      setFilterOrder,
      setDataOrderTracking,
      setPerPage,
      handleClickPrev,
      handleClickNext,
      handleChangeDropdownPage,
      handleClickPage,
      onTabChange,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleFilter,
      handleClearFilter,
      handleDeleteFilterHistory,
      handleSearch,
      handleClickSubmitFilter,
      setIsOpenDialog
    }
  };
};

export default useAuditTrail;
