import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { IPagination, ISort } from '@/types/pagination';
import { IFlashSaleSupabase } from '@/views/FlashSaleManagement/type';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

interface IProduct {
  id: number;
  ref_id?:number;
  description: string;
  specification: ISpecification;
  sku: string;
  merchant_id: string;
  merchants?: IMerchants;
  product_variants?: IProductVariants[];
  images: string[];
  video_url: string;
  information: string;
  insurance_flag: boolean;
  minimum_purchase: number;
  maximum_purchase: number;
  is_digital: boolean;
  is_active: boolean;
  revision_note: string;
  status: string;
  name: string;
  type: string;
  main_price: number;
  is_main_price: boolean;
  volume: IVolume;
  category_id: number;
  categories?: ICategory;
  wholesale_price?: IWholesalePrice[];
}

interface IProductVariant {
  id: string;
  sku_id: string;
  price: number | string;
  saleable_stock: number | string;
  products: {
    name: string;
    status: string;
    merchants: {
      id: string;
      name: string;
    }
  }
}
export interface PendingUpdate {
  id: number;
  product_id?: number;
  status: string;
  is_active: boolean;
  images: string[];
  name: string;
  description: string;
  main_price: number;
  insurance_flag: boolean;
  volume: IVolume;
  minimum_purchase: number;
  category_id: number;
  video_url: string;
  is_main_price: boolean;
  maximum_purchase: number;
  merchant_id: string;
}
interface ICategory {
  id: number;
  name: string;
  parent_id: number;
}
interface IVolume {
  height: number;
  width: number;
  depth: number;
  weight: number;
}
interface IMerchants {
  name: string;
  id: string;
}
interface ISpecification {
  brand: string;
  color: string;
  size: string;
  weight: number;
}
interface IProductVariants {
  product_flashsale?: IFlashSaleSupabase[];
  saleable_stock: number;
  ref_id?: number;
  inflight_stock: number;
  price: number;
  product_id: number;
  has_price_diff: boolean;
  merchant_id: string;
  id: string;
  special_price: number;
  is_primary_variant: boolean;
  sku_source: string;
  sku_id: string;
  is_active: boolean;
  warehouse_id: number;
  omnichannel_account_id: string;
  image: string;
  attribute_set_variants?: ISetVariants[];
  attribute_set_specifications?: ISetSpecification[];
  product_favorites?: IProductFavorite[];
  account_history_logs?: IProductLogs[];
  voucher_stock?: number;
  saleable_stock_product_digitals?: ISaleableStockProductDigitals[];
  stock_product_digitals?: IStockProductDigitals[];
  is_digital: boolean;
}
interface IWholesalePrice {
  id: number;
  product_id: number;
  max_purchase: number;
  min_purchase: number;
  price: number;
  created_at: string;
}
interface IProductFavorite{
  id:string
  product_variant_id: string
}
interface IProductLogs{
  id:string
  product_variant_id: string
}
interface ICategories {
  id: number;
  name: string;
  parent_id: number;
  child?: ICategories[];
}
interface ISpecifications {
  name: string;
  label: string;
  id: number;
}
interface ISetSpecification {
  value: string
  specifications?: ISetSpecification[]
  product_variant_id: string
  specification_id: number
}

interface IStockProductDigitals {
  id: number;
  product_variant_id: string;
}
interface IVariants {
  name: string;
  label: string;
  id: number;
}
interface ISetVariants {
  value: string
  variant_id: number
  variants?: IVariants[]
  product_variant_id: string
}
interface IFilterProduct {
  id_from: number | null;
  id_to: number | null;
  name: string;
  merchant_name: string;
  sku: string;
  price_from: number | null;
  price_to: number | null;
  special_price_from: number | null;
  special_price_to: number | null;
  stock_from: number | null;
  stock_to: number | null;
  saleable_from: number | null;
  saleable_to: number | null;
  status_product: string;
  status: boolean | null;
}

export interface ISaleableStockProductDigitals {
  stock: number;
  product_variant_id: string;
}

export const getAllProduct = async (range:IPagination = { start: 0, end: 10 }, sort:ISort, filter?: IFilterProduct, search?: string) => {
  const query = supabase
    .from('catalogue.products')
    .select('*, merchants!inner(name)', { count: 'exact' });

  if (sort.field === 'merchants.name') {
    const { data: merchants } = await supabase.from(TABLE.MERCHANTS).select('id, name').order('name', { ascending: sort.asc }).limit(10);
    if (merchants) {
      const merchantId = merchants?.map((item) => item.id);
      query.in('merchant_id', merchantId).range(range.start, range.end);

      const result = await query as PostgrestSingleResponse<IProduct[]>;

      if (result.data && result.data.length > 0) {
        result.data = await getAllProductRelatedData(result.data);
      }

      return result;
    }
  } else {
    query.order(sort.field, { ascending: sort.asc });
  }

  if (filter) {
    // ID
    if (filter.id_from != null) {
      query.gte('id', filter.id_from);
    }

    if (filter.id_to != null) {
      query.lte('id', filter.id_to);
    }

    // Name
    if (filter.name != '') {
      query.ilike('name', `%${filter.name}%`);
    }

    // Merchant Name
    if (filter.merchant_name != '') {
      query.ilike('merchants.name', `%${filter.merchant_name}%`);
    }

    // SKU
    if (filter.sku != '') {
      query.ilike('sku', `%${filter.sku}%`);
    }

    // Price & Special Price
    if (
      filter.price_from != null ||
      filter.price_to != null ||
      filter.special_price_from != null ||
      filter.special_price_to != null ||
      filter.saleable_from != null ||
      filter.saleable_to != null
    ) {
      const productIds = await getProductIdsByFilter(filter);
      if (productIds.length > 0) {
        query.in('id', productIds);
      }
    }

    // Status Product
    if (filter.status_product != '') {
      query.eq('status', filter.status_product);
    }

    // Status
    if (filter.status != null) {
      query.eq('is_active', filter.status);
    }
  }

  if (search && search != '') {
    const { data: productVariants } = await supabase.from(TABLE.PRODUCT_VARIANTS)
      .select('product_id')
      .ilike('sku_id', `%${search}`);

    if (productVariants && productVariants.length > 0) {
      query.in('id', productVariants.map((pv) => pv.product_id));
    }

    const merchantIds = await getMerchantIdsByName(search);
    if (merchantIds.length > 0) {
      query.in('merchant_id', merchantIds);
    }

    if (!productVariants?.length && !merchantIds.length) {
      query.ilike('name', `%${search}%`);
    }
  }

  const result: PostgrestSingleResponse<IProduct[]> = { data: [] as IProduct[] } as PostgrestSingleResponse<IProduct[]>;
  const { data, error, status, statusText, count } = await query.range(range.start, range.end) as PostgrestSingleResponse<IProduct[]>;

  result.status = status;
  result.error = error;
  result.statusText = statusText;
  result.count = count;
  result.data = data;
  if (result.data && result.data.length > 0) {
    result.data = await getAllProductRelatedData(result.data);
  }

  return result;
};

const getMerchantIdsByName = async (name: string) => {
  const { data } = await supabase.from(TABLE.MERCHANTS).select('id').ilike('name', `%${name}%`);
  if (data) {
    return data?.map((m) => m.id);
  }

  return [];
};

const getProductIdsByFilter = async (filter: IFilterProduct) => {
  const query = supabase.from(TABLE.PRODUCT_VARIANTS).select('product_id');

  // Price
  if (filter.price_from != null) {
    query.gte('price', filter.price_from);
  }

  if (filter.price_to != null) {
    query.lte('price', filter.price_to);
  }

  // Special Price
  if (filter.special_price_from != null) {
    query.gte('special_price', filter.special_price_from);
  }

  if (filter.special_price_to != null) {
    query.lte('special_price', filter.special_price_to);
  }

  // Saleable Stock
  if (filter.saleable_from != null) {
    query.gte('saleable_stock', filter.saleable_from);
  }

  if (filter.saleable_to != null) {
    query.lte('saleable_stock', filter.saleable_to);
  }

  const { data } = await query;

  if (data) {
    return data?.map((m) => m.product_id);
  }

  return [];
};

const getAllProductRelatedData = async (products: IProduct[]): Promise<IProduct[]> => {
  const productIDs = products.map((product) => product.id).flat();
  const merchantIDs = products.map((product) => product.merchant_id).flat();
  const categoryIDs: number[] = [];

  products.forEach((product) => {
    if (product.category_id) categoryIDs.push(product.category_id);
  });

  let productVariants: PostgrestSingleResponse<IProductVariants[]> = { data: [] as IProductVariants[] } as PostgrestSingleResponse<IProductVariants[]>;
  productVariants = await supabase.
    from('catalogue.product_variants').
    select('*').in('product_id', productIDs) as PostgrestSingleResponse<IProductVariants[]>;

  if (productVariants.data && productVariants.data.length > 0) {
    const productVariantIDs = productVariants.data.map((pv) => pv.id).flat();

    let productFavorites: PostgrestSingleResponse<IProductFavorite[]> = { data: [] as IProductFavorite[] } as PostgrestSingleResponse<IProductFavorite[]>;
    let accountLogHistories: PostgrestSingleResponse<IProductLogs[]> = { data: [] as IProductLogs[] } as PostgrestSingleResponse<IProductLogs[]>;
    let attributeSetVariants: PostgrestSingleResponse<ISetVariants[]> = { data: [] as ISetVariants[] } as PostgrestSingleResponse<ISetVariants[]>;
    let saleableStockProductDigitals: PostgrestSingleResponse<ISaleableStockProductDigitals[]> = {
      data: [] as ISaleableStockProductDigitals[]
    } as PostgrestSingleResponse<ISaleableStockProductDigitals[]>;
    let stockProductDigitals: PostgrestSingleResponse<IStockProductDigitals[]> = { data: [] as IStockProductDigitals[] } as PostgrestSingleResponse<IStockProductDigitals[]>;
    let flashsaleProducts: PostgrestSingleResponse<IFlashSaleSupabase[]> = { data: [] as IFlashSaleSupabase[] } as PostgrestSingleResponse<IFlashSaleSupabase[]>;
    // get product favorite
    productFavorites = (await supabase
      .from('catalogue.product_favorites')
      .select('id, product_variant_id')
      .in('product_variant_id', productVariantIDs)) as PostgrestSingleResponse<IProductFavorite[]>;

    flashsaleProducts = (await supabase
      .from('catalogue.flash_sales')
      .select('id, product_variant_id, master_slot_id, periode, saleable_stock, total_stock, price_value')
      .in('product_variant_id', productVariantIDs)) as PostgrestSingleResponse<IFlashSaleSupabase[]>;

    // account hostory log
    accountLogHistories = (await supabase
      .from('catalogue.account_history_logs')
      .select('id, product_variant_id')
      .in('product_variant_id', productVariantIDs)) as PostgrestSingleResponse<IProductLogs[]>;

    // get atribute set variant
    attributeSetVariants = (await supabase
      .from('catalogue.attribute_set_variants')
      .select('*, variants(*)')
      .in('product_variant_id', productVariantIDs)) as PostgrestSingleResponse<ISetVariants[]>;

    // get Saleabe Stock product digitals
    saleableStockProductDigitals = (await supabase.rpc('catalogue.get_vouchers_count', {
      variant_ids: productVariantIDs
    })) as PostgrestSingleResponse<ISaleableStockProductDigitals[]>;

    // get Stock product digitals
    stockProductDigitals = (await supabase
      .from('catalogue.product_variant_vouchers')
      .select('id, product_variant_id')
      .in('product_variant_id', productVariantIDs)) as PostgrestSingleResponse<IStockProductDigitals[]>;

    if (productFavorites.data && productFavorites.data.length > 0) {
      productVariants.data = productVariants.data.map((pv) => {
        pv.product_favorites = productFavorites.data ? productFavorites.data.filter((pf) => pf.product_variant_id == pv.id) : [];
        return pv;
      });
    }

    if (flashsaleProducts.data && flashsaleProducts.data.length > 0) {
      productVariants.data = productVariants.data.map((pv) => {
        pv.product_flashsale = flashsaleProducts.data ? flashsaleProducts.data.filter((pf) => pf.product_variant_id == pv.id) : [];
        return pv;
      });
    }

    if (accountLogHistories.data && accountLogHistories.data.length > 0) {
      productVariants.data = productVariants.data.map((pv) => {
        pv.account_history_logs = accountLogHistories.data ? accountLogHistories.data.filter((pl) => pl.product_variant_id == pv.id) : [];
        return pv;
      });
    }

    if (attributeSetVariants.data && attributeSetVariants.data.length > 0) {
      productVariants.data = productVariants.data.map((pv) => {
        pv.attribute_set_variants = attributeSetVariants.data ?? [];
        return pv;
      });
    }

    if (saleableStockProductDigitals.data && saleableStockProductDigitals.data.length > 0) {
      productVariants.data = productVariants.data.map((pv) => {
        pv.saleable_stock_product_digitals = saleableStockProductDigitals.data ?? [];
        return pv;
      });
    }

    if (stockProductDigitals.data && stockProductDigitals.data.length > 0) {
      productVariants.data = productVariants.data.map((pv) => {
        pv.stock_product_digitals = stockProductDigitals.data ?? [];
        return pv;
      });
    }

    if (productVariants.data) {
      products = products.map((p) => {
        p.product_variants = productVariants.data!.filter((pv) => pv.product_id == p.id);
        return p;
      });
    }
  }

  // Get Merchants
  let merchants: PostgrestSingleResponse<IMerchants[]> = { data: [] as IMerchants[] } as PostgrestSingleResponse<IMerchants[]>;
  merchants = await supabase.
    from('catalogue.merchants').
    select('*').in('id', merchantIDs) as PostgrestSingleResponse<IMerchants[]>;

  if (merchants.data && merchants.data.length > 0) {
    products = products.map((p) => {
      const foundMerchant = merchants.data ? merchants.data.find((m) => m.id == p.merchant_id) : null;
      if (foundMerchant) {
        p.merchants = foundMerchant;
      }
      return p;
    });
  }
  let categories: PostgrestSingleResponse<ICategory[]> = { data: [] as ICategory[] } as PostgrestSingleResponse<ICategory[]>;
  categories = await supabase.
    from('catalogue.categories').
    select('id, name, parent_id').in('id', categoryIDs) as PostgrestSingleResponse<ICategory[]>;

  if (categories.data && categories.data.length > 0) {
    products = products.map((p) => {
      const foundCategory = categories.data ? categories.data.find((c) => c.id == p.category_id) : null;
      if (foundCategory) {
        p.categories = foundCategory;
      }
      return p;
    });
  }

  return products;
};

const getTotalProduct =async () => {
  const query = supabase.from('catalogue.products').select('*', { count: 'exact', head: true }).limit(50);
  return await query;
};

const getFilterOptionProduct = async () => {
  return (await supabase.from('catalogue.products').select('status').limit(10)) as PostgrestSingleResponse<
    IProduct[]
  >;
};

const getFilterOptionMerchants = async () => {
  return (await supabase
    .from('catalogue.merchants')
    .select('name,id')
    .limit(10)
    .order('name', { ascending: true })) as PostgrestSingleResponse<IMerchants[]>;
};

const getDetailProduct = async (id?: string) => {
  return (await supabase
    .from('catalogue.products')
    .select('*,categories(id,name,parent_id), merchants(name)')
    .eq('id', id)
    .single()) as PostgrestSingleResponse<IProduct>;
};

const getWholesalePrice =async (id?: string) => {
  return (await supabase
    .from('catalogue.product_special_prices')
    .select('*').eq('product_id', id)) as PostgrestSingleResponse<IWholesalePrice[]>;
};

const getPendingUpdate =async (id?:string) => {
  const data = await supabase.
    from(TABLE.PRODUCT_PENDING_UPDATE).
    select(`id, product_id, status, is_active, images, name,
    description, main_price, insurance_flag, volume, minimum_purchase,
    category_id, video_url, is_main_price, maximum_purchase, sku`).
    eq('product_id', id).order('created_at', { ascending: false }) as PostgrestSingleResponse<PendingUpdate[]>;
  return data;
};

const getAllProductVariant = async (
  range:IPagination = { start: 0, end: 10 },
  search?: string
) => {
  const query = supabase
    .from('catalogue.product_variants')
    .select(
      'id, price, saleable_stock, sku_id, is_active, products!inner(name, status, merchants(id, name)), attribute_set_variants(value)',
      { count: 'exact' }
    )
    .is('is_active', true)
    .eq('products.status', 'APPROVED')
    .gt('saleable_stock', 0)
    .range(range.start, range.end);

  if (search && search !== '') {
    query.ilike('products.name', `%${search}%`);
  }

  return await query as PostgrestSingleResponse<IProductVariant[]>;
};

export const getProductMerchantDetail = async (id: string) => {
  return (
    await supabase.from('catalogue.product_variants')
      .select('id, saleable_stock, price, products(name, merchants(name)), attribute_set_variants(value)')
      .eq('id', id)
      .single()
  ) as PostgrestSingleResponse<IProduct>;
};

export const getProductsMerchantsDetail = async (id: string[]) => {
  return (
    await supabase.from('catalogue.product_variants')
      .select('id, saleable_stock, products(name, merchants(name))')
      .in('id', id)
  ) as PostgrestSingleResponse<IProduct[]>;
};

export const useFilterOptionProduct = () =>
  useQuery({
    queryKey: ['filter-product'],
    queryFn: getFilterOptionProduct,
    refetchOnWindowFocus: false
  });

export const useFilterOptionMerchant = () =>
  useQuery({
    queryKey: ['filter-merchant'],
    queryFn: getFilterOptionMerchants,
    refetchOnWindowFocus: false
  });

export const useProduct = (range:IPagination, sort:ISort, filter?: IFilterProduct, search?:string) =>
  useQuery({
    queryKey: ['product', filter, range, sort, search],
    queryFn: () => getAllProduct(range, sort, filter, search),
    refetchOnWindowFocus: false
  });

export const useProductVariant = (range: IPagination, search?: string) =>
  useQuery({
    queryKey: ['product-variant', range, search],
    queryFn: () => getAllProductVariant(range, search),
    refetchOnWindowFocus: false
  });

export const useTotalProduct = () =>
  useQuery({
    queryKey: ['total-product'],
    queryFn: getTotalProduct,
    refetchOnWindowFocus: false
  });

export const useDetailProduct = (id?: string | undefined) =>
  useQuery({
    queryKey: ['product_detail', id],
    queryFn: () => getDetailProduct(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useWholesalePrice = (id?: string | undefined) =>
  useQuery({
    queryKey: ['product-wholesale-price', id],
    queryFn: () => getWholesalePrice(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const usePendingUpdate = (id: string) =>
  useQuery({
    queryKey: ['product-pending-update', id],
    queryFn: () => getPendingUpdate(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export type {
  IProduct,
  ICategories,
  ICategory,
  IVolume,
  IMerchants,
  ISpecification,
  IProductVariants,
  ISetSpecification,
  ISpecifications,
  ISetVariants,
  IVariants,
  IFilterProduct
};
