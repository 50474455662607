import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Image } from 'primereact/image';
import { FC } from 'react';
import useCustom, { ILayoutList } from './hooks';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';


const PageBuilder: FC = () => {
  const { data, methods } = useCustom();
  const { canCreate, canDelete, canEdit, canView } = usePermissionStore();
  const imageIcon = (layout: ILayoutList) => {
    if (layout.iconImage) {
      return (
        <div className="">
          <Image src={layout.iconImage}
            zoomSrc={layout.iconImage}
            alt={`image${layout.iconImage}`}
            className="mx-0.5"
            width="300px" preview />
        </div>
      );
    }
    return (
      <div>{layout.name}</div>
    );
  };

  const header = [
    { field: 'id', header: 'Street ID' },
    { field: 'name', header: 'Street Name', body: imageIcon },
    { field: 'created_at', header: 'Created Date' },
    { field: 'updated_at', header: 'Updated Date' }
  ];

  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.paginator.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: methods.handleChangeDropdownPage,
    onClickNext: methods.handleClickNext,
    onClickPrev: methods.handleClickPrev,
    onJumptoPage: methods.handleJumpToPage,
    onChangeJumptoPage: methods.handleChangeJumpTopage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Street ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'street_id_from')}
              value={data.itemFilters.street_id_from}
              name="street_id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => methods.handleFilter(e, 'street_id_to')}
              value={data.itemFilters.street_id_to}
              name="street_id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Street Name</label>
          <InputText
            onChange={(e) => methods.handleFilter(e, 'street_name')}
            value={data.itemFilters.street_name}
            name="street_name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created Date</label>
          <Calendar
            onChange={(e) => methods.handleFilter(e, 'created_at')}
            value={data.itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Updated Date</label>
          <Calendar
            onChange={(e) => methods.handleFilter(e, 'updated_at')}
            value={data.itemFilters.updated_at}
            name="updated_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      {/* loading screen */}
      <LoadingOverlay show={data.isLoadingListlayout} />
      {/* confirm dialog */}
      <ConfirmDialog />
      <MainTitle title="Page Builder" nav="create" labelButton="Create New Street" isWithButton={canCreate} />
      <Table
        isWithCheckBox
        rows={data.totalRecords}
        filter={data.search}
        headerTable={header}
        onDeleted={methods.handleDeleteList}
        onSearch={methods.handleSearch}
        value={data.dataListLayout as []}
        paginationOptions={paginationOptions}
        isDetailAction={false}
        selection={data.selectedDataPageBuilder as []}
        onSelectionChange={
          (e) => {
            const value = e.value as [];
            methods.setSelectedDataPageBuilder(value);
          }}
        isDeleteAction={canDelete}
        isEditAction={canEdit || canView}
        filterContent={filterContent}
        filterHistory={data.filterHistory}
        onSubmitFilter={methods.handleClickSubmitFilter}
        handleClearFilter={methods.handleClearFilter}
        handleDeleteFilterHistory={methods.handleDeleteFilterHistory}
      />
    </BaseContainer>
  );
};

export default PageBuilder;
