import { useCustom } from './hooks';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import SubmitButton from '@/components/base/SubmitButton/SubmitButton';
import Asuransi from './component/Asuransi';
import GeneralInfo from './component/GeneralInformation';
import PriceWeight from './component/PriceWeight';
import VarianProduct from './component/VarianProduct';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import CategoryField from './component/Category';
import { Toast } from 'primereact/toast';
import LoadingOverlay from '@/components/base/LoadingOverlay';
import { ICategories, IProduct } from '@/services/rest/product';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { usePermissionStore } from '@/store/usePermissions';
import { STATUS } from '@/constants';
import notesIcon from '../../assets/file.svg';

const DetailProduct = () => {
  const { data, method } = useCustom();
  const { canEdit } = usePermissionStore();
  const footerContent = (
    <div>
      <Button label="No" onClick={() => method.setVisible(false)} className="p-button-text" />
      <Button label="Yes" onClick={() => method.onRejected(data.detailProduct.status)} />
    </div>
  );
  const header = (
    <div className="flex flex-col">
      <div className="font-bold py-2 px-1">Tolak dan Minta Revisi</div>
      <div className="text-xs px-1">Berikan deskripsi apa yang perlu Seller revisi dari informasi produk ini ya</div>
    </div>
  );

  const tempRevisionNote = data?.detailProduct?.revision_note?.replace(/\s/g, '');
  let revisionNote = '';

  if (tempRevisionNote?.length > 0) {
    revisionNote = data?.detailProduct?.revision_note;
  } else {
    revisionNote;
  }

  return (
    <BaseContainer>
      <LoadingOverlay show={data.isLoading}/>
      <MainTitle title="Review Product Approval" isBack isWithButton labelButton="Back" />

      {(data.detailProduct.status === STATUS.NEED_REVISION || data.detailProduct.status === STATUS.IN_REVIEW_UPDATE || data.detailProduct.status === STATUS.NEED_REVISION_UPDATE ) &&

      (<div>
        <div>
          <p className="text-base-gray">
          Ini catatan terakhir keterangan yang perlu direview ya
          </p>
        </div>

        <div className="mt-5 mb-12 border-2 rounded-2xl min-h-32 p-8 bg-yellow-light border-base-yellow">
          <div className="flex items-center gap-3">
            <img src={notesIcon} alt="notes"/>
            <p className="font-bold text-lg text-base-black-1 font-satoshi">Catatan Revisi</p>
          </div>
          {revisionNote.length > 0 ?
            (<pre className="mt-3 text-base-black-1 font-satoshi whitespace-pre-wrap">{revisionNote}</pre>) :
            (<p className="text-base-gray-1 mt-3 font-satoshi">Tidak ada deskripsi catatan</p>)
          }
        </div>
      </div>)}

      <CategoryField
        category={data.categories}
        selectedCategory={data.detailProduct?.status === STATUS.IN_REVIEW_UPDATE && data.detailProduct?.category_id ? data.selectedParent['3'] : data.detailProduct?.categories as ICategories}
        selectedMerchant={data.detailProduct?.merchants?.name as string}
        selectedParent={data.selectedParent}
        secondChild={data.secondSelect as [{ id: number, name: string, parent_id: number, child: ICategories[] }]}
        lastChild={data.lastSelect as [{ id: number, name: string, parent_id: number, child: ICategories[] }]}
      />

      <GeneralInfo
        product={data?.detailProduct as IProduct}
        specifications={data.specifications}
        onShowMore={method.onSHowMore}
        isOpen={data.isOpen}
        specStyle={data.specStyle}
      />
      <PriceWeight
        product={data.detailProduct as IProduct}
        stocks={data.stock}
      />
      <VarianProduct
        productStatus={data.detailProduct?.status}
        prodVariants={data.dataVariants}
        varType1={data.tipe1}
        varType2={data.tipe2}
        selected={data.selectedProducts}
        setSelected={method.setSelectedProducts}
      />
      <Asuransi
        insuranceFlag={data.detailProduct?.insurance_flag}
      />
      <div className="card flex justify-content-center">
        <Dialog id="product-dialog" header={header} visible={data.visible} style={{ width: '35vw' }} onHide={() => method.setVisible(false)} footer={footerContent}>
          <InputTextarea id="product-note-revision-input" className="w-full h-36" onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => method.setNoteRevision(e.target.value)} />
        </Dialog>
      </div>
      <ConfirmDialog />
      {data.detailProduct?.status !== 'APPROVED' &&
        <><Toast ref={data.toast} />
          <SubmitButton
            labelBack="Reject"
            labelSubmit="Approve"
            onCancel={() => {
              method.setVisible(true);
              method.setNoteRevision('');
            }}
            onSubmit={() => method.onApproved()}
            disabledSubmit={Boolean(!data.detailProduct?.category_id) || !canEdit}
            disabledEdit={!canEdit || data.validateStatus}
          />
        </>
      }
    </BaseContainer>
  );
};
export default DetailProduct;
