import React from 'react';
import useCustom, { IHighlight } from './hooks';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import Table from '@/components/base/DataTable/DataTable';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { formattedDate } from '@/utils/formatter';
import { usePermissionStore } from '@/store/usePermissions';
import { IPagination } from '@/components/base/Pagination';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { optionSection, optionSortBy } from './helper';


const ProductHighlightManagement = () => {
  const { data, method } = useCustom();
  const { canCreate, canView, canDelete, canEdit } = usePermissionStore();
  const startTemplate = (highlight: IHighlight) => {
    return (
      <div id={`product-highlight-date-${highlight?.sku}`}>{formattedDate(highlight?.started_time)}</div>
    );
  };
  const endTemplate = (highlight: IHighlight) => {
    return (
      <div id={`product-highlight-date-${highlight?.sku}`}>{formattedDate(highlight?.ended_time)}</div>
    );
  };
  const header = [
    { field: 'name', header: 'Product Name' },
    { field: 'sku', header: 'SKU' },
    { field: 'section', header: 'Section' },
    { field: 'street', header: 'Street' },
    { field: 'type', header: 'Sort Type' },
    { field: 'status', header: 'Status' },
    { field: 'started_time', header: 'Valid From', body: startTemplate },
    { field: 'ended_time', header: 'Valid To', body: endTemplate }
  ];

  // pagination
  const paginationOptions: IPagination = {
    perPage: data.perPage,
    totalPages: data.totalPages,
    currentPage: data.pagination.currentPage,
    disabledDropdown: data.totalRecords === 0,
    onChangeDropdownPage: method.handleChangeDropdownPage,
    onClickNext: method.handleClickNext,
    onClickPrev: method.handleClickPrev,
    onChangeJumptoPage: method.handleChangeJumpTopage,
    onJumptoPage: method.handleJumpToPage
  };

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Product Name</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">SKU</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'sku')}
            value={data.itemFilters.sku}
            name="sku"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Section</label>
          <Dropdown
            options={optionSection}
            onChange={(e) => method.handleFilter(e, 'section')}
            value={data.itemFilters.section}
            placeholder="Select Section"
            optionValue="code"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Sort Type</label>
          <Dropdown
            options={optionSortBy}
            onChange={(e) => method.handleFilter(e, 'sort_type')}
            value={data.itemFilters.sort_type}
            placeholder="Select Sort Type"
            optionValue="code"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
            onChange={(e) => method.handleFilter(e, 'status')}
            value={data.itemFilters.status}
            placeholder="Select Status"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Valid Date</label>
          <Calendar
            onChange={(e) => method.handleFilter(e, 'valid_date')}
            value={data.itemFilters.valid_date}
            name="valid_date"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
      </div>
    </div>
  );

  return (
    <BaseContainer>
      <MainTitle title="Product Highlight"/>
      <ConfirmDialog />
      <Toast ref={data.toast}/>
      <Table
        rows={data.totalRecords}
        isProductHighlight
        paginationOptions={paginationOptions}
        isLoadingTable={data.isLoading}
        customId="id"
        isWithButton={canCreate}
        filter={data.search}
        headerTable={header}
        onDeleted={method.confirmDelete}
        onSearch={method.handleSearch}
        value={data.highlight as []}
        isDeleteAction={canDelete}
        isDetailAction={canView}
        isEditAction={canEdit}
        filterHistory={data.filterHistory}
        handleClearFilter={method.handleClearFilter}
        handleDeleteFilterHistory={method.handleDeleteFilterHistory}
        onSubmitFilter={method.handleClickSubmitFilter}
        filterContent={filterContent}
      />
    </BaseContainer>
  );
};

export default ProductHighlightManagement;
