import * as yup from 'yup';
import { MESSAGE } from '@/constants';

export const SchemaCreateVoucher = yup.object().shape({
  name: yup.string().required(MESSAGE.REQUIRED),
  cooperation: yup.string().required(MESSAGE.REQUIRED),
  start_date: yup.string().required(MESSAGE.REQUIRED),
  end_date: yup.string().required(MESSAGE.REQUIRED),
  description: yup.string().required(MESSAGE.REQUIRED),
  voucher_type: yup.string().required(MESSAGE.REQUIRED),
  code: yup.string().when(['use_generator', 'voucher_type'], ([useGenerator, voucherType], schema) => {
    // check when use type specific
    if (voucherType === 'specific') {
      if (useGenerator) {
        return schema;
      }
    } else if (voucherType === 'auto') {
      return schema;
    }
    return yup.string().required(MESSAGE.REQUIRED);
  }),
  quota: yup.string().when(['use_generator'], ([useGenerator], schema)=> {
    if (useGenerator === true) {
      return schema;
    }
    return yup.string().required(MESSAGE.REQUIRED);
  }),
  user_quota: yup.string().when('is_user_quota_unlimited', (isQuotaUnlimited, schema) => {
    return isQuotaUnlimited.includes(true) ? schema : schema.required(MESSAGE.REQUIRED);
  }),
  min_payment_amount: yup.string().when('no_minimal', (isNoNominal, schema) => {
    return isNoNominal.includes(true) ? schema : schema.required(MESSAGE.REQUIRED);
  }),
  amount_type: yup.string().required(MESSAGE.REQUIRED),
  discount_amount: yup.string().when('amount_type', (type, schema) => {
    return type.includes('fixed') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  discount_percentage: yup.string().when('amount_type', (type, schema) => {
    return type.includes('percentage') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  max_discount_amount: yup.string().when('amount_type', (type, schema) => {
    return type.includes('percentage') ? schema.required(MESSAGE.REQUIRED) : schema;
  }),
  payment_method: yup.string().required(MESSAGE.REQUIRED),
  bin_code: yup.array().when('payment_method', (paymentMethod, schema) => {
    if (paymentMethod.includes('credit_card')) {
      return schema.min(1, MESSAGE.BIN_CODE_REQUIRED).required(MESSAGE.REQUIRED);
    }
    return schema;
  }),
  isSourceChecked: yup.boolean().isTrue(MESSAGE.REQUIRED),
  promoType: yup.string().required(MESSAGE.REQUIRED)
});
