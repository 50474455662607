import { supabase } from '@/lib/supabase';
import { TABLE } from '@/constants';


export const promoAuditTrails = async (name:string, id:string) => {
  if (id !== '') {
    const { data } = await supabase
      .from(TABLE.PROMOS).select('*').eq('id', id);
    name = data?.[0].name;
  }

  const dataPromos = await ( async () => {
    const { data: dataPromos } = await supabase
      .from(TABLE.PROMOS).select('*').eq('name', name).order('created_at', { ascending: true });
    return dataPromos || [];
  })() as PromosTable[];
  const newPromoTable:NewPromoTable[] = [];
  const newCode:Array<string> = [];
  if (dataPromos.length>0) {
    for (const data of dataPromos) {
      newCode.push(
        data.code || '' as string
      );
    }
    newPromoTable.push({
      ...dataPromos[0],
      code: newCode
    });
    return newPromoTable[0];
  }
};

interface PromosTable {
  id: string;
  code?: string;
  description: string;
  image?:string;
  amount_type: string;
  amount: number;
  start_date: string;
  end_date: string;
  status: boolean;
  type: string;
  accepted_payment_provider_ids?: Array<string>;
  payment_bin_rule:Array<string>;
  quota: number;
  user_quota: number;
  is_quota_unlimited: boolean;
  is_user_quota_unlimited: boolean;
  min_discount_amount: number;
  max_discount_amount: number;
  min_payment_amount: number;
  max_payment_amount: number;
  name: string
  created_at: string;
  periode_start:string
  periode_end:string
  title:string
  code_info:string
  path_url:string
  is_payment_cc:string
  multiple_code:string
  multiple_description:string
  promo_category_id:string
  is_public:string
  target_platform:string
  borne_by_seller:string
  images:string
  is_code_generator:string
  merchant_cooperation:string
  parent_id:string
}

interface NewPromoTable{
  id: string;
  code?: Array<string>;
  description: string;
  image?:string;
  amount_type: string;
  amount: number;
  start_date: string;
  end_date: string;
  status: boolean;
  type: string;
  accepted_payment_provider_ids?: Array<string>;
  payment_bin_rule:Array<string>;
  quota: number;
  user_quota: number;
  is_quota_unlimited: boolean;
  is_user_quota_unlimited: boolean;
  min_discount_amount: number;
  max_discount_amount: number;
  min_payment_amount: number;
  max_payment_amount: number;
  name: string
  created_at: string;
  periode_start:string
  periode_end:string
  title:string
  code_info:string
  path_url:string
  is_payment_cc:string
  multiple_code:string
  multiple_description:string
  promo_category_id:string
  is_public:string
  target_platform:string
  borne_by_seller:string
  images:string
  is_code_generator:string
  merchant_cooperation:string
  parent_id:string
}
