import React from 'react';
import MainTitle from '@/components/base/MainTitle/MainTitle';
import BaseContainer from '@/components/base/BaseContainer/BaseContainer';
import useCustom from './hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconShow from '@/assets/IconShow';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import IconTrash from '@/assets/IconTrash';
import { InputText } from 'primereact/inputtext';
import IconPencil from '@/assets/IconPencil';
import FilterHistory from '@/components/base/FilterHistory';
import { usePermissionStore } from '@/store/usePermissions';
import { Link } from 'react-router-dom';
import Pagination from '@/components/base/Pagination';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { IBannerOptions } from './CreateEditBanner/hooks';

const Banner = () => {
  const { data, method } = useCustom();
  const { canCreate, canEdit, canDelete, canView } = usePermissionStore();

  const optionBannerType: IBannerOptions[] = [
    { label: 'Promo Header Banner', code: 'HEADER_BANNER' },
    { label: 'Main Banner', code: 'MAIN_BANNER' },
    { label: 'Promo Ads Banner 1', code: 'ADS_BANNER' },
    { label: 'Produk Pilihan', code: 'OPTIONAL_PRODUCT_BANNER' },
    { label: 'Brand Banner', code: 'BRAND_BANNER' },
    { label: 'Hot Deals', code: 'HOT_DEAL_BANNER' },
    { label: 'Banner Produk Kategori', code: 'PRODUCT_CATEGORY_BANNER' },
    { label: 'Promo Page', code: 'PROMO_BANNER' },
    { label: 'Footer', code: 'FOOTER' },
    { label: 'Street', code: 'STREET' },
    { label: 'Flash Banner', code: 'FLASH_SALE_BANNER' },
    { label: 'Official Store Banner', code: 'OFFICIAL_STORE_BANNER' },
    { label: 'Section Video Background', code: 'VIDEO_BANNER' },
    { label: 'Section Flash Sale Background', code: 'SECTION_FLASH_SALE_BACKGROUND' }
  ];

  const filterContent = (
    <div className="flex flex-col gap-4 w-[900px]">
      <div className="w-full flex flex-col items-start gap-2">
        <h1 className="font-bold">Banner ID</h1>
        <div className="flex w-full gap-8">
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Dari</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_from')}
              value={data.itemFilters.id_from}
              name="id_from"
            />
          </div>
          <div className="flex gap-2 items-center w-1/2">
            <label htmlFor="filter-id" className="w-3/4">Sampai</label>
            <InputNumber
              onChange={(e) => method.handleFilter(e, 'id_to')}
              value={data.itemFilters.id_to}
              name="id_to"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Banner Name</label>
          <InputText
            onChange={(e) => method.handleFilter(e, 'name')}
            value={data.itemFilters.name}
            name="name"
            keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Banner Category</label>
          <Dropdown
            value={data.itemFilters.type}
            onChange={(e) => {
              method.handleFilter(e, 'type');
            }}
            placeholder="Select Category"
            options={optionBannerType}
            optionLabel="label"
            optionValue="code"
            className="w-full"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-status" className="font-bold">Created Date</label>
          <Calendar
            onChange={(e) => method.handleFilter(e, 'created_at')}
            value={data.itemFilters.created_at}
            name="created_at"
            className="w-full"
            selectionMode="range"
            showIcon
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <label htmlFor="filter-username" className="font-bold">Status</label>
          <Dropdown
            value={data.itemFilters.status}
            onChange={(e) => {
              method.handleFilter(e, 'status');
            }}
            placeholder="Select Category"
            options={[{ label: 'ACTIVE', value: 'ACTIVE' }, { label: 'INACTIVE', value: 'INACTIVE' }]}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex justify-end py-4 gap-4">
      <Button label="Batal" severity="secondary" outlined onClick={() => method.setIsOpenDialog(false)} size="large" />
      <Button label="Terapkan" severity="info" onClick={method.handleClickSubmitFilter} size="large" />
    </div>
  );

  return (
    <>
      <Toast ref={data.refToastList} />
      <BaseContainer>
        <MainTitle title="Banner" labelButton="Add New Banner" nav="create" isWithButton={canCreate} />
        <ConfirmDialog />
        <div className="flex flex-col gap-2 my-2">
          <div className="flex justify-end">
            <Button
              icon="pi pi-sliders-h"
              onClick={() => method.setIsOpenDialog(true)}
              label="Filter"
              severity="secondary"
              text
              raised
            />
          </div>
          {data.filterHistory.length > 0 ?
            <FilterHistory
              filterHistory={data.filterHistory}
              handleClearFilter={method.handleClearFilter}
              handleDeleteFilterHistory={method.handleDeleteFilterHistory}
              show={data.filterHistory.length > 0}
            /> :
            <Divider className="p-divider-solid " />
          }
          <div className="flex justify-between items-center my-4">
            <div id="search" className="p-input-icon-right w-[20%]">
              <InputText
                className="w-full"
                placeholder="Cari Disini"
                type="search"
                value={data.search}
                onInput={method.handleSearch}
                keyfilter={/^[a-zA-Z0-9()+\-\s]+$/}
              />
              <i className="pi pi-search" />
            </div>
            <div className="flex justify-end w-full">
              <Pagination
                perPage={data.perPage}
                totalPages={data.totalPages}
                currentPage={data.paginator.currentPage}
                disabledDropdown={data.totalRecords === 0}
                onClickPrev={method.handleClickPrev}
                onClickNext={method.handleClickNext}
                onChangeDropdownPage={method.handleChangeDropdownPage}
                onJumptoPage={method.handleJumpToPage}
                onChangeJumptoPage={method.handleChangeJumpTopage}
              />
            </div>
          </div>
        </div>
        <DataTable
          id="banner-table"
          value={data.banner}
          lazy
          totalRecords={data.totalRecords}
          scrollable
          scrollHeight="40rem"
          loading={data.isLoadingBanner}
          showGridlines
          dataKey="id"
          tableStyle={{ minWidth: '12rem' }}
          selection={data.selectedRecords}
          onSelectionChange={method.onSelectionChange}
          selectionPageOnly
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          {data.headers.map((header, index) => {
            switch (header.field) {
            case 'street':
              return (
                <Column
                  key={index}
                  header={header.header}
                  body={(rowData) => {
                    return (
                      Array.isArray(rowData.banner_applied_streets) &&
                    rowData.banner_applied_streets?.map((street) => street?.streets?.name)
                    );
                  }}
                />
              );
            case 'category':
              return (
                <Column
                  key={index}
                  header={header.header}
                  body={(rowData) => {
                    return (
                      method.renderLabelCategoryBanner(rowData.type)
                    );
                  }}
                />
              );
            case 'desktopImage':
              return (
                <Column
                  key={index}
                  header={header.header}
                  body={(rowData) => {
                    const image = rowData.image.find((it) => it.image);
                    return (
                      <Image src={image && image.image} width="200px" preview/>
                    );
                  }}
                />
              );
            case 'mobileImage':
              return (
                <Column
                  key={index}
                  header={header.header}
                  body={(rowData) => {
                    const image = rowData.image.find((it) => it.image);
                    if (rowData.type === 'BRAND_BANNER') {
                      return (
                        <Image src={image && image.image} width="200px" preview/>
                      );
                    }
                    return (
                      <Image src={image && (image.image_mobile)} width="200px" preview/>
                    );
                  }}
                />
              );
            case 'thumbnailImage':
              return (
                <Column
                  key={index}
                  header={header.header}
                  body={(rowData) => {
                    const image = rowData.image.find((it) => it.image);
                    if (rowData.type === 'BRAND_BANNER') {
                      return (
                        <Image src={image && image.image} width="200px" preview/>
                      );
                    }
                    return (
                      <Image src={image && image.thumbnail} width="200px" preview/>
                    );
                  }}
                />
              );
            default:
              return (
                <Column key={index} header={header.header} field={header.field} sortField={header.field} sortable />
              );
            }
          })}
          <Column
            header="Action"
            alignHeader="center"
            align="center"
            style={{ width: '6rem' }}
            body={(rowData) => (
              <div className="flex gap-2">
                <Link to={canView ? `/frontstore-management/banner/${rowData.id}` : ''}>
                  <Button
                    id="product-detail-button"
                    icon={IconShow}
                    outlined
                    rounded
                    severity="secondary"
                    disabled={!canView}
                  />
                </Link>
                <Link to={canEdit ? `/frontstore-management/banner/edit/${rowData.id}` : ''}>
                  <Button
                    id="product-edit-button"
                    icon={IconPencil}
                    outlined
                    rounded
                    severity="secondary"
                    disabled={!canEdit}
                  />
                </Link>
                <Button
                  id="product-delete-button"
                  icon={IconTrash}
                  outlined
                  rounded
                  severity="danger"
                  onClick={() => method.handleClickDelete(rowData)}
                  disabled={!canDelete}
                />
              </div>
            )}
          />
        </DataTable>
      </BaseContainer>
      <Dialog onHide={() => method.setIsOpenDialog(false)} visible={data.isOpenDialog} header="Semua Filter" footer={footerContent}>
        {filterContent}
      </Dialog>
    </>
  );
};

export default Banner;
