import { supabase } from '@/lib/supabase';
import { STATUS_TRANSACTION } from '@/constants';
import {
  getTransactionList,
  IFilterTransaction,
  OrderTransactionsListBuyer,
  updateOrderStatus,
  useTransactionList
} from '@/services/rest/transaction';
import { exportTable } from '@/utils/exportTable';
import { concat, debounce, uniqBy } from 'lodash';
import { DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissionStore } from '@/store/usePermissions';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { useDebounce } from 'primereact/hooks';
import dayjs from 'dayjs';

const useCustom = () => {
  const initialFilter: IFilterTransaction = {
    id_from: null,
    id_to: null,
    purchased_product: '',
    buyer_name: '',
    recipient_information: '',
    status: ''
  };

  const { canEdit, canView } = usePermissionStore();
  const [isReturnPart, setIsReturnPart] = useState<boolean>(false);
  const [returnedProducts, setReturnedProducts] = useState<{ id: string, quantity: number, maxQuantity: number }[]>([]);
  const [requester, setRequester] = useState<{ label: string, value: string }>({ label: '', value: '' });
  const [preferedAction, setPreferedAction] = useState<string|null>(null);
  const [foundMerchants, setFoundMerchants] = useState<{ id: string, name: string }[]|null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  const [changeNote, setChangeNote] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<OrderTransactionsListBuyer | null>(null);
  const toast = useRef<Toast | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [poId, setPoId] = useState<string>('');
  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const currentPage = parseInt(visitedPage.orderManagement.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({
    currentPage,
    range: {
      start,
      end
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);
  const [filterMerchant, setFilterMerchant] = useState<string[]>([]);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [expanded, setExpanded] = useState<DataTableExpandedRows| DataTableValueArray | undefined>(undefined);
  const [itemFilters, setItemFilters] = useState<IFilterTransaction>(lastFilterPage.orderManagement != '' ? JSON.parse(String(lastFilterPage.orderManagement)) : initialFilter);
  const [filters, setFilters] = useState<IFilterTransaction>(lastFilterPage.orderManagement != '' ? JSON.parse(String(lastFilterPage.orderManagement)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const filterSearchTable = useMemo(() => {
    const searchidOrderList = search?.split('-')[0];
    if (search && !isNaN(+searchidOrderList)) {
      return '';
    }
    return search;
  }, [search]);

  const { data: dataResponses, refetch, isLoading } = useTransactionList(paginator.range, filters, debounceSearch);
  const { results: dataTransactionList, count } = dataResponses || { results: [], count: 0 };

  const transactions = useMemo(()=>{
    return dataTransactionList?.map((transaction)=>({
      ...transaction,
      product_name: transaction.products[0]?.product?.name,
      order_date: dayjs(transaction.order_transactions[0]?.orders?.created_at).format('DD MMMM YYYY HH:mm:ss')
    }));
  }, [dataTransactionList]);

  const totalRecords = useMemo(() => {
    return count;
  }, [dataResponses]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const header = [
    { field: 'transaction_id', header: 'Transaction ID' },
    { field: 'order_date', header: 'Order Date' },
    { field: 'products', header: 'Purchased Product' },
    { field: 'buyer_detail.name', header: 'Buyer Name' },
    { field: 'shipping_address_detail', header: 'Recipient Information' },
    { field: 'order_status', header: 'Transaction Status' }
  ];

  const globalFilterFields = [
    'transaction_id',
    'product_name',
    'buyer_detail.name',
    'shipping_address_detail.address',
    'order_status'
  ];

  const poTableHeader = [
    { field: 'account_transaction_id', header: 'Order ID' },
    { field: 'order_date', header: 'Order Date' },
    { field: 'products', header: 'Produk Yang Dibeli' },
    { field: 'merchants', header: 'Informasi Merchant' },
    { field: 'delivery', header: 'Informasi Pengiriman' },
    { field: 'order_status', header: 'Status Order' }
  ];

  const buttonActions = useCallback((disabledCancelButton: boolean) => {
    const detailButton = {
      label: 'Lihat Detail',
      type: 'detail',
      icon: 'pi pi-eye',
      disabled: !canView,
      url: `/order-management/${selectedRow?.order_id}?po_id=${poId}`,
      command: (e) => {
        navigate(`/order-management/${selectedRow?.order_id}?po_id=${poId}`);
      }
    };

    const cancelButton = {
      label: 'Batalkan Pesanan',
      type: 'cancel',
      icon: 'pi pi-times-circle',
      disabled: !canEdit,
      command: async (e) => {
        setPreferedAction('cancel_order');
        setVisible(true);
      }
    };

    const completeButton = {
      label: 'Selesaikan Pesanan',
      type: 'finish',
      icon: 'pi pi-check-circle',
      disabled: !canEdit,
      command: async (e) => {
        setPreferedAction('complete_order');
        setVisible(true);
      }
    };

    const returnButton = {
      label: 'Return',
      type: 'return',
      icon: 'pi pi-replay',
      disabled: true, //!canEdit implement when ready
      command: async (e) => {
        setPreferedAction('return_order');
        setVisible(true);
      }
    };

    if (disabledCancelButton) return [detailButton, completeButton, returnButton];
    return [detailButton, cancelButton, completeButton, returnButton];
  }, [selectedRow, poId]);

  const handleSelectedActions = useCallback((item: OrderTransactionsListBuyer | null, poId?: string) => () => {
    setPoId(poId as string);
    setSelectedRow(item);
  }, [selectedRow]);

  const modalPresets = (actionType: string) => {
    const orderNo = selectedRow ? selectedRow.order_id : 'N/A';
    const subTotal = selectedRow && selectedRow.orders.total_amount || 0;
    const shippingFee = selectedRow && selectedRow.orders.shipping_fee || 0;
    const discount = 0;
    const buyerName = selectedRow?.buyer_detail.name;
    const sellerName = selectedRow?.orders.merchant_name;

    const presets = {
      cancel_order: {
        title: 'Pembatalan Pesanan',
        description: `Batalkan pesanan ${orderNo} dan dana sebesar Rp ${
          subTotal + shippingFee - discount
        } akan dikembalikan ke ${buyerName}`
      },
      complete_order: {
        title: 'Selesaikan Pesanan',
        description: `Selesaikan pesanan ${orderNo} dan dana sebesar Rp ${
          subTotal - discount
        } Seller akan dibayarkan ke ${sellerName}`
      },
      return_order: {
        title: 'Pengembalian Pesanan',
        description: `Proses pengembalian pesanan ${orderNo} dan dana sebesar Rp ${
          subTotal - discount
        } akan dikembalikan ke ${buyerName}`
      }
    };

    return presets[actionType];
  };

  const resetModal = () => {
    setChangeNote('');
    setRequester({ label: '', value: '' });
    setVisible(false);
  };

  const onSubmit = async () => {
    const timeNow = new Date();
    const adminId = (await supabase.auth.getUser()).data.user?.id;

    if (preferedAction === 'cancel_order') {
      if (selectedRow?.order_id != null &&
        [STATUS_TRANSACTION.WAITING_APPROVAL, STATUS_TRANSACTION.ON_THE_WAY, STATUS_TRANSACTION.READY].includes(selectedRow?.orders.order_status ?? STATUS_TRANSACTION.WAITING_PAYMENT)) {
        const { error } = await updateOrderStatus(selectedRow?.order_id!, { updated_at: timeNow.toISOString(), status: 'cancel', cancel_notes: changeNote, cancel_by: requester.value === 'buyer' ? selectedRow?.buyer_detail.name : selectedRow?.orders.merchant_id });
        if (!error) {
          toast.current?.show({ severity: 'success', summary: 'Success Batalkan Pesanan.', detail: 'Message Content', life: 3000 });
          setSearch('');
          setSelectedRow(null);
          refetch();
        }
        resetModal();
        return;
      }
      toast.current?.show({ severity: 'info', summary: 'Info', detail: `Tidak dapat Batalkan Pesanan.\nStatus order: ${selectedRow?.orders.order_status}`, life: 3000 });
    }

    if (preferedAction === 'complete_order') {
      if (selectedRow?.order_id != null &&
        [STATUS_TRANSACTION.ON_THE_WAY, STATUS_TRANSACTION.DELIVERED].includes(selectedRow?.orders.order_status ?? STATUS_TRANSACTION.WAITING_PAYMENT)) {
        const { error } = await updateOrderStatus(selectedRow?.order_id!, { updated_at: timeNow.toISOString(), status: 'completed', updated_by: adminId });
        if (!error) {
          toast.current?.show({ severity: 'success', summary: 'Success Selesaikan Pesanan.', detail: 'Message Content', life: 3000 });
          setSearch('');
          setSelectedRow(null);
          refetch();
        }
        resetModal();
        return;
      }
      toast.current?.show({ severity: 'info', summary: 'Info', detail: `Tidak dapat Selesaikan Pesanan.\nStatus order: ${selectedRow?.orders.order_status}`, life: 3000 });
    }

    if (preferedAction === 'return_order') {
      if (selectedRow?.order_id != null &&
        [STATUS_TRANSACTION.DELIVERED].includes(selectedRow?.orders.order_status ?? STATUS_TRANSACTION.WAITING_PAYMENT)) {
        const cancelNote = `${changeNote} || ${isReturnPart ? 'Kembali Sebagian' : 'Kembali Sepenuhnya'} || ${JSON.stringify(returnedProducts)}`;

        const { error } = await updateOrderStatus(selectedRow?.order_id!, { updated_at: timeNow.toISOString(), status: 'return', updated_by: adminId, cancel_notes: cancelNote });
        if (!error) {
          toast.current?.show({ severity: 'success', summary: 'Success Return Pesanan.', detail: 'Message Content', life: 3000 });
          setSearch('');
          setSelectedRow(null);
          refetch();
        }
        resetModal();
        return;
      }
      toast.current?.show({ severity: 'info', summary: 'Info', detail: `Tidak dapat Return Pesanan.\nStatus order: ${selectedRow?.orders.order_status}`, life: 3000 });
    }
  };

  const findMerchantsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.merchants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const concatData = uniqBy([...foundMerchants || [], ...data], 'id');
      setFoundMerchants(concatData);
    }
  }, 250);

  const findMerchants = async (findName: string | null) => {
    findMerchantsDebounce(findName);
  };

  const getFilterOptions = () => {
    const filters: { label: string, items: { label: string, value: string }[] }[] = [];

    filters.push({
      label: 'Status',
      items: Object.keys(STATUS_TRANSACTION).map((s) => ({ label: STATUS_TRANSACTION[s], value: `status##${s.toLocaleLowerCase()}` }))
    });

    filters.push({
      label: 'Merchants',
      items: foundMerchants?.map((m) => ({ label: m.name, value: `merchant##${m.id}` })) || []
    });

    return filters;
  };

  const getSelectedFilters = () => {
    const merchants =
      filterMerchant && filterMerchant.length ? filterMerchant.map((m) => `merchant##${m}`) : [];
    const statuses = filterStatus && filterStatus.length ? filterStatus.map((s) => `status##${s}`) : [];

    return concat(merchants, statuses);
  };

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      orderManagement: ''
    });
    setFilters(initialFilter);
    setItemFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleDeleteFilterHistory = useCallback(
    (_: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);


  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
    setIsOpenDialog(false);
  }, [itemFilters]);

  const storeFilterHistory = useCallback((filter: IFilterTransaction) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('ID', 'id_from,id_to', filterID));
    }

    if (filter.purchased_product !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Purchased Product', 'purchased_product', filter.purchased_product));
    }

    if (filter.buyer_name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Buyer Name', 'buyer_name', filter.buyer_name));
    }

    if (filter.recipient_information !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Recipient Information', 'recipient_information', filter.recipient_information));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', STATUS_TRANSACTION[filter.status.toUpperCase()]));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  const handleExportData = async () => {
    setIsExportLoading(true);
    const now = new Date();

    const transactionResponse = await getTransactionList({ start: 0, end: 100 }, filters);
    const _transactions = Array.isArray(transactionResponse?.results) && transactionResponse?.results.map((item)=>({
      ...item
    }));


    if (_transactions) {
      const exportDataMapper: {
        transaction_id: number | null,
        order_id: number | null,
        buyer_name: string | null,
        merchant_name: string | null,
        product_variant_id: string | null,
        product_id: number | null,
        product_name: string | null,
        product_price: number | null,
        shipping_price: number | null,
        quantity: number | null,
        order_status: string | null,
        recipient_address: string | null,
        delivery_info: string | null
      }[] = [];
      _transactions.forEach((t) => {
        t.order_transactions?.forEach((ot) => {
          ot.orders.order_list.forEach((ol) => {
            exportDataMapper.push({
              transaction_id: ot.account_transaction_id,
              order_id: ot.order_id,
              buyer_name: ot.buyer_detail.name,
              merchant_name: ot.orders.merchant_name,
              product_variant_id: ol.product_variant_id,
              product_id: ol.product_metadata?.['product']?.['id'],
              product_name: ol.product_metadata?.['product']?.['name'],
              product_price: ol.product_metadata?.['product']?.['price'],
              shipping_price: ot.orders.delivery_metadata?.['price'],
              quantity: ol.quantity,
              order_status: ot.orders.order_status,
              recipient_address: Object.keys(ot.shipping_address_detail || {}).map((key, value) => {
                if (ot.shipping_address_detail && ot.shipping_address_detail[key]) {
                  if (ot.shipping_address_detail[key] instanceof Object) {
                    return `${key}: ${ot.shipping_address_detail[key]['name']}\n`;
                  }
                  return `${key}: ${ot.shipping_address_detail[key]}\n`;
                }
                return null;
              }).join('\n'),
              delivery_info: `${ot.orders.delivery_metadata?.['name']} ${ot.orders.delivery_metadata?.['type']}`
            });
          });
        });
      });

      setIsExportLoading(false);
      return exportTable(exportDataMapper as [], `aladinmall-order-${now.toISOString()}.xlsx`);
    }

    setIsExportLoading(false);
  };

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      orderManagement: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    setLastFilterPage({
      ...lastFilterPage,
      orderManagement: JSON.stringify(filters)
    });
  }, [filters]);

  return {
    data: {
      toast,
      header,
      search,
      expanded,
      poTableHeader,
      buttonActions,
      isLoading,
      transactions,
      globalFilterFields,
      totalRecords,
      totalPages,
      paginator,
      perPage,
      visible,
      changeNote,
      requester,
      preferedAction,
      selectedRow,
      returnedProducts,
      isReturnPart,
      foundMerchants,
      filterMerchant,
      filterStatus,
      filterHistory,
      isExportLoading,
      filterSearchTable,
      isOpenDialog,
      itemFilters
    },
    methods: {
      setSearch,
      setVisible,
      setExpanded,
      handleSelectedActions,
      setPaginator,
      handleChangeDropdownPage,
      setPerPage,
      handleClickNext,
      handleClickPrev,
      setChangeNote,
      onSubmit,
      modalPresets,
      setRequester,
      setReturnedProducts,
      setIsReturnPart,
      resetModal,
      findMerchants,
      getFilterOptions,
      setFilterMerchant,
      setFilterStatus,
      getSelectedFilters,
      handleDeleteFilterHistory,
      handleClearFilter,
      handleExportData,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleSearch,
      handleFilter,
      handleClickSubmitFilter,
      setIsOpenDialog
    }
  };
};

export default useCustom;
