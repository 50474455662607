import { TABLE } from '@/constants';
import { supabase } from '@/lib/supabase';
import { Database as DB_CATALOGUE } from '@/types/services/catalogue';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

export type Promos = {
  cooperation_value: string;
  cooperation_with: String;
 id: string;
 name: string;
 type: string;
 platforms?: string[];
 customers?: never[];
 cooperation?: string;
 merchants: string[];
 borne_by_seller?: number;
 start_date: string;
 end_date: string;
 periode_start: string;
 periode_end: string;
 status: boolean;
 description: string;
 code: string;
 quota: number;
 user_quota: number;
 is_user_quota_unlimited: boolean;
 is_quota_unlimited: boolean;
 image:string;
 min_payment_amount: number;
 max_payment_amount: number;
 min_discount_amount: number;
 max_discount_amount: number;
 amount_type: string;
 amount: number;
 payment_method?: string;
 discount_by?: string;
 bin_code?: [];
 voucher_type?: string;
 applied_product: string[];
 exclude_type?: string;
 labels?: string;
 exclude: [];
 applied_promo_tags: [];
 accepted_payment_provider_ids: Array<PaymentProviders>;
 promo_applied_merchant_variants: Array<PromoAppliedMerchants>;
 promo_applied_product_variants: Array<PromoAppliedProducts>;
 promo_applied_product_variant_promo_tags: Array<PromoAppliedPromoTags>;
 promo_excluded_product_variants: Array<PromoExcludedProduct>;
 promo_excluded_merchant_variants: Array<PromoExcludeMerchant>;
 promo_excluded_product_variant_promo_tags: Array<PromoExcludePromoTags>;
 promo_applied_shipping_providers?: Array<PromoAppliedShippingProviders>;
 is_payment_cc: boolean;
 payment_bin_rule:Array<string>,
 is_public: boolean,
 target_platform: Array<string>,
 merchant_cooperation: Array<string>,
 is_code_generator: boolean
  source: string;
  promo_type: string;
  sourceAM: boolean
  sourceRPlus: boolean
};

type PayloadPromo = {
 id?: string | null;
 name: string;
 type: string;
 platforms?: string[];
 customers?: never[];
 cooperation?: string;
 merchants?: string[];
 borne_by_seller?: number;
 start_date: string;
 end_date: string;
 status: boolean;
 description: string;
 code: string;
 quota: number;
 user_quota: number;
 is_user_quota_unlimited: boolean;
 is_quota_unlimited: boolean;
 image?:string;
 min_payment_amount: number;
 max_payment_amount: number;
 min_discount_amount: number;
 max_discount_amount: number;
 amount_type: string;
 amount: number;
 payment_method?: string;
 discount_by?: string;
 bin_code?: [];
 voucher_type?: string;
 applied_product?: string[];
 exclude_type?: string;
 labels?: string;
 exclude?: string[];
 applied_promo_tags?: string[];
 accepted_payment_provider_ids?: Array<PaymentProviders> | null;
 promo_applied_merchant_variants?: Array<PromoAppliedMerchants>;
 promo_applied_product_variants?: Array<PromoAppliedProducts>;
 promo_applied_product_variant_promo_tags?: Array<PromoAppliedPromoTags>;
 promo_excluded_product_variants?: Array<PromoExcludedProduct>;
 promo_applied_shipping_providers?: Array<PromoAppliedShippingProviders>;
 is_payment_cc: boolean;
 payment_bin_rule:Array<string>
 is_code_generator?: boolean
}

type PayloadInvoke = {
  id: string;
  type: string;
  start_date: string;
  end_date: string;
  status: boolean;
  description: string;
  code: string;
  cooperation_with: string;
  cooperation_value: string;
  quota: number;
  user_quota: number;
  is_user_quota_unlimited: boolean;
  is_quota_unlimited: boolean;
  image?:string;
  min_payment_amount: number;
  max_payment_amount: number;
  min_discount_amount: number;
  max_discount_amount: number;
  amount_type: string;
  amount: number;
  accepted_payment_provider_ids?: Array<PaymentProviders>;
  promo_applied_merchant_variants?: Array<PromoAppliedMerchants>;
  promo_applied_product_variants?: Array<PromoAppliedProducts>;
  promo_applied_product_variant_promo_tags?: Array<PromoAppliedPromoTags>;
  promo_applied_shipping_providers?: Array<PromoAppliedShippingProviders>;
  promo_excluded_product_variants?: Array<PromoExcludedProduct>;
  promo_excluded_merchant_variants?: Array<PromoExcludeMerchant>;
  promo_excluded_product_variant_promo_tags?: Array<PromoExcludePromoTags>;
  is_payment_cc: boolean;
  payment_bin_rule:Array<string>;
  target_platform?: string[];
  is_public?: boolean
  source?: string,
  promo_type?: string,
}

type PromoExcludedProduct = {
 id?: string;
 product_variant_ids: Array<string>;
};

type PromoExcludeMerchant = {
  id?: string;
  merchant_ids: Array<string>
}

type PromoExcludePromoTags = {
  id?: string;
  product_variant_promo_tag_ids: Array<string>
}

type PromoAppliedMerchants = {
 id?: string;
 promo_id?: string;
 merchant_ids: Array<string>;
};
type PromoAppliedPromoTags = {
 id?: string;
 promo_id?: string;
 product_variant_promo_tag_ids: Array<string>;
};
type PromoAppliedProducts = {
 id?: string;
 promo_id?: string;
 product_variant_ids: Array<string>;
};
type PromoAppliedShippingProviders = {
  id?: string;
  promo_id?: string;
  shipping_provider_ids: Array<string>
}

type PaymentProviders = {
 id: number | string;
 code: string;
 label: string;
};

interface PaymentProvider {
 id: number;
 group: string;
 name: string;
 name_en: string;
 slug: string;
 active: boolean;
 use_phone_number: boolean;
 gateway: string;
 logo: string;
 maximum_pay_amount: number;
 minimum_pay_amount: number;
 description: string;
 description_en: string;
 expiry_duration: {
 duration: number;
 unit: string;
 };
 created_by: number;
 updated_by: number;
 created_at: string;
 updated_at: string;
 instructions: string[];
}

interface PaymentProviderResult {
 data: PaymentProvider[] | null;
}

type ProductVariants = {
 id: string;
};

type Product = {
 id: string;
 name: string;
 product_variants: ProductVariants[];
};


type PromoTags = DB_CATALOGUE['public']['Tables']['promo_tags']['Row'];
type Merchants = DB_CATALOGUE['public']['Tables']['merchants']['Row'];
type Variant = DB_CATALOGUE['public']['Tables']['product_variants']['Row']
type products = DB_CATALOGUE['public']['Tables']['products']['Row']

export interface IProductVariant extends Variant {
  products: products
}

const getVoucherById = async (id: string) => {
  return (await supabase
    .from(TABLE.PROMOS)
    .select(
      `*,promo_applied_merchant_variants(id, promo_id, merchant_ids),
      promo_applied_product_variants(id,promo_id, product_variant_ids),
      promo_applied_product_variant_promo_tags(id,promo_id,product_variant_promo_tag_ids),
      promo_excluded_product_variants(id,product_variant_ids),
      promo_excluded_merchant_variants(id, merchant_ids),
      promo_excluded_product_variant_promo_tags(id, product_variant_promo_tag_ids)`
    )
    .eq('id', id)
    .single()) as PostgrestSingleResponse<Promos>;
};

const getAllPaymentProviders = async () => {
  const bodyData = {
    method: 'GET',
    headers: {
      'X-Merchant-Id': '1',
      'http-x-platform': 'mobile'
    },
    path: '/payment-gateways/v2/payment-method?page&perpage=30'
  };
  return (await supabase.functions.invoke('payment-api', {
    body: bodyData
  })) as PostgrestSingleResponse<PaymentProviderResult>;
};

export const getAllProductAppliedByIds = async (ids?: string[]) => {
  const query = supabase
    .from(TABLE.PRODUCT)
    .select('id,name,product_variants!inner(id)');
  if (ids) {
    query.in('product_variants.id', ids);
  }
  return (await query) as PostgrestSingleResponse<Product[]>;
};

const getAllProductExcludedByIds = async (ids?: string[]) => {
  const query = supabase
    .from(TABLE.PRODUCT)
    .select('id,name,product_variants!inner(id)');
  if (ids) {
    query.in('product_variants.id', ids);
  }
  return (await query) as PostgrestSingleResponse<Product[]>;
};

const getAllProduct = async () => {
  const query = supabase
    .from(TABLE.PRODUCT)
    .select('id,name,product_variants!inner(id)')
    .range(0, 10);
  return (await query) as PostgrestSingleResponse<Product[]>;
};

const getProductsByVariantsId = async () => {
  return (await supabase
    .from(TABLE.PRODUCT_VARIANTS)
    .select('id, products(id,name)')
    .range(0, 10)
  ) as PostgrestSingleResponse<IProductVariant[]>;
};

const getProductByVariantId = async (ids: string[]) => {
  return (await supabase
    .from(TABLE.PRODUCT_VARIANTS)
    .select('id, product_id')
    .in('id', ids) as PostgrestSingleResponse<IProductVariant[]>
  );
};

export const getAlVariants =async (ids:string[]) => {
  const query = supabase
    .from(TABLE.PRODUCT)
    .select('id,product_variants!inner(id)')
    .in('id', ids);
  return (await query) as PostgrestSingleResponse<Product[]>;
};

const getAllMerchants = async () => {
  return (await supabase
    .from(TABLE.MERCHANTS)
    .select('id,name')
    .range(0, 10)
  ) as PostgrestSingleResponse<Merchants[]>;
};

const getAllPromoTags = async () => {
  return (await supabase
    .from(TABLE.PROMO_TAGGING)
    .select('id,name')
    .range(0, 10)
  ) as PostgrestSingleResponse<PromoTags[]>;
};


export const upsertPromos = async (payload: PayloadPromo) => {
  return (await supabase
    .from(TABLE.PROMOS)
    .upsert([payload])
    .select()
    .single()) as PostgrestSingleResponse<Promos>;
};

export const insertPromos = async (payload: PayloadPromo) => {
  return (await supabase
    .from(TABLE.PROMOS)
    .insert(payload)
    .select('*')
    .single()
  ) as PostgrestSingleResponse<Promos>;
};

export const invokeFunction = async (payload: PayloadInvoke) => {
  return (await supabase
    .functions.invoke('promo-engine', ({
      body: {
        'action': 'add',
        'promo': payload
      }
    }))
  );
};

export const upsertPromoAppliedPromo = async (payload: PromoAppliedPromoTags) => {
  return (await supabase
    .from(TABLE.PROMO_APPLIED_PROMO_TAGS)
    .upsert(payload)
  );
};

export const upsertPromoAppliedProduct = async (payload: PromoAppliedProducts) => {
  return (await supabase
    .from(TABLE.PROMO_APPLIED_PRODUCT)
    .upsert(payload)
  );
};

export const upsertPromoAppliedMerchants = async (payload: PromoAppliedMerchants) => {
  return (await supabase
    .from(TABLE.PROMO_APPLIED_MERCHANTS)
    .upsert(payload)
  );
};

export const useAllMerchants = () =>
  useQuery({
    queryKey: ['merchants'],
    queryFn: getAllMerchants,
    refetchOnWindowFocus: false
  });

export const useAllPromoTags = () =>
  useQuery({
    queryKey: ['promo-tags'],
    queryFn: getAllPromoTags,
    refetchOnWindowFocus: false
  });

export const useAllProduct = () =>
  useQuery({
    queryKey: ['variants'],
    queryFn: getAllProduct,
    refetchOnWindowFocus: false
  });

export const useAllProductAppliedIds = (ids?: string[]) =>
  useQuery({
    queryKey: ['variants-applied'],
    queryFn: () => getAllProductAppliedByIds(ids),
    enabled: !!ids,
    refetchOnWindowFocus: false
  });

export const useAllProductExcludedIds = (ids?: string[]) =>
  useQuery({
    queryKey: ['variants-excluded'],
    queryFn: () => getAllProductExcludedByIds(ids),
    enabled: !!ids,
    refetchOnWindowFocus: false
  });

export const useVoucherById = (id: string) =>
  useQuery({
    queryKey: ['voucher-id', id],
    queryFn: () => getVoucherById(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useListPaymentProviders = () =>
  useQuery({
    queryKey: ['payment-providers'],
    queryFn: getAllPaymentProviders,
    refetchOnWindowFocus: false
  });

export const useGetProductByProductVariantId = (ids: string[]) =>
  useQuery({
    queryKey: ['get-product-by-variant-id', ids],
    queryFn: () => getProductByVariantId(ids),
    enabled: !!(ids.length > 0),
    refetchOnWindowFocus: false
  });

export const useGetProductByVariants = () =>
  useQuery({
    queryKey: ['productByVariant'],
    queryFn: getProductsByVariantsId,
    refetchOnWindowFocus: false
  });
