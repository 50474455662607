import { supabase } from '@/lib/supabase';
import { IHistory, insertImportHistory } from '@/services/rest/importHistory';
import {
  getAlVariants,
  insertPromos,
  invokeFunction,
  upsertPromoAppliedMerchants,
  upsertPromoAppliedProduct,
  upsertPromoAppliedPromo,
  upsertPromos,
  useListPaymentProviders,
  useVoucherById
} from '@/services/rest/voucher';
import { useAuthStore } from '@/store/useAuthStore';
import { useFormik } from 'formik';
import { concat, debounce, uniq, uniqBy } from 'lodash';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { FileUpload, FileUploadFilesEvent } from 'primereact/fileupload';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { SchemaEditVoucher } from './validation';
import { TABLE } from '@/constants';
import { createPromoAuditTrail, Promos } from '@/services/rest/promo';
import { IPagination } from '@/types/pagination';
import { useDebounce } from 'primereact/hooks';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { promoAuditTrails } from '@/utils/auditTrails';

import type { RadioButtonChangeEvent } from 'primereact/radiobutton';
import type { CalendarChangeEvent } from 'primereact/calendar';

export type Form = {
 id?:string
 name: string;
 platforms: string[];
 customers: string[];
 cooperation: string;
 merchants: string[];
 borned_by_seller: number;
 start_date: Date;
 end_date: Date;
 status: boolean;
 use_generator?: boolean;
 no_minimal?: boolean;
 description: string;
 code: string;
 quota: number;
 user_quota: number;
 is_user_quota_unlimited: boolean;
 min_payment_amount: number;
 max_discount_amount: number;
 amount_type: string;
 discount_amount: number;
 discount_percentage: number;
 payment_method: {
  id: number | string;
  label: string;
  code: string;
  };
 discount_by: string;
 bin_code: [];
 voucher_type: string;
 applied_to: string[];
 labels?: string;
 applied_promo_tags: [];
 accepted_payment_provider_ids: Array<PaymentProviders>;
 isAllPlatform?: boolean;
 isRegisteredCustomer?: boolean;
  sourceAM: boolean;
  sourceRPlus: boolean;
  promoType: string;
  cooperation_value: string;
};

type PaymentProviders = {
 id: number | string;
 code: string;
 label: string;
};

type ITemplate = {
  code: string;
}

interface IPaginationState {
  currentPage:number,
  range:IPagination
}

export interface ProductVariant {
 id: string;
 products: [{ name: string }];
}

export interface Merchants {
 id: string;
 name: string;
}

export interface PromoTags {
 id: number;
 name: string;
}

interface IMerchantExclude {
  id: string,
  merchant_ids: string
}

interface IPromoTagsExclude {
  id: string,
  product_variant_promo_tag_ids: string | number
}

interface IProductExclude {
  id: string,
  product_variant_ids: string
}

interface IProductVariants {
  id: string
}

interface IOptionsProduct {
  id: string,
  name: string,
  product_variants: IProductVariants[]
}

const useEditVoucher = () => {
  const initialForm: Form = {
    name: '',
    platforms: [],
    customers: [],
    cooperation: '',
    merchants: [],
    borned_by_seller: 0,
    start_date: new Date(),
    end_date: new Date(),
    status: false,
    description: '',
    code: '',
    quota: 0,
    user_quota: 0,
    is_user_quota_unlimited: false,
    min_payment_amount: 0,
    max_discount_amount: 0,
    amount_type: '',
    discount_amount: 0,
    discount_percentage: 0,
    payment_method: {
      id: 0,
      label: '',
      code: ''
    },
    discount_by: '',
    bin_code: [],
    voucher_type: '',
    applied_to: [],
    use_generator: false,
    no_minimal: false,
    applied_promo_tags: [],
    accepted_payment_provider_ids: [],
    isAllPlatform: false,
    isRegisteredCustomer: false,
    sourceAM: false,
    sourceRPlus: false,
    promoType: '',
    cooperation_value: ''
  };
  const toast = useRef<Toast>(null);
  const params = useParams();
  const navigate = useNavigate();
  const [voucherListData, setVoucherListData] = useState<Promos[]>([]);
  const [voucherListCount, setVoucherListCount] = useState<number>(0);
  const [foundProducts, setFoundProducts] = useState<IOptionsProduct[]>([]);
  const [foundMerchants, setFoundMerchants] = useState<{ id: string, name: string }[]>([]);
  const [foundPromoTags, setFoundPromoTags] = useState<{ id: string, name: string }[]>([]);
  const [formValue, setFormValue] = useState<Form>(initialForm);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [codeUpload, setCodeUpload] = useState<ITemplate[]>([]);
  const collapsible = {
    quota: true,
    condition: true,
    codes: true
  };
  const [collapse, setCollapse] = useState(collapsible);
  const [counter, setCounter] = useState<number>(1);
  const [fileName, setFileName] = useState<string>('');
  const user = useAuthStore((state)=>state.user);
  const fileUploadRef = useRef<FileUpload>(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const initialValueFormik = {
    name: '',
    platforms: [''],
    customers: [''],
    cooperation: '',
    cooperation_value: '',
    start_date: '',
    end_date: '',
    description: '',
    voucher_type: '',
    code: '',
    quota: 0,
    user_quota: 0,
    min_payment_amount: 0,
    amount_type: '',
    discount_amount: 0,
    discount_percentage: 0,
    max_discount_amount: 0,
    payment_method: '',
    bin_code: [],
    isSourceChecked: false,
    promoType: ''
  };
  const [customerType, setCustomerType] = useState<'targeted' | 'registered' | null>(null);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [isNewBuyer, setIsNewBuyer] = useState(false);
  const [registrationPeriod, setRegistrationPeriod] = useState<string[]>(['', '']);

  useEffect(() => {
    formik.setValues((prevState) => (
      {
        ...prevState,
        isSourceChecked: formValue.sourceAM || formValue.sourceRPlus
      }
    ));
  }, [formValue.sourceAM, formValue.sourceRPlus]);

  const [addMoreException, setAddMoreException] = useState<{
    exclude_type: string,
    exclude: string[],
    excludeProductVariantIds: string[]
  }[]>([]);

  const [merchantExclude, setMerchantExclude] = useState<IMerchantExclude[]>([]);

  const [productVariantExclude, setProductVariantExclude] = useState<IProductExclude[]>([]);

  const [promoTagsExclude, setPromoTagsExclude] = useState<IPromoTagsExclude[]>([]);

  const [matchProduct, setMatchProduct] = useState<IOptionsProduct[]>([]);

  const optionNominal = [
    { name: 'Fixed Amount', code: 'fixed' },
    { name: 'Percentage', code: 'percentage' }
  ];

  const optionVoucherType = [
    { name: 'Auto', code: 'auto' },
    { name: 'Specific', code: 'specific' }
  ];

  const selectionDiscountOption = [
    { name: 'All Product in Checkout', code: 'ALL' },
    { name: 'Selected Product', code: 'PRODUCT' },
    { name: 'Selected Merchant', code: 'STORE' },
    { name: 'Selected Promo Tags', code: 'PROMO' }
  ];

  const excludedDiscountOption = [
    { name: 'Selected Product', code: 'PRODUCT' },
    { name: 'Selected Merchant', code: 'STORE' },
    { name: 'Selected Promo Tags', code: 'PROMO' }
  ];

  const formik = useFormik({
    initialValues: initialValueFormik,
    validationSchema: SchemaEditVoucher,
    onSubmit: (e) => {
      if (params.detailId) {
        return navigate(`/voucher/edit/${params.detailId}`);
      }
      onEditVoucher();
    }
  });

  const findMerchantsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.merchants').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const concatData = uniqBy([...foundMerchants || [], ...data], 'id');
      setFoundMerchants(concatData);
    }
  }, 250);

  const findMerchants = async (findName: string | null) => {
    findMerchantsDebounce(findName);
  };

  const findProductsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.products').select('id, name, product_variants!inner(id)')
      .ilike('name', `*${findName}*`).eq('status', 'APPROVED').eq('is_active', true).limit(10);

    if (data) {
      const concatData = uniqBy([...foundProducts || [], ...data], 'id');
      setFoundProducts(concatData as []);
    }
  }, 250);

  const findProducts = async (findName: string | null) => {
    findProductsDebounce(findName);
  };

  const findPromoTagsDebounce = debounce(async (findName: string | null) => {
    if (!findName) {
      return;
    }

    const { data } = await supabase.from('catalogue.promo_tags').select('id, name').ilike('name', `*${findName}*`).limit(10);

    if (data) {
      const concatData = uniqBy([...foundPromoTags || [], ...data], 'id');
      setFoundPromoTags(concatData);
    }
  }, 250);

  const findPromoTags = async (findName: string | null) => {
    findPromoTagsDebounce(findName);
  };

  const { data: dataVoucher, isLoading } = useVoucherById(String(params.id || params.detailId));

  const selectedDataVoucher = useMemo(() => {
    const pvIds = uniq(concat(
      dataVoucher?.data?.promo_applied_product_variants.flatMap((it) => it.product_variant_ids) || [],
      dataVoucher?.data?.promo_excluded_product_variants.flatMap((it) => it.product_variant_ids) || []
    ));

    if (pvIds.length > 0) {
      supabase
        .from('catalogue.product_variants')
        .select('id, product_id, products(id, name)')
        .in(
          'id',
          pvIds
        )
        .limit(pvIds.length)
        .then(({ data }) => {
          const products = uniqBy(data?.flatMap((p) => p.products as Array<{ id: number, name: string }>), 'id');
          setFoundProducts(products?.map((it) => ({
            id: `${it.id}`,
            name: it.name as string,
            product_variants: data?.filter((pv) => pv.product_id === it.id).map((pv) => ({ id: pv.id })) as { id: string }[]
          })) || []);
          setMatchProduct(products?.map((it) => ({
            id: `${it.id}`,
            name: it.name as string,
            product_variants: data?.filter((pv) => pv.product_id === it.id).map((pv) => ({ id: pv.id })) as { id: string }[]
          })) || []);
        });
    }

    const merchants = uniq(concat(
      dataVoucher?.data?.promo_applied_merchant_variants.flatMap((it) => it.merchant_ids) || [],
      dataVoucher?.data?.promo_excluded_merchant_variants.flatMap((it) => it.merchant_ids) || [],
      dataVoucher?.data?.merchant_cooperation || []
    ));

    if (merchants.length > 0) {
      supabase
        .from('catalogue.merchants')
        .select('id, name')
        .in(
          'id',
          merchants
        )
        .limit(merchants.length)
        .then(({ data }) => {
          setFoundMerchants(data || []);
        });
    }

    const tags = uniq(concat(
      dataVoucher?.data?.promo_applied_product_variant_promo_tags.flatMap((it) => it.product_variant_promo_tag_ids) || [],
      dataVoucher?.data?.promo_excluded_product_variant_promo_tags.flatMap((it) => it.product_variant_promo_tag_ids) || []
    ));

    if (tags.length > 0) {
      supabase
        .from('catalogue.promo_tags')
        .select('id, name')
        .in(
          'id',
          tags
        )
        .limit(tags.length)
        .then(({ data }) => {
          setFoundPromoTags(data || []);
        });
    }

    return dataVoucher?.data && dataVoucher.data;
  }, [dataVoucher]);

  const dataProductsApplied = useMemo(() => {
    const appliedVariant = selectedDataVoucher?.promo_applied_product_variants.flatMap((pv) => pv.product_variant_ids) || [];
    return matchProduct.filter((p) => appliedVariant.find((pv) => Array.isArray(p.product_variants) && p.product_variants.find((_p) => _p.id === pv) !== undefined) !== undefined);
  }, [selectedDataVoucher, matchProduct]);

  const dataProductsExcluded = useMemo(() => {
    const excludeVariant = selectedDataVoucher?.promo_excluded_product_variants.flatMap((pv) => pv.product_variant_ids) || [];
    return matchProduct.filter((p) => excludeVariant.find((pv) => Array.isArray(p.product_variants) && p.product_variants.find((_p) => _p.id === pv) !== undefined) !== undefined);
  }, [selectedDataVoucher, matchProduct]);

  const showError = (msg: string) => {
    setIsSubmitting(false);
    toast.current?.show({
      severity: 'error',
      summary: 'Error',
      detail: `${msg}`,
      life: 2000
    });
  };
  const showSuccess = (msg:string, isBulk?:boolean) => {
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 2000
    });
    navigate('/voucher');
  };

  const minRegistrationPeriod = useMemo(() => {
    if (registrationPeriod[0] && registrationPeriod[0] !== '') {
      return new Date(registrationPeriod[0]);
    }
    return undefined;
  }, [registrationPeriod]);

  const maxRegistrationPeriod = useMemo(() => {
    if (registrationPeriod[1] && registrationPeriod[1] !== '') {
      return new Date(registrationPeriod[1]);
    }
    return undefined;
  }, [registrationPeriod]);

  const onChangeCustomerType = useCallback((e: RadioButtonChangeEvent) => {
    setCustomerType(e.value);
  }, []);

  const onCheckNewBuyer = useCallback(
    (e: CheckboxChangeEvent) => setIsNewBuyer(e.checked as boolean),
    [isNewBuyer]
  );
  const onCheckNewCustomer = useCallback(
    (e: CheckboxChangeEvent) => setIsNewCustomer(e.checked as boolean),
    [isNewCustomer]
  );

  const updateRegistrationPeriod = useCallback((value: string, index: number) => {
    setRegistrationPeriod((period) => {
      const newValue = [...period];
      newValue[index] = value;
      return newValue;
    });
  }, []);

  const onChangeRegistrtionPeriodStart = useCallback((e: CalendarChangeEvent) => {
    updateRegistrationPeriod(e.value as string, 0);
  }, []);

  const onChangeRegistrtionPeriodEnd = useCallback((e: CalendarChangeEvent) => {
    updateRegistrationPeriod(e.value as string, 1);
  }, []);

  const onChangeFormValue = (key: string, value: string | Date | boolean | number | string[]) => {
    formik.setValues((prev) => {
      return {
        ...prev,
        [key]: value
      };
    });
    setFormValue((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };
  const onCollapse = (key: string, value: boolean) => {
    setCollapse((prevState)=> {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const handleOnChangeDropdown = useCallback(
    (key: string) => (event: DropdownChangeEvent) => {
      formik.setValues((prev) => ({
        ...prev,
        [key]: event.value
      }));
      setFormValue((prev) => ({
        ...prev,
        [key]: event.value
      }));
      if (key === 'payment_method') {
        setFormValue((prev) => ({
          ...prev,
          payment_method: {
            id: event.value?.id,
            code: event.value?.code,
            label: event.value?.label
          }
        }));
        formik.setValues((prev) => ({
          ...prev,
          payment_method: event.value?.code
        }));
      }
      if (key === 'discount_by') {
        setFormValue((prev)=>({
          ...prev,
          applied_to: []
        }));
      }
      if (key==='exclude_type') {
        setFormValue((prev)=>({
          ...prev,
          exclude: []
        }));
      }
    },
    []
  );


  const handleOnChangeCheckbox = useCallback(
    (key: string) => (event: CheckboxChangeEvent) => {
      if (key === 'use_generator') {
        formik.setValues((prev) => ({
          ...prev,
          ['code']: '',
          ['quota']: 1
        }));
        setFormValue((prev) => ({
          ...prev,
          ['code']: '',
          ['quota']: 1
        }));
      }
      formik.setValues((prev) => ({
        ...prev,
        [key]: event.checked
      }));
      setFormValue((prev) => ({
        ...prev,
        [key]: event.checked
      }));
    },
    []
  );

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const urlPath = window.location.href;
  const baseUrl = urlPath.split('/').slice(0, 5).join('/');

  const handleClickDownload = useCallback(()=>{
    const link = document.createElement('a');
    link.href = `${baseUrl}/voucherCode.xlsx`;
    link.target = '_blank';
    link.download = '';
    link.click();
  }, [baseUrl]);

  const handleSelectFile = async (e: FileUploadFilesEvent) => {
    if (e.files) {
      const text = await e.files[0].arrayBuffer();
      const wb = XLSX.read(text, { type: 'binary' });
      const sn = wb.SheetNames[0];
      const ws = wb.Sheets[sn];
      const json = XLSX.utils.sheet_to_json<ITemplate>(ws);
      const option = { header: 1 };
      const sheet2 = XLSX.utils.sheet_to_json(ws, option);
      const header = sheet2.shift();
      const isHeaderValid = JSON.stringify(header) === JSON.stringify(['Coupon Code']);
      if (!isHeaderValid) {
        showError('File is not Same With Template, Please Check your file');
        fileUploadRef.current?.clear();
        return;
      }
      if (json.length > 1) {
        json.shift();
        setFileName(e.files[0].name);
        setCodeUpload(json as ITemplate[]);
      } else {
        showError('File Can not be Empty');
      }
    }
  };

  const onEditVoucher = useCallback(async () => {
    setIsSubmitting(true);
    if (
      !formValue.name ||
      !formValue.quota ||
      !formValue.amount_type ||
      !formValue.start_date ||
      !formValue.end_date
    ) {
      setIsSubmitting(false);
      showError('Please complete the form!');
      return;
    }

    const PromoOld = await promoAuditTrails(formValue.name, '');

    const merchantExludeIds = merchantExclude.map((it) => String(it.id));
    const promoTagsExcludeIds = promoTagsExclude.map((it) => String(it.id));
    const productVariantsExcludeIds = productVariantExclude.map((it) => String(it.id));

    // delete product exclude
    if (productVariantsExcludeIds.length > 0) {
      const { data } = await supabase.rpc('trade.delete_action', {
        'table_name': 'promo_excluded_product_variants', 'column_name': 'id', 'val': productVariantsExcludeIds
      });
      if (!data[0].success) {
        setIsSubmitting(false);
        showError('Failed Edit Voucher');
        return;
      }
    }

    // delete merchant exclude
    if (merchantExludeIds.length > 0) {
      const { data } = await supabase.rpc('trade.delete_action', {
        'table_name': 'promo_excluded_merchant_variants', 'column_name': 'id', 'val': merchantExludeIds
      });
      if (!data[0].success) {
        setIsSubmitting(false);
        showError('Failed Edit Voucher');
        return;
      }
    }
    // delete promo tag exclude
    if (promoTagsExcludeIds.length > 0) {
      const { data } = await supabase.rpc('trade.delete_action', {
        'table_name': 'promo_excluded_product_variant_promo_tags', 'column_name': 'id', 'val': promoTagsExcludeIds
      });
      if (!data[0].success) {
        setIsSubmitting(false);
        showError('Failed Edit Voucher');
        return;
      }
    }
    // delete merchant applied
    if (dataVoucher?.data?.promo_applied_merchant_variants && dataVoucher?.data?.promo_applied_merchant_variants.length > 0) {
      const merchantIds = dataVoucher?.data?.promo_applied_merchant_variants.map((i)=> String(i.promo_id));
      const { data } = await supabase.rpc('trade.delete_action', {
        'table_name': TABLE.PROMO_APPLIED_MERCHANTS.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': merchantIds
      });
      if (!data[0].success) {
        setIsSubmitting(false);
        showError('Failed Edit Voucher');
        return;
      }
    }

    // delete product applied
    if (dataVoucher?.data?.promo_applied_product_variants && dataVoucher?.data?.promo_applied_product_variants.length > 0) {
      const productIds = dataVoucher?.data?.promo_applied_product_variants.map((i)=> String(i.promo_id));
      const { data } = await supabase.rpc('trade.delete_action', {
        'table_name': TABLE.PROMO_APPLIED_PRODUCT.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': productIds
      });
      if (!data[0].success) {
        setIsSubmitting(false);
        showError('Failed Edit Voucher');
        return;
      }
    }

    // delete promo tag applied
    if (dataVoucher?.data?.promo_applied_product_variant_promo_tags && dataVoucher?.data?.promo_applied_product_variant_promo_tags.length > 0) {
      const promoTagsIds = dataVoucher?.data?.promo_applied_product_variant_promo_tags.map((i)=> String(i.promo_id));
      const { data } = await supabase.rpc('trade.delete_action', {
        'table_name': TABLE.PROMO_APPLIED_MERCHANTS.split('.').splice(1, 1)[0], 'column_name': 'promo_id', 'val': promoTagsIds
      });
      if (!data[0].success) {
        setIsSubmitting(false);
        showError('Failed Edit Voucher');
        return;
      }
    }

    if (formValue.voucher_type) {
      if (formValue.voucher_type['code'] == 'specific') {
        if (!formValue.code) {
          setIsSubmitting(false);
          showError('Please input voucher code');
          return;
        }
      }
    } else {
      setIsSubmitting(false);
      showError('Please select one voucher type');
      return;
    }

    // exclude promo ids
    const excludeMerchantIds = addMoreException.find((it) => it.exclude_type == 'STORE')?.exclude || [];

    const excludePromoTagsIds = addMoreException.find((it) => it.exclude_type == 'PROMO')?.exclude || [];

    const excludeProductIds = addMoreException.find((it) => it.exclude_type == 'PRODUCT')?.excludeProductVariantIds || [];

    const excludePromoTagsIdsString = excludePromoTagsIds?.map(String);


    //TODO Add code insert

    const { data: variants } = await getAlVariants(formValue.applied_to);

    const variantIds = variants?.map((v)=>v.product_variants.map((pv)=>pv.id)).reduce((prev, current)=>prev.concat(current), []);

    // update without code generator
    if (codeUpload.length <= 0) {
      const payloadUpsert = {
        id: String(params.id),
        type: 'product',
        name: formValue.name,
        start_date: formValue.start_date.toISOString(),
        end_date: formValue.end_date.toISOString(),
        description: formValue.description,
        code: formValue.code,
        cooperation_with: formValue.cooperation,
        cooperation_value: formValue.cooperation_value,
        is_quota_unlimited: formValue.quota === 0,
        is_user_quota_unlimited: formValue.is_user_quota_unlimited,
        quota: formValue.quota,
        user_quota: formValue.user_quota,
        status: formValue.status,
        amount: formValue.amount_type == 'fixed' ? formValue.discount_amount : formValue.discount_percentage,
        amount_type: formValue.amount_type,
        min_payment_amount: formValue.min_payment_amount,
        max_payment_amount: formValue.min_payment_amount * 2,
        max_discount_amount: formValue.amount_type === 'fixed' ? formValue.discount_amount : formValue.max_discount_amount,
        min_discount_amount: formValue.amount_type === 'fixed' ?
          formValue.discount_amount :
          formValue.min_payment_amount * (formValue.discount_percentage / 100),
        accepted_payment_provider_ids: formValue.payment_method.code == 'all-payment-method' ? null : [formValue.payment_method],
        payment_bin_rule: formValue.bin_code,
        is_payment_cc: formValue.payment_method.code === 'credit_card',
        target_platform: formValue.isAllPlatform ? ['web', 'ios', 'android'] : null,
        is_public: !formValue.isRegisteredCustomer,
        merchant_cooperation: formValue.cooperation == 'OS' ? formValue.merchants : [],
        borne_by_seller: formValue.cooperation == 'OS' || formValue.cooperation == 'PS' ? formValue.borned_by_seller : 0,
        is_code_generator: formValue.use_generator,
        source: formValue.sourceAM && formValue.sourceRPlus ? 'ALL' : formValue.sourceAM ? 'AM' : formValue.sourceRPlus ? 'R+' : '',
        promo_type: formValue.promoType
      };
      const { data: dataUpsert, error: errorUpsert } = await upsertPromos(payloadUpsert);
      if (dataUpsert) {
        const { error: errorRule } = await invokeFunction({
          'id': dataUpsert.id,
          'code': dataUpsert.code,
          'cooperation_with': dataUpsert.cooperation as string,
          'cooperation_value': dataUpsert.cooperation_value,
          'description': dataUpsert.description,
          'image': dataUpsert.image,
          'amount_type': dataUpsert.amount_type,
          'amount': dataUpsert.amount,
          'start_date': dataUpsert.start_date,
          'end_date': dataUpsert.end_date,
          'status': dataUpsert.status,
          'type': 'product',
          'accepted_payment_provider_ids': dataUpsert.accepted_payment_provider_ids,
          'payment_bin_rule': dataUpsert.payment_bin_rule,
          'is_payment_cc': dataUpsert.is_payment_cc,
          'quota': dataUpsert.quota,
          'user_quota': dataUpsert.user_quota,
          'is_quota_unlimited': dataUpsert.is_quota_unlimited,
          'is_user_quota_unlimited': dataUpsert.is_user_quota_unlimited,
          'min_discount_amount': dataUpsert.min_discount_amount,
          'max_discount_amount': dataUpsert.max_discount_amount,
          'min_payment_amount': dataUpsert.min_payment_amount,
          'max_payment_amount': dataUpsert.max_payment_amount,
          'target_platform': dataUpsert.target_platform,
          'is_public': dataUpsert.is_public,
          'source': dataUpsert.source,
          'promo_type': dataUpsert.promo_type,
          'promo_applied_merchant_variants': [
            {
              'merchant_ids': formValue.discount_by === 'STORE' ? formValue.applied_to : []
            }
          ],
          'promo_applied_product_variants': [
            {
              'product_variant_ids': variantIds || []
            }
          ],
          'promo_applied_shipping_providers': [
            {
              'shipping_provider_ids': [
              ]
            }
          ],
          'promo_applied_product_variant_promo_tags': [
            {
              'product_variant_promo_tag_ids': formValue.discount_by === 'PROMO' ? formValue.applied_to : []
            }
          ],
          'promo_excluded_merchant_variants': [
            {
              'merchant_ids': excludeMerchantIds
            }
          ],
          'promo_excluded_product_variant_promo_tags': [
            {
              'product_variant_promo_tag_ids': excludePromoTagsIdsString
            }
          ],
          'promo_excluded_product_variants': [
            {
              'product_variant_ids': excludeProductIds
            }
          ]
        });
        if (errorRule) {
          showError(`Invalid Promo Rule Applied: ${errorRule.message}`);
          return;
        }

        if (excludeProductIds && excludeProductIds.length > 0) {
          const payload = excludeProductIds.map((it) => {
            return {
              promo_id: dataUpsert.id,
              product_variant_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_product_variants')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (excludeMerchantIds && excludeMerchantIds.length > 0) {
          const payload = excludeMerchantIds.map((it) => {
            return {
              promo_id: dataUpsert.id,
              merchant_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_merchant_variants')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (excludePromoTagsIds && excludePromoTagsIds.length > 0) {
          const payload = excludePromoTagsIds.map((it) => {
            return {
              promo_id: dataUpsert.id,
              product_variant_promo_tag_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_product_variant_promo_tags')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (formValue.discount_by === 'PROMO') {
          const appliedPromoTagId = dataVoucher?.data?.promo_applied_product_variant_promo_tags.find((i)=> i.promo_id === params.id)?.id;
          await upsertPromoAppliedPromo({
            id: appliedPromoTagId,
            promo_id: dataUpsert.id,
            product_variant_promo_tag_ids: formValue.applied_to
          });
        }
        if (formValue.discount_by === 'PRODUCT' && variantIds && variantIds.length > 0) {
          const appliedProductId = dataVoucher?.data?.promo_applied_product_variants.find((i)=> i.promo_id === params.id)?.id;
          await upsertPromoAppliedProduct({
            id: appliedProductId,
            promo_id: dataUpsert.id,
            product_variant_ids: variantIds
          });
        }
        if (formValue.discount_by === 'STORE') {
          const appliedMerchantId = dataVoucher?.data?.promo_applied_merchant_variants.find((i)=> i.promo_id === params.id)?.id;
          await upsertPromoAppliedMerchants({
            id: appliedMerchantId,
            promo_id: dataUpsert.id,
            merchant_ids: formValue.applied_to
          });
        }
      }
      if (errorUpsert) {
        showError(errorUpsert.message);
      }
    }

    // update and insert promo with code generator
    const errorImport: Array<string> = [];
    if (codeUpload.length > 0 && formValue.use_generator) {
      const payloadUpsert = {
        id: String(params.id),
        type: 'product',
        name: formValue.name,
        start_date: formValue.start_date.toISOString(),
        end_date: formValue.end_date.toISOString(),
        description: formValue.description,
        code: codeUpload[0]['Coupon Code'],
        cooperation_with: formValue.cooperation,
        cooperation_value: formValue.cooperation_value,
        is_quota_unlimited: formValue.quota === 0,
        is_user_quota_unlimited: formValue.is_user_quota_unlimited,
        quota: formValue.quota,
        user_quota: formValue.user_quota,
        status: formValue.status,
        amount: formValue.amount_type == 'fixed' ? formValue.discount_amount : formValue.discount_percentage,
        amount_type: formValue.amount_type,
        min_payment_amount: formValue.min_payment_amount,
        max_payment_amount: formValue.min_payment_amount * 2,
        max_discount_amount: formValue.amount_type === 'fixed' ? formValue.discount_amount : formValue.max_discount_amount,
        min_discount_amount: formValue.amount_type === 'fixed' ?
          formValue.discount_amount :
          formValue.min_payment_amount * (formValue.discount_percentage / 100),
        accepted_payment_provider_ids: formValue.payment_method.code == 'all-payment-method' ? null : [formValue.payment_method],
        payment_bin_rule: formValue.bin_code,
        is_payment_cc: formValue.payment_method.code === 'credit_card',
        target_platform: formValue.isAllPlatform ? ['web', 'ios', 'android'] : null,
        is_public: !formValue.isRegisteredCustomer,
        merchant_cooperation: formValue.cooperation == 'OS' ? formValue.merchants : [],
        borne_by_seller: formValue.cooperation == 'OS' || formValue.cooperation == 'PS' ? formValue.borned_by_seller : 0,
        is_code_generator: formValue.use_generator,
        source: formValue.sourceAM && formValue.sourceRPlus ? 'ALL' : formValue.sourceAM ? 'AM' : formValue.sourceRPlus ? 'R+' : '',
        promo_type: formValue.promoType
      };
      const { data: dataUpsert, error: errorUpsert } = await upsertPromos(payloadUpsert);
      if (errorUpsert) {
        errorImport.push('Baris ke-1: '+ errorUpsert.message);
      }
      const appliedPromoTag = formValue.discount_by === 'PROMO' ? formValue.applied_to.map((item)=> item.toString()) : [];
      if (dataUpsert) {
        const { error: invokeError } = await invokeFunction({
          'id': dataUpsert.id,
          'code': dataUpsert.code,
          'cooperation_with': dataUpsert.cooperation as string,
          'cooperation_value': dataUpsert.cooperation_value,
          'description': dataUpsert.description,
          'image': dataUpsert.image,
          'amount_type': dataUpsert.amount_type,
          'amount': dataUpsert.amount,
          'start_date': dataUpsert.start_date,
          'end_date': dataUpsert.end_date,
          'status': dataUpsert.status,
          'type': 'product',
          'accepted_payment_provider_ids': dataUpsert.accepted_payment_provider_ids,
          'payment_bin_rule': dataUpsert.payment_bin_rule,
          'is_payment_cc': dataUpsert.is_payment_cc,
          'quota': dataUpsert.quota,
          'user_quota': dataUpsert.user_quota,
          'is_quota_unlimited': dataUpsert.is_quota_unlimited,
          'is_user_quota_unlimited': dataUpsert.is_user_quota_unlimited,
          'min_discount_amount': dataUpsert.min_discount_amount,
          'max_discount_amount': dataUpsert.max_discount_amount,
          'min_payment_amount': dataUpsert.min_payment_amount,
          'max_payment_amount': dataUpsert.max_payment_amount,
          'target_platform': dataUpsert.target_platform,
          'is_public': dataUpsert.is_public,
          'source': dataUpsert.source,
          'promo_type': dataUpsert.promo_type,
          'promo_applied_merchant_variants': [
            {
              'merchant_ids': formValue.discount_by === 'STORE' ? formValue.applied_to : []
            }
          ],
          'promo_applied_product_variants': [
            {
              'product_variant_ids': variantIds || []
            }
          ],
          'promo_applied_shipping_providers': [
            {
              'shipping_provider_ids': [
              ]
            }
          ],
          'promo_applied_product_variant_promo_tags': [
            {
              'product_variant_promo_tag_ids': appliedPromoTag
            }
          ],
          'promo_excluded_merchant_variants': [
            {
              'merchant_ids': excludeMerchantIds
            }
          ],
          'promo_excluded_product_variant_promo_tags': [
            {
              'product_variant_promo_tag_ids': excludePromoTagsIdsString
            }
          ],
          'promo_excluded_product_variants': [
            {
              'product_variant_ids': excludeProductIds
            }
          ]
        });
        if (invokeError) {
          errorImport.push('Baris ke-1: '+ invokeError.message);
        }
        if (formValue.discount_by === 'PROMO') {
          const appliedPromoTagId = dataVoucher?.data?.promo_applied_product_variant_promo_tags.find((i)=> i.promo_id === params.id)?.id;
          const { error: errPromo } = await upsertPromoAppliedPromo({
            id: appliedPromoTagId,
            promo_id: dataUpsert.id,
            product_variant_promo_tag_ids: formValue.applied_to
          });
          if (errPromo) {
            errorImport.push('Baris ke-1: '+ errPromo.message);
          }
        }
        if (formValue.discount_by === 'PRODUCT') {
          const appliedProductId = dataVoucher?.data?.promo_applied_product_variants.find((i)=> i.promo_id === params.id)?.id;
          const { error: errPromo } = await upsertPromoAppliedProduct({
            id: appliedProductId,
            promo_id: dataUpsert.id,
            product_variant_ids: variantIds || []
          });
          if (errPromo) {
            errorImport.push('Baris ke-1: '+ errPromo.message);
          }
        }
        if (formValue.discount_by === 'STORE') {
          const appliedMerchantId = dataVoucher?.data?.promo_applied_merchant_variants.find((i)=> i.promo_id === params.id)?.id;
          const { error: errPromo } = await upsertPromoAppliedMerchants({
            id: appliedMerchantId,
            promo_id: dataUpsert.id,
            merchant_ids: formValue.applied_to
          });
          if (errPromo) {
            errorImport.push('Baris ke-1: '+ errPromo.message);
          }
        }
        if (excludeProductIds && excludeProductIds.length > 0) {
          const payload = excludeProductIds.map((it) => {
            return {
              promo_id: dataUpsert.id,
              product_variant_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_product_variants')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (excludeMerchantIds && excludeMerchantIds.length > 0) {
          const payload = excludeMerchantIds.map((it) => {
            return {
              promo_id: dataUpsert.id,
              merchant_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_merchant_variants')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (excludePromoTagsIds && excludePromoTagsIds.length > 0) {
          const payload = excludePromoTagsIds.map((it) => {
            return {
              promo_id: dataUpsert.id,
              product_variant_promo_tag_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_product_variant_promo_tags')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }
      }

      // insert new promo with code generator
      const payloadNewInsert = {
        type: 'product',
        name: formValue.name,
        start_date: formValue.start_date.toISOString(),
        end_date: formValue.end_date.toISOString(),
        description: formValue.description,
        code: formValue.code,
        cooperation_with: formValue.cooperation,
        cooperation_value: formValue.cooperation_value,
        is_quota_unlimited: formValue.quota === 0,
        is_user_quota_unlimited: formValue.is_user_quota_unlimited,
        quota: formValue.quota,
        user_quota: formValue.user_quota,
        status: formValue.status,
        amount: formValue.amount_type == 'fixed' ? formValue.discount_amount : formValue.discount_percentage,
        amount_type: formValue.amount_type,
        min_payment_amount: formValue.min_payment_amount,
        max_payment_amount: formValue.min_payment_amount * 2,
        max_discount_amount: formValue.amount_type === 'fixed' ? formValue.discount_amount : formValue.max_discount_amount,
        min_discount_amount: formValue.amount_type === 'fixed' ?
          formValue.discount_amount :
          formValue.min_payment_amount * (formValue.discount_percentage / 100),
        accepted_payment_provider_ids: formValue.payment_method.code == 'all-payment-method' ? null : [formValue.payment_method],
        payment_bin_rule: formValue.bin_code,
        is_payment_cc: formValue.payment_method.code === 'credit_card',
        target_platform: formValue.isAllPlatform ? ['web', 'ios', 'android'] : null,
        is_public: !formValue.isRegisteredCustomer,
        merchant_cooperation: formValue.cooperation === 'OS' ? formValue.merchants : [],
        borne_by_seller: formValue.cooperation === 'OS' ? formValue.borned_by_seller : 0,
        is_code_generator: formValue.use_generator,
        source: formValue.sourceAM && formValue.sourceRPlus ? 'ALL' : formValue.sourceAM ? 'AM' : formValue.sourceRPlus ? 'R+' : '',
        promo_type: formValue.promoType
      };
      const multipleCode = codeUpload.slice(1).map((item)=> {
        return {
          ...payloadNewInsert,
          code: item['Coupon Code']
        };
      });
      for (const [index, item] of multipleCode.entries()) {
        const { data: insertCode, error } = await insertPromos(item);
        if (error) {
          errorImport.push(`Baris ke-${index+3}: `+ error.message);
          continue;
        }
        const appliedPromoTag = formValue.discount_by === 'PROMO' ? formValue.applied_to.map((item)=> item.toString()) : [];
        const { error: invokeError } = await invokeFunction({
          'id': insertCode.id,
          'code': insertCode.code,
          'cooperation_with': insertCode.cooperation as string,
          'cooperation_value': insertCode.cooperation_value,
          'description': insertCode.description,
          'image': insertCode.image,
          'amount_type': insertCode.amount_type,
          'amount': insertCode.amount,
          'start_date': insertCode.start_date,
          'end_date': insertCode.end_date,
          'status': insertCode.status,
          'type': 'product',
          'accepted_payment_provider_ids': insertCode.accepted_payment_provider_ids,
          'payment_bin_rule': insertCode.payment_bin_rule,
          'is_payment_cc': insertCode.is_payment_cc,
          'quota': insertCode.quota,
          'user_quota': insertCode.user_quota,
          'is_quota_unlimited': insertCode.is_quota_unlimited,
          'is_user_quota_unlimited': insertCode.is_user_quota_unlimited,
          'min_discount_amount': insertCode.min_discount_amount,
          'max_discount_amount': insertCode.max_discount_amount,
          'min_payment_amount': insertCode.min_payment_amount,
          'max_payment_amount': insertCode.max_payment_amount,
          'target_platform': insertCode.target_platform,
          'is_public': insertCode.is_public,
          'source': insertCode.source,
          'promo_type': insertCode.promo_type,
          'promo_applied_merchant_variants': [
            {
              'merchant_ids': formValue.discount_by === 'STORE' ? formValue.applied_to : []
            }
          ],
          'promo_applied_product_variants': [
            {
              'product_variant_ids': variantIds || []
            }
          ],
          'promo_applied_shipping_providers': [
            {
              'shipping_provider_ids': [
              ]
            }
          ],
          'promo_applied_product_variant_promo_tags': [
            {
              'product_variant_promo_tag_ids': appliedPromoTag
            }
          ],
          'promo_excluded_merchant_variants': [
            {
              'merchant_ids': excludeMerchantIds
            }
          ],
          'promo_excluded_product_variant_promo_tags': [
            {
              'product_variant_promo_tag_ids': excludePromoTagsIdsString
            }
          ],
          'promo_excluded_product_variants': [
            {
              'product_variant_ids': excludeProductIds
            }
          ]
        });
        if (invokeError) {
          errorImport.push(`Baris ke-${index+3}: `+ invokeError.message);
        }
        if (formValue.discount_by === 'PROMO') {
          const { error: errPromo } = await upsertPromoAppliedPromo({
            promo_id: insertCode.id,
            product_variant_promo_tag_ids: formValue.applied_to
          });
          if (errPromo) {
            errorImport.push(`Baris ke-${index+3}: `+ errPromo.message);
          }
        }
        if (formValue.discount_by === 'PRODUCT') {
          const { error: errPromo } = await upsertPromoAppliedProduct({
            promo_id: insertCode.id,
            product_variant_ids: variantIds || []
          });
          if (errPromo) {
            errorImport.push(`Baris ke-${index+3}: `+ errPromo.message);
          }
        }
        if (formValue.discount_by === 'STORE') {
          const { error: errPromo } = await upsertPromoAppliedMerchants({
            promo_id: insertCode.id,
            merchant_ids: formValue.applied_to
          });
          if (errPromo) {
            errorImport.push(`Baris ke-${index+3}: `+ errPromo.message);
          }
        }
        if (excludeProductIds && excludeProductIds.length > 0) {
          const payload = excludeProductIds.map((it) => {
            return {
              promo_id: insertCode.id,
              product_variant_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_product_variants')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (excludeMerchantIds && excludeMerchantIds.length > 0) {
          const payload = excludeMerchantIds.map((it) => {
            return {
              promo_id: insertCode.id,
              merchant_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_merchant_variants')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }

        if (excludePromoTagsIds && excludePromoTagsIds.length > 0) {
          const payload = excludePromoTagsIds.map((it) => {
            return {
              promo_id: insertCode.id,
              product_variant_promo_tag_ids: it
            };
          });
          const { error: errExclude } = await supabase
            .from('trade.promo_excluded_product_variant_promo_tags')
            .insert(payload);

          if (errExclude) {
            showError('Failed insert to promo excluded product variants');
            return;
          }
        }
      }
      const payloadImportHistorySuccess = multipleCode.length - errorImport.length > 0 && {
        account_id: user?.id || '',
        file_path: fileName || '',
        row_number: multipleCode.length - errorImport.length,
        status: true,
        type: 'bulk_upload_voucher_code'
      };

      const payloadImportHistoryError = errorImport.length>0&&{
        account_id: user?.id || '',
        file_path: fileName || '',
        errors: errorImport,
        row_number: errorImport.length,
        status: false,
        type: 'bulk_upload_voucher_code'
      };

      const payloadImportHistory = [payloadImportHistorySuccess, payloadImportHistoryError].filter((item) => typeof item != 'boolean');
      await insertImportHistory(payloadImportHistory as IHistory[]);
      setIsShow(false);
      // eslint-disable-next-line new-cap
      const PromoNew = await promoAuditTrails(formValue.name, '');
      createPromoAuditTrail({
        promo_id: PromoNew?.id || '',
        activity: 'update',
        promo_type: PromoNew?.type || '',
        data_fields: JSON.stringify(PromoNew),
        old_fields: JSON.stringify(PromoOld)
      });

      return showSuccess('Code Voucher Uploaded', true);
    }
    // eslint-disable-next-line new-cap
    const PromoNew = await promoAuditTrails(formValue.name, '');
    createPromoAuditTrail({
      promo_id: PromoNew?.id || '',
      activity: 'update',
      promo_type: PromoNew?.type || '',
      data_fields: JSON.stringify(PromoNew),
      old_fields: JSON.stringify(PromoOld)
    });
    showSuccess('Voucher Edited Successfully');
    setIsSubmitting(false);
  }, [formValue, codeUpload, addMoreException, isShow, merchantExclude, promoTagsExclude, productVariantExclude, dataVoucher?.data]);

  const merchantOptions = useMemo(() => {
    return Array.isArray(foundMerchants) ? foundMerchants : [];
  }, [foundMerchants]);

  const promoTagsOptions = useMemo(() => {
    return Array.isArray(foundPromoTags) ? foundPromoTags : [];
  }, [foundPromoTags]);

  const variantOptions = useMemo(() => {
    return Array.isArray(foundProducts) ? foundProducts : [];
  }, [foundProducts]);

  const { data: dataPaymentProviders } = useListPaymentProviders();

  const listProviderPayment = useMemo(() => {
    const paymentProviderActive = dataPaymentProviders?.data?.data?.filter((it) => it.active);
    return (
      (Array.isArray(paymentProviderActive) &&
      paymentProviderActive.map((pp) => {
        return {
          label: pp.name,
          code: pp.slug,
          id: pp.id
        };
      })) || []
    );
  }, [dataPaymentProviders]);

  const optionsPayments = useMemo(() => {
    if (listProviderPayment) {
      const newOptions = [
        {
          label: 'Semua Metode Pembayaran',
          code: 'all-payment-method',
          id: 'all-payment'
        },
        ...listProviderPayment
      ];
      return newOptions;
    }
  }, [listProviderPayment]);

  useEffect( () => {
    if (selectedDataVoucher) {
      const isAllPlatform = selectedDataVoucher?.target_platform?.includes('web') && selectedDataVoucher?.target_platform?.includes('ios') && selectedDataVoucher?.target_platform?.includes('android');
      setFormValue({
        accepted_payment_provider_ids: selectedDataVoucher?.accepted_payment_provider_ids ? selectedDataVoucher?.accepted_payment_provider_ids : [],
        discount_amount: selectedDataVoucher?.amount_type == 'fixed' ? selectedDataVoucher?.amount : 0,
        discount_percentage: selectedDataVoucher?.amount_type == 'percentage' ? selectedDataVoucher?.amount : 0,
        amount_type: selectedDataVoucher?.amount_type,
        applied_to:
        (selectedDataVoucher?.promo_applied_merchant_variants.length > 0 &&
        selectedDataVoucher.promo_applied_merchant_variants.flatMap((m) => m.merchant_ids)) ||
        (selectedDataVoucher?.promo_applied_product_variants.length > 0 &&
        dataProductsApplied?.map((p)=>p.id)) ||
        (selectedDataVoucher?.promo_applied_product_variant_promo_tags.length > 0 &&
        selectedDataVoucher.promo_applied_product_variant_promo_tags.flatMap((pt) => pt.product_variant_promo_tag_ids)) ||
        [],
        applied_promo_tags: selectedDataVoucher?.applied_promo_tags,
        bin_code: (selectedDataVoucher?.payment_bin_rule as []) || [],
        borned_by_seller: selectedDataVoucher?.borne_by_seller || 0,
        code: selectedDataVoucher?.code || '',
        cooperation: selectedDataVoucher?.cooperation_with === 'OS' ? 'OS' : selectedDataVoucher?.cooperation_with === 'PS' ? 'PS' : 'AM',
        customers: ['RM'],
        description: selectedDataVoucher?.description,
        discount_by:
        (selectedDataVoucher?.promo_applied_merchant_variants.length > 0 && 'STORE') ||
        (selectedDataVoucher?.promo_applied_product_variant_promo_tags.length > 0 && 'PROMO') ||
        (selectedDataVoucher?.promo_applied_product_variants.length > 0 && 'PRODUCT') ||
        'ALL',
        end_date: new Date(selectedDataVoucher?.end_date),
        // exclude: (selectedDataVoucher?.promo_excluded_product_variants.length > 0 &&
        // dataProductsExcluded?.map((p)=>p.id)) ||
        // [],
        // exclude_type: selectedDataVoucher?.promo_excluded_product_variants.length>0 && 'PRODUCT' || '',
        is_user_quota_unlimited: selectedDataVoucher?.user_quota === 0,
        name: selectedDataVoucher?.name,
        platforms: ['WEB', 'IOS', 'AND'],
        max_discount_amount: selectedDataVoucher?.max_discount_amount,
        merchants: selectedDataVoucher?.merchant_cooperation,
        min_payment_amount: selectedDataVoucher?.min_payment_amount,
        payment_method: selectedDataVoucher?.accepted_payment_provider_ids ? selectedDataVoucher?.accepted_payment_provider_ids[0] :
          { label: 'Semua Metode Pembayaran',
            code: 'all-payment-method',
            id: 'all-payment'
          },
        quota: selectedDataVoucher.is_code_generator ? 1 : selectedDataVoucher?.quota,
        start_date: new Date(selectedDataVoucher?.start_date),
        status: selectedDataVoucher?.status,
        user_quota: selectedDataVoucher?.user_quota,
        voucher_type: selectedDataVoucher.code || selectedDataVoucher.is_code_generator ? 'specific' : 'auto',
        isRegisteredCustomer: !selectedDataVoucher.is_public,
        isAllPlatform: isAllPlatform,
        no_minimal: selectedDataVoucher.min_payment_amount === 0,
        use_generator: selectedDataVoucher.is_code_generator,
        cooperation_value: selectedDataVoucher.cooperation_value,
        promoType: selectedDataVoucher.promo_type,
        sourceAM: selectedDataVoucher.source === 'AM' || selectedDataVoucher.source === 'ALL',
        sourceRPlus: selectedDataVoucher.source === 'R+' || selectedDataVoucher.source === 'ALL'
      });
      formik.setValues((prev)=>({
        ...prev,
        name: selectedDataVoucher.name,
        cooperation: selectedDataVoucher.promo_applied_merchant_variants.length > 0 ? 'OS' : 'AM',
        start_date: selectedDataVoucher.start_date,
        end_date: selectedDataVoucher.end_date,
        description: selectedDataVoucher?.description,
        voucher_type: selectedDataVoucher.code ? 'specific' : 'auto',
        code: selectedDataVoucher?.code || '',
        quota: selectedDataVoucher.is_code_generator ? 1 : selectedDataVoucher.quota,
        user_quota: selectedDataVoucher.user_quota,
        min_payment_amount: selectedDataVoucher.min_payment_amount,
        amount: selectedDataVoucher?.amount,
        amount_type: selectedDataVoucher?.amount_type,
        max_discount_amount: selectedDataVoucher.max_discount_amount,
        customers: ['RM'],
        platforms: ['WEB', 'IOS', 'AND'],
        payment_method: selectedDataVoucher?.accepted_payment_provider_ids ? selectedDataVoucher?.accepted_payment_provider_ids[0]?.code : 'all-payment-method',
        bin_code: selectedDataVoucher?.payment_bin_rule as [],
        isSourceChecked: selectedDataVoucher.source === 'ALL' || selectedDataVoucher.source === 'AM' || selectedDataVoucher.source === 'R+',
        promoType: selectedDataVoucher.promo_type,
        cooperation_value: selectedDataVoucher.cooperation_value
      }));

      // set data merchant exclude to multiple select when detail and edit
      const exceptions: {
        exclude_type: string,
        exclude: string[],
        excludeProductVariantIds: string[]
      }[] = [];
      if (selectedDataVoucher.promo_excluded_merchant_variants.length >= 1) {
        exceptions.push({
          exclude_type: 'STORE',
          exclude: selectedDataVoucher.promo_excluded_merchant_variants.map((it) => it.merchant_ids) as [],
          excludeProductVariantIds: []
        });
      }

      // set data promo exlude to multiple select when detail and edit
      if (selectedDataVoucher.promo_excluded_product_variant_promo_tags && selectedDataVoucher.promo_excluded_product_variant_promo_tags.length >= 1) {
        exceptions.push({
          exclude_type: 'PROMO',
          exclude: selectedDataVoucher.promo_excluded_product_variant_promo_tags?.map((it) => it.product_variant_promo_tag_ids) as [],
          excludeProductVariantIds: []
        });
      }

      if (selectedDataVoucher.promo_excluded_product_variants && selectedDataVoucher.promo_excluded_product_variants.length >= 1) {
        exceptions.push({
          exclude_type: 'PRODUCT',
          exclude: dataProductsExcluded.map((p) => p.id),
          excludeProductVariantIds: dataProductsExcluded.flatMap((p) => {
            if (Array.isArray(p.product_variants)) {
              return p.product_variants.map((p) => p.id);
            }
            return '';
          }).filter((p) => p && p !== '')
        });
      }

      setAddMoreException(() => uniqBy(exceptions, 'exclude_type'));

      // set merchant exclude with id
      setMerchantExclude(selectedDataVoucher.promo_excluded_merchant_variants as []);
      // set promo tags exclude with id
      setPromoTagsExclude(selectedDataVoucher.promo_excluded_product_variant_promo_tags as []);
      // set produt exclude with id
      setProductVariantExclude(selectedDataVoucher.promo_excluded_product_variants as []);
    }
  }, [selectedDataVoucher, dataProductsApplied, dataProductsExcluded]);

  const handleAddException = () => {
    setAddMoreException((prev) => [
      ...prev,
      {
        exclude_type: '',
        exclude: [],
        excludeProductVariantIds: []
      }
    ]);
  };


  // handle delete rule exception
  const handleDeleteRuleExcepiton = useCallback((index: number) => {
    const tempExceptionArr = [...addMoreException];
    tempExceptionArr.splice(index, 1);

    setAddMoreException(tempExceptionArr);
  }, [addMoreException]);

  const handleInputChange = (index, event: MultiSelectChangeEvent, type = '') => {
    const values = [...addMoreException];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    if (updatedValue == 'exclude_type') {
      values[index]['exclude'] = [];
    }

    //to check when exclude type product adding product variant id
    if (type == 'PRODUCT' && event?.selectedOption?.product_variants) {
      const productVariantIds = variantOptions.filter((i) => event.value.includes(i.id)).map((v) => v.product_variants).flat().map((it) => it.id);
      values[index]['excludeProductVariantIds'] = productVariantIds;
    } else if (type == 'PRODUCT' && !event?.selectedOption?.product_variants) {
      // select all product variant
      const productVariantIds = variantOptions.map((i) => i.product_variants.map((v) => v.id)).flat();
      values[index]['excludeProductVariantIds'] = productVariantIds;
    }

    setAddMoreException(values);
  };

  // default user quota
  const defaultValueUsageVoucher = useCallback(() => {
    if (formValue.is_user_quota_unlimited) {
      onChangeFormValue('user_quota', 0);
    } else if (formValue.use_generator) {
      onChangeFormValue('quota', 1);
      onChangeFormValue('user_quota', 1);
    }
  }, [formValue]);

  useEffect(() => {
    defaultValueUsageVoucher();
  }, [formValue.is_user_quota_unlimited, formValue.use_generator]);

  const [isLoadingCoupon, setIsLoadingCoupon] = useState<boolean>(false);
  const getVoucherListData = async (search: string) => {
    setIsLoadingCoupon(true);
    let vouchers: Promos[] = [];
    let counts = 0;
    if (dataVoucher?.data?.is_code_generator) {
      const query = supabase
        .from('trade.promos')
        .select('id, code, name, created_at', { count: 'exact' })
        .not('code', 'is', null)
        .not('code', 'eq', '')
        .eq('name', dataVoucher?.data?.name)
        .eq('is_code_generator', true)
        .range(pagination.range.start, pagination.range.end);

      if (search) {
        query.ilike('code', `*${search}*`);
      }

      const { data, count, error } = await query;

      if (error) {
        setIsLoadingCoupon(false);
      }

      const { data: usedPromos } = await supabase
        .from('trade.promo_amount_counts')
        .select('id, promo_id')
        .is('released_at', null)
        .in('promo_id', data?.map((p) => p.id) || []);
      vouchers = data?.map((p) => ({
        ...p,
        is_used: usedPromos?.find((up) => up.promo_id == p.id) !== undefined
      })) as Promos[];
      counts = count || 0;
    }

    setVoucherListData(vouchers);
    setVoucherListCount(counts);
    setIsLoadingCoupon(false);
  };

  // Pagination
  const [search, debounceSearch, setSearch] = useDebounce('', 500);
  const [perPage, setPerPage] = useState<number>(10);
  const [pagination, setPagination] = useState<IPaginationState>({
    currentPage: 1,
    range: {
      start: 0,
      end: 9
    }
  });
  const [jumpToPage, setJumpToPage] = useState<number>(1);

  useEffect(() => {
    getVoucherListData(debounceSearch);
  }, [dataVoucher, pagination, debounceSearch]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    if (currentTarget.value.length === 0) {
      setSearch('');
      setPagination((prev) => ({
        ...prev,
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      }));
      return;
    }
    setSearch(currentTarget.value);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    }));
  }, []);

  const totalPages = useMemo(()=>{
    return Math.ceil(Number(voucherListCount) / perPage);
  }, [voucherListCount, perPage]);

  const handleClickPage = useCallback((page:number)=>()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
      range: {
        start: page === 1 ? page - 1 : perPage * page - perPage,
        end: page * perPage - 1
      }
    }));
  }, [perPage]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent)=>{
    setPerPage(event.value);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickNext = useCallback(()=>{
    pagination.currentPage <= totalPages &&
    setPagination((prev) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
      range: {
        start: pagination.range.start + perPage,
        end: pagination.range.end + perPage
      }
    }));
  }, [pagination, totalPages, perPage]);

  const handleClickPrev = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: prev.currentPage - 1,
      range: {
        start: prev.range.start - perPage,
        end: prev.range.end - perPage
      }
    }));
  }, [pagination, perPage]);

  const handleClickFirstPage = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    }));
  }, [pagination, perPage]);

  const handleClickLastPage = useCallback(()=>{
    setPagination((prev) => ({
      ...prev,
      currentPage: totalPages,
      range: {
        start: voucherListCount - perPage + (totalPages * perPage - voucherListCount),
        end: voucherListCount
      }
    }));
  }, [pagination, voucherListCount, perPage]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      let value = 1;
      if (jumpToPage > 1 ) {
        value = jumpToPage > totalPages ? totalPages : jumpToPage;
      }
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, voucherListCount - 1);

      setPagination(() => ({
        currentPage: value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, voucherListCount]);

  useEffect(() => {
    if (customerType !== 'targeted') {
      setIsNewBuyer(false);
      setIsNewCustomer(false);
    }
  }, [customerType]);

  useEffect(() => {
    if (!isNewCustomer || customerType !== 'targeted') {
      setRegistrationPeriod(['', '']);
    }
  }, [customerType, isNewCustomer]);

  return {
    data: {
      toast,
      formValue,
      optionNominal,
      optionVoucherType,
      isLoading,
      selectionDiscountOption,
      variantOptions,
      excludedDiscountOption,
      merchantOptions,
      promoTagsOptions,
      params,
      collapse,
      counter,
      formik,
      addMoreException,
      isSubmitting,
      fileUploadRef,
      codeUpload,
      isShow,
      optionsPayments,
      voucherListData,
      voucherListCount,
      totalPages,
      perPage,
      pagination,
      search,
      isLoadingCoupon,
      customerType,
      isNewBuyer,
      isNewCustomer,
      registrationPeriod,
      minRegistrationPeriod,
      maxRegistrationPeriod
    },
    method: {
      onChangeFormValue,
      onEditVoucher,
      navigate,
      handleOnChangeDropdown,
      handleOnChangeCheckbox,
      onCollapse,
      handleSelectFile,
      setCounter,
      isFormFieldValid,
      handleAddException,
      handleDeleteRuleExcepiton,
      handleInputChange,
      findMerchants,
      findProducts,
      findPromoTags,
      handleClickDownload,
      setIsShow,
      handleChangeDropdownPage,
      handleClickFirstPage,
      handleClickLastPage,
      handleClickNext,
      handleClickPage,
      handleClickPrev,
      handleSearch,
      handleChangeJumpTopage,
      handleJumpToPage,
      onChangeCustomerType,
      onCheckNewBuyer,
      onCheckNewCustomer,
      onChangeRegistrtionPeriodStart,
      onChangeRegistrtionPeriodEnd
    }
  };
};

export default useEditVoucher;
