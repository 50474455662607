import { IFilterBanner, useBanner, useSoftDeleteBanner, useSoftDeleteBrandBanner } from '@/services/rest/banner';
import { Toast } from 'primereact/toast';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IBannerOptions } from './CreateEditBanner/hooks';
import {
  DataTableExpandedRows,
  DataTableRowToggleEvent,
  DataTableSelection,
  DataTableSelectionChangeEvent,
  DataTableValueArray
} from 'primereact/datatable';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useHistoryStore } from '@/store/useHistoryStore';
import { IFilterHistoryItems } from '@/components/base/FilterHistory';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumberChangeEvent } from 'primereact/inputnumber';
import { Banner as Banners, IPayloadSoftDelete } from '@/services/rest/banner';
import { confirmDialog } from 'primereact/confirmdialog';
import dayjs from 'dayjs';
import { useDebounce } from 'primereact/hooks';

interface IOptions {
  label: string;
  items: Array<IItemsOption>;
}

interface IItemsOption {
  label: string;
  value: string | boolean;
  name: string;
}

const useCustom = () => {
  const initialFilter: IFilterBanner = {
    id_from: null,
    id_to: null,
    name: '',
    created_at: [],
    status: '',
    type: '',
    street: ''
  };

  const [visitedPage] = useHistoryStore((state) => [state.visitedPage]);
  const [setVisitedPage] = useHistoryStore((state) => [state.setVisitedPage]);
  const [lastFilterPage] = useHistoryStore((state) => [state.lastFilterPage]);
  const [setLastFilterPage] = useHistoryStore((state) => [state.setLastFilterPage]);
  const navigate: NavigateFunction = useNavigate();
  const refToastList = useRef<Toast>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [selectedBannerRow, setSelectedBannerRow] = useState<DataTableSelection<[]> | undefined>(undefined);
  const [expandedRow, setExpandedRow] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const handleToggleRow = useCallback((e: DataTableRowToggleEvent) => {
    setExpandedRow(e.data);
  }, []);
  const [itemFilters, setItemFilters] = useState(lastFilterPage.banner != '' ? JSON.parse(String(lastFilterPage.banner)) : initialFilter);
  const [filters, setFilters] = useState(lastFilterPage.banner != '' ? JSON.parse(String(lastFilterPage.banner)) : initialFilter);
  const [filterHistory, setFilterHistory] = useState<IFilterHistoryItems[]>([]);
  const [search, debounceSearch, setSearch] = useDebounce('', 1500);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const currentPage = parseInt(visitedPage.banner.toString()) ?? 1;
  const start = currentPage != 1 ? (10 * currentPage - 10) : 0;
  const end = currentPage != 1 ? (10 * currentPage) - 1 : perPage - 1;
  const [paginator, setPaginator] = useState({ currentPage, range: { start, end } });
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [jumpToPage, setJumpToPage] = useState<number>(1);

  const { data: dataBanner, isLoading: isLoadingBanner, refetch: refetchBanner } = useBanner(paginator.range, filters, debounceSearch);

  const totalRecords = useMemo(() => {
    return dataBanner?.count ? dataBanner.count : 0;
  }, [dataBanner]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRecords / perPage);
  }, [totalRecords, perPage]);

  const { mutate: softDeleteBannerById, isError: errorDelete, status: statusDeleteBanner } = useSoftDeleteBanner(refetchBanner);
  const { mutate: softDeleteBrandBanner, isError: errorDeleteBrand, status: statusDeleteBrandBanner } = useSoftDeleteBrandBanner(refetchBanner);

  const banner = useMemo(() => {
    if (!Array.isArray(dataBanner?.data)) return [];
    return dataBanner?.data.map((item)=>({
      ...item,
      status: item.status === 'ACTIVE' ? 'Enabled' : 'Disabled'
    }));
  }, [dataBanner]);

  const optionBannerStatus: IBannerOptions[] = [
    { label: 'Enabled', code: 'ACTIVE' },
    { label: 'Disabled', code: 'INACTIVE' }
  ];

  const renderLabelCategoryBanner = useCallback((type: string) => {
    switch (type) {
    case 'HEADER_BANNER':
      return 'Promo Header Banner';
    case 'MAIN_BANNER':
      return 'Main Banner';
    case 'ADS_BANNER':
      return 'Promo Ads Banner 1';
    case 'OPTIONAL_PRODUCT_BANNER':
      return 'Produk Pilihan';
    case 'BRAND_BANNER':
      return 'Brand Banner';
    case 'HOT_DEAL_BANNER':
      return 'Hot Deals';
    case 'PRODUCT_CATEGORY_BANNER':
      return 'Banner Produk Kategori';
    case 'PROMO_BANNER':
      return 'Promo Page';
    case 'FOOTER':
      return 'Footer';
    case 'STREET':
      return 'Street';
    case 'FLASH_SALE_BANNER':
      return 'Flash Banner';
    case 'OFFICIAL_STORE_BANNER':
      return 'Official Store Banner';
    case 'VIDEO_BANNER':
      return 'Section Video Background';
    case 'SECTION_FLASH_SALE_BACKGROUND':
      return 'Section Flash Sale Background';
    case 'BRAND_BANNER_RIGHT':
      return 'Brand Banner Right';
    case 'BRAND_BANNER_LEFT':
      return 'Brand Banner Left';
    case 'POPUP_BANNER':
      return 'Popup Banner';
    default:
      return '';
    }
  }, []);

  const optionBannerType: IBannerOptions[] = [
    { label: 'Promo Header Banner', code: 'HEADER_BANNER' },
    { label: 'Main Banner', code: 'MAIN_BANNER' },
    { label: 'Promo Ads Banner 1', code: 'ADS_BANNER' },
    { label: 'Produk Pilihan', code: 'OPTIONAL_PRODUCT_BANNER' },
    { label: 'Brand Banner', code: 'BRAND_BANNER' },
    { label: 'Hot Deals', code: 'HOT_DEAL_BANNER' },
    { label: 'Banner Produk Kategori', code: 'PRODUCT_CATEGORY_BANNER' },
    { label: 'Promo Page', code: 'PROMO_BANNER' },
    { label: 'Footer', code: 'FOOTER' },
    { label: 'Street', code: 'STREET' },
    { label: 'Flash Banner', code: 'FLASH_SALE_BANNER' },
    { label: 'Official Store Banner', code: 'OFFICIAL_STORE_BANNER' },
    { label: 'Section Video Background', code: 'VIDEO_BANNER' },
    { label: 'Section Flash Sale Background', code: 'SECTION_FLASH_SALE_BACKGROUND' }
    // DONT DELETE
    // TODO: Handle when another street available
    // { label: 'Promo Ads Banner 2', code: '' },
    // { label: 'Promo Highlight Banner', code: '' },
    // { label: 'Multiple Banner 1', code: '' },
    // { label: 'Multiple Banner 2', code: '' },
    // { label: 'Section Product Pilihan Background', code: '' },
    // { label: 'Section Product Billboard', code: '' },
    // { label: 'Section Product Billboard 2', code: '' }
  ];

  const headers = [
    { field: 'id', header: 'Banner ID' },
    { field: 'name', header: 'Banner Name' },
    { field: 'category', header: 'Banner Category' },
    { field: 'status', header: 'Status' },
    { field: 'desktopImage', header: 'Desktop Image' },
    { field: 'mobileImage', header: 'Mobile Image' },
    { field: 'thumbnailImage', header: 'Thumbnail Image' },
    { field: 'street', header: 'Street' }
  ];

  const optionListBannerFilter = useMemo(():IOptions[] => {
    return [
      {
        label: 'Type',
        items: optionBannerType.map((bt) => ({
          label: String(bt.label),
          name: 'type',
          value: String(bt.code)
        }))
      },
      {
        label: 'Status',
        items: optionBannerStatus.map((bs) => ({
          label: String(bs.label),
          name: 'status',
          value: String(bs.code)
        }))
      }
    ];
  }, [optionBannerType, optionBannerStatus]);

  const showSuccessToast = (isDeleted = false) => {
    refToastList.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: isDeleted ? 'Banner is deleted successfully' : 'New banner successfully created',
      life: 2000
    });
    const timeOut = setTimeout(() => {
      navigate('/frontstore-management/banner');
      return () => clearTimeout(timeOut);
    }, 2000);
  };

  const showFailedToast = (message?: string) => {
    refToastList.current?.show({
      severity: 'error',
      summary: 'Failed',
      detail: message || 'Failed upload image',
      life: 2000
    });
  };

  const handleFilter = useCallback((e, field: string) => {
    setItemFilters((prev) => ({
      ...prev,
      [field]: e.target ? e.target.value : e.value
    }));
  }, []);


  const handleDeleteFilterHistory = useCallback(
    (_: string, value: string[]) => {
      const items = value[0].split(',');
      items.forEach((i) => {
        setFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));

        setItemFilters((prev) => ({
          ...prev,
          [i]: initialFilter[i]
        }));
      });

      setFilterHistory((prev) => {
        return prev.filter((item) => item.items[0].label !== value[0]);
      });

      setSearch('');
    },
    []
  );

  const handleClearFilter = useCallback(() => {
    setLastFilterPage({
      ...lastFilterPage,
      banner: ''
    });
    setItemFilters(initialFilter);
    setFilters(initialFilter);
    setFilterHistory([]);
    setSearch('');
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [lastFilterPage, perPage]);

  const handleSearch = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement, Event>)=>{
    setSearch(currentTarget.value);
    const newKeyword = {
      name: 'Keyword',
      items: [{
        label: currentTarget.value,
        value: currentTarget.value,
        name: currentTarget.value
      }]
    };

    if (currentTarget.value != '') {
      setFilterHistory((prev: IFilterHistoryItems[]) => {
        const existingIndex = prev.findIndex((item) => item.name === 'Keyword');
        if (existingIndex !== -1) {
          prev[existingIndex] = newKeyword;
        } else {
          prev.push(newKeyword);
        }
        return [...prev];
      });

      setPaginator({
        currentPage: 1,
        range: {
          start: 0,
          end: perPage - 1
        }
      });
    } else {
      handleDeleteFilterHistory('Keyword', ['Keyword']);
    }
  }, [perPage]);

  const handleSelectionChange = useCallback((e: DataTableSelectionChangeEvent<[]>) => {
    setSelectedBannerRow(e.value);
  }, []);

  const allowExpansion = useCallback((rowData) => {
    return rowData.image != null ? rowData.image.length > 0 : false;
  }, []);

  const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedRecords(value);
  };

  const handleClickDelete = useCallback((rowData: Banners) => {
    if (Array.isArray(selectedRecords) && selectedRecords.length > 1) {
      const brandBanner = selectedRecords.filter((sr:Banners)=>sr.type === 'BRAND_BANNER');
      const banner = selectedRecords.filter((sr:Banners)=>sr.type !== 'BRAND_BANNER');
      const payloadBrandBanner:IPayloadSoftDelete[] = brandBanner.map((item:Banners)=>{
        return {
          id: Number(item?.id),
          identity: {
            categoryId: item.category_id,
            createdAt: item.created_at,
            merchantId: item.merchant_id,
            productId: item.product_id,
            promosId: item.promos_id,
            urlFull: item.url_full,
            urlPath: item.url_path
          }
        };
      });
      const payloadBanner = banner.map((item:Banners)=>Number(item?.id));
      confirmDialog({
        message: 'Are you sure you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept() {
          softDeleteBannerById(payloadBanner);
          payloadBrandBanner.forEach((item)=>softDeleteBrandBanner(item));
          if (errorDelete || errorDeleteBrand) {
            showFailedToast('Failed To Delete Banner');
            return;
          }
          showSuccessToast();
        }
      });
    } else {
      confirmDialog({
        message: 'Are you sure you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept() {
          if (rowData.type === 'BRAND_BANNER') {
            const payloadDeleteBrandBanner:IPayloadSoftDelete = {
              id: Number(rowData.id),
              identity: {
                categoryId: rowData.category_id,
                createdAt: rowData.created_at,
                merchantId: rowData.merchant_id,
                productId: rowData.product_id,
                promosId: rowData.promos_id,
                urlFull: rowData.url_full,
                urlPath: rowData.url_path
              }
            };
            softDeleteBrandBanner(payloadDeleteBrandBanner);
          }
          softDeleteBannerById([Number(rowData.id)]);
          showSuccessToast(true);
        }
      });
    }
  }, [selectedRecords, errorDelete, errorDeleteBrand]);

  const handleChangeJumpTopage = useCallback((event: InputNumberChangeEvent) => {
    setJumpToPage(Number(event.value));
  }, []);

  const handleJumpToPage = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = jumpToPage > totalPages ? totalPages : jumpToPage;
      const rangeStart = (value - 1) * perPage;
      const rangeEnd = Math.min(value * perPage - 1, totalRecords - 1);

      setPaginator(() => ({
        currentPage: jumpToPage > totalPages ? totalPages : value,
        range: {
          start: rangeStart,
          end: rangeEnd
        }
      }));
    }
  }, [jumpToPage, totalPages, perPage, totalRecords]);

  const handleChangeDropdownPage = useCallback((event: DropdownChangeEvent) => {
    setPerPage(event.value);
    setPaginator((prev) => ({
      ...prev,
      currentPage: 1,
      range: { start: 0, end: event.value - 1 }
    }));
  }, []);

  const handleClickNext = useCallback(() => {
    paginator.currentPage <= totalPages &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: paginator.currentPage + 1,
        range: {
          start: paginator.range.start + perPage,
          end: paginator.range.end + perPage
        }
      }));
  }, [paginator, totalPages, perPage]);

  const handleClickPrev = useCallback(() => {
    paginator.range.start > 0 &&
      setPaginator((prev) => ({
        ...prev,
        currentPage: prev.currentPage - 1,
        range: {
          start: prev.range.start - perPage,
          end: prev.range.end - perPage
        }
      }));
  }, [paginator, perPage]);

  const handleClickSubmitFilter = useCallback(() => {
    setFilters(itemFilters);
    setIsOpenDialog(false);
    setPaginator({
      currentPage: 1,
      range: {
        start: 0,
        end: perPage - 1
      }
    });
  }, [itemFilters, perPage]);

  const storeFilterHistory = useCallback((filter) => {
    const createFilterHistoryItem = (name: string, label: string, value: string) => {
      return {
        name,
        items: [{
          label,
          value,
          name
        }]
      };
    };

    const filterHistoryItems: IFilterHistoryItems[] = [];

    if (filter.id_from != null) {
      let filterID = filter.id_from.toString();
      if (filter.id_to != null) {
        filterID = `${filter.id_from}-${filter.id_to}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Banner ID', 'id_from,id_to', filterID));
    }

    if (filter?.created_at && filter.created_at[0]) {
      let filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')}`;
      if (filter.created_at.length > 1 && filter.created_at[1] !== null) {
        filterDate = `${dayjs(filter.created_at[0]).format('YYYY-MM-DD')} - ${dayjs(filter.created_at[1]).format('YYYY-MM-DD')}`;
      }
      filterHistoryItems.push(createFilterHistoryItem('Created Date', 'created_at', filterDate));
    }

    if (filter.name !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Banner Name', 'name', filter.name));
    }

    if (filter.status !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Status', 'status', filter.status));
    }

    if (filter.street !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Street', 'street', filter.street));
    }

    if (filter.type !== '') {
      filterHistoryItems.push(createFilterHistoryItem('Category', 'type', renderLabelCategoryBanner(filter.type)));
    }

    setFilterHistory(filterHistoryItems);
  }, []);

  useEffect(() => {
    storeFilterHistory(filters);
  }, [filters]);

  useEffect(() => {
    // update store visitedPage with latest page
    setVisitedPage({
      ...visitedPage,
      banner: paginator.currentPage
    });
  }, [paginator.currentPage]);

  // update store lastFilterPage with latest filter
  useEffect(() => {
    const filterString = JSON.stringify(filters);
    if (lastFilterPage.banner !== filterString) {
      setLastFilterPage({
        ...lastFilterPage,
        banner: filterString
      });
    }
  }, [lastFilterPage, filters]);

  return {
    data: {
      refToastList,
      selectedBannerRow,
      isLoadingBanner,
      banner,
      optionListBannerFilter,
      expandedRow,
      headers,
      perPage,
      totalRecords,
      totalPages,
      errorDelete,
      errorDeleteBrand,
      statusDeleteBanner,
      statusDeleteBrandBanner,
      itemFilters,
      isOpenDialog,
      filterHistory,
      paginator,
      selectedRecords,
      optionBannerType,
      search
    },
    method: {
      handleToggleRow,
      handleSearch,
      handleSelectionChange,
      allowExpansion,
      handleDeleteFilterHistory,
      handleClearFilter,
      renderLabelCategoryBanner,
      setPerPage,
      softDeleteBannerById,
      softDeleteBrandBanner,
      showFailedToast,
      showSuccessToast,
      handleFilter,
      setIsOpenDialog,
      onSelectionChange,
      handleClickDelete,
      handleChangeJumpTopage,
      handleJumpToPage,
      handleChangeDropdownPage,
      handleClickNext,
      handleClickPrev,
      handleClickSubmitFilter
    }
  };
};

export default useCustom;
